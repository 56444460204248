import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"
import Privacy from "../pages/Authentication/Privacy"
import Termsandconditions from "../pages/Authentication/Termsandconditions"
import Mailverification from "../pages/Authentication/Mailverification"
import Deleteaccount from "../pages/Authentication/Deleteaccount"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Access from "../pages/Dashboard/Access"
import Maintenace from "../pages/Dashboard/Maintenace"

// Settings
import State from "pages/Settings/State"
import District from "pages/Settings/District"
import City from "pages/Settings/City"
import Faqs from "pages/Settings/Faqs"
import RefundPolicy from "pages/Settings/RefundPolicy"
import Terms from "pages/Settings/Terms"
import About from "pages/Settings/About"
import PrivacyPolicy from "pages/Settings/PrivacyPolicy"
import Notifications from "pages/Settings/Notifications"
// import Test from "pages/Settings/Test"
//Staff
import Departments from "pages/Staff/Departments"
import Roles from "pages/Staff/RolesPremissions"
import Staff from "pages/Staff/Staff"

//Payment
import Payment from "pages/Payment/Payment"
import Wallet from "pages/Payment/Wallet"

//Doctors
import AddDoctors from "pages/Doctors/AddDoctors"
import Doctors from "pages/Doctors/Doctors"
import ViewDoctor from "pages/Doctors/ViewDoctor"

//Users
// import AddUser from "pages/Users/AddUser"
import User from "pages/Users/User"
import ViewUser from "pages/Users/ViewUser"
//SlotMaking
import SlotMaking from "pages/SlotMaking/SlotMaking"
// import SlotSchedule from "pages/SlotMaking/SlotSchedule"
import Slotlist from "pages/SlotMaking/Slotlist"

//Subscription
import AddSubscription from "pages/Subscription/AddSubscription"
import Subscription from "pages/Subscription/Subscription"
import EditSubscription from "pages/Subscription/EditSubscription"

import Banners from "pages/Banners/Banners"

import Blog from "pages/Blog/Blog"
import Editblog from "pages/Blog/Editblog"

import Suggestions from "pages/Suggestions/Suggestions"

import Categories from "pages/Categories/Categories"

import Plans from "pages/Plans/Plans"

import CurrectPlan from "pages/Plans/CurrectPlan"

import Support from "pages/Support/Support"
import Enquiry from "pages/Support/Enquiry"
import Chattingapp from "pages/Support/Chattingapp"
import Chattest from "pages/Support/Chattest"

import Timeslot from "pages/TimeSlot/Timeslot"

import AddTimeSlot from "pages/TimeSlot/AddTimeSlot"
import Addslot from "pages/SlotMaking/Addslot"
import Editspot from "pages/SlotMaking/Editspot"

import BookingList from "pages/Booking/BookingList"
import Consulted from "pages/Booking/Consulted"
import Videobooking from "pages/Booking/Videobooking"
import Audiobooking from "pages/Booking/Audiobooking"
import Directbookings from "pages/Booking/Directbookings"
import Rejectedbookings from "pages/Booking/Rejectedbookings"
import Callingfun from "pages/Booking/Callingfun"
import ViewBooking from "pages/Booking/ViewBooking"
import Bookingprescription from "pages/Booking/Bookingprescription"
import Alludeai from "pages/Booking/Alludeai"
import Followup from "pages/Booking/Followup"
import Pendingops from "pages/Booking/Pendingops"

import NewBlog from "pages/Blog/NewBlog"

import Profile from "pages/Settings/Profile"
import Complaints from "pages/Settings/Complaints"
import Consultentionfee from "pages/Settings/Consultentionfee"

import Prescriptions from "pages/Prescriptions/Prescriptions"

import Viewblog from "pages/Blog/Viewblog"

import ApprovedBlog from "pages/Blog/ApprovedBlog"

import RejectedBlog from "pages/Blog/RejectedBlog"
import Allblogs from "pages/Blog/Allblogs"

import Signature from "pages/Doctors/Signature"

import Sample from "pages/Sample"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/Signature", component: Signature },

  { path: "/Prescriptions", component: Prescriptions },

  { path: "/ApprovedBlog", component: ApprovedBlog },

  { path: "/RejectedBlog", component: RejectedBlog },
  { path: "/allblogs", component: Allblogs },

  { path: "/Viewblog", component: Viewblog },

  { path: "/DoctorProfile", component: Profile },

  { path: "/ViewBooking", component: ViewBooking },
  { path: "/bookingprescription", component: Bookingprescription },
  { path: "/BookingList", component: BookingList },
  { path: "/onlinebooking", component: Videobooking },
  { path: "/directbooking", component: Directbookings },
  { path: "/rejectedbookings", component: Rejectedbookings },
  { path: "/alludeai", component: Alludeai },
  { path: "/followup", component: Followup },
  { path: "/pendingops", component: Pendingops },

  { path: "/consulted", component: Consulted },
  { path: "/NewBlog", component: NewBlog },
  { path: "/Timeslot", component: Timeslot },

  { path: "/AddTimeSlot", component: AddTimeSlot },
  { path: "/addslot", component: Addslot },
  { path: "/editspot", component: Editspot },

  { path: "/Support", component: Support },
  { path: "/enquiries", component: Enquiry },

  { path: "/chatmain", component: Chattingapp },
  { path: "/chattest", component: Chattest },

  { path: "/Plans", component: Plans },

  { path: "/CurrectPlan", component: CurrectPlan },

  { path: "/Suggestions", component: Suggestions },

  { path: "/Categories", component: Categories },

  //profile
  { path: "/profile", component: UserProfile },

  { path: "/Banners", component: Banners },

  { path: "/Blog", component: Blog },
  { path: "/editblog", component: Editblog },

  //Doctors
  { path: "/AddDoctors", component: AddDoctors },
  { path: "/Doctors", component: Doctors },
  { path: "/ViewDoctor", component: ViewDoctor },

  //Subscription
  { path: "/AddSubscription", component: AddSubscription },
  { path: "/Subscription", component: Subscription },
  { path: "/EditSubscription", component: EditSubscription },

  //payment
  { path: "/Payment", component: Payment },
  { path: "/wallet", component: Wallet },
  //Users
  // { path: "/AddUser", component: AddUser },
  { path: "/User", component: User },
  { path: "/ViewUser", component: ViewUser },

  //SlotMaking
  { path: "/SlotMaking", component: SlotMaking },
  // { path: "/Slotlist", component: SlotSchedule },
  { path: "/Slotlist", component: Slotlist },
  //Staff
  { path: "/Departments", component: Departments },
  { path: "/RolesPremissions", component: Roles },
  { path: "/Staff", component: Staff },

  // Settings
  { path: "/notifications", component: Notifications },
  { path: "/State", component: State },
  { path: "/District", component: District },
  { path: "/City", component: City },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Terms", component: Terms },
  { path: "/About", component: About },
  { path: "/Faqs", component: Faqs },
  { path: "/PrivacyPolicy", component: PrivacyPolicy },
  { path: "/complaints", component: Complaints },
  { path: "/consultation", component: Consultentionfee },
  // { path: "/Test", component: Test },

  // this route should be at the end of all other routesside
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
  { path: "/register", component: Register },
  { path: "/access", component: Access },
  { path: "/maintenance", component: Maintenace },
  { path: "/audiobooking", component: Audiobooking },
  { path: "/callingfun", component: Callingfun },
  { path: "/privacy", component: Privacy },
  { path: "/termsandconditions", component: Termsandconditions },
  { path: "/mailverification", component: Mailverification },
  { path: "/deleteaccount", component: Deleteaccount },

  { path: "/Sample", component: Sample },
]

export { publicRoutes, authProtectedRoutes }
