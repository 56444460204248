import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { withRouter, Link, useHistory } from "react-router-dom"
import logo from "assets/images/logo allude color.png"
import { addData } from "Servicescalls"

const Privacy = () => {
  const history = useHistory()
  const [form, setform] = useState([])

  console.log(form)

  const getTerms = async () => {
    const resonse = await addData("getPolicies")
    var _data = resonse
    setform(_data?.data?.doctorcompanyPolicy?.termsAndCondition)
  }

  useEffect(() => {
    getTerms()
  }, [])

  return (
    <React.Fragment>
      <div className="bg-white">
        <div style={{ overflow: "hidden" }}>
          <div className="pt-4">
            <Row>
              <Col className="text-center" md="12">
                <img src={logo} />
              </Col>
            </Row>
            <Container>
              <Row className="mt-5">
                <Col md={12}>
                  <div>
                    <Row>
                      <Col md="6">
                        <h5>Terms and Conditions</h5>
                      </Col>
                      <Col className="text-end" md="6">
                        <a onClick={history.goBack}>
                          <h5 className="text-primary">
                            <i className="bx bx-arrow-back" /> Back
                          </h5>
                        </a>
                      </Col>
                    </Row>
                    <Card>
                      <CardBody>
                        <div
                          className="pt-2"
                          dangerouslySetInnerHTML={{
                            __html: form,
                          }}
                        ></div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="text-center mt-5 mb-3">
                    <p className="mb-0">
                      © {new Date().getFullYear()} VRAH Telehealth Services
                      Private Limited
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Privacy
