import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();
// var deviceID = window.navigator.userAgent
const deviceName = navigator.userAgent;

// console.log("Device Name:", deviceName);

var deviceID = sessionStorage.getItem("IpAddress")
var deviceID123 = sessionStorage.getItem("IpAddress")
// console.log(deviceID123)
// console.log(deviceID)

const userAgent = window.navigator.userAgent;
const platform = window.navigator.platform;
const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);

const deviceID1 = `${userAgent}-${platform}-${randomString}`;
// console.log(deviceID1)
// console.log(randomString)

const deviceId = navigator.hardwareConcurrency;
const userAgentid = navigator.userAgent;
// constdeviceId = navigator.userAgent.match(/(Windows|Macintosh|Linux)/)[0];

// console.log(constdeviceId)
// console.log(deviceId)
// console.log(userAgentid)

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password,
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
        webDeviceId: sessionStorage.getItem("IpAddress"),
        webDeviceName: userAgent,
      });
      if (response.status === 420) {
        history.push("/access");
        localStorage.setItem("authemail", user.email)
      } else {
        yield put(loginSuccess(response));
        localStorage.setItem("authUser", JSON.stringify(response));
        localStorage.setItem("authemail", user.email)
        history.push("/dashboard");
      }

      // localStorage.setItem("authUser", JSON.stringify(response));
      // localStorage.setItem("authemail", user.email);
      // yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
        webDeviceId: sessionStorage.getItem("IpAddress"),
        // webDeviceId: randomString,
        webDeviceName: userAgent,
      });

      if (response.status === 420) {
        history.push("/access");

        console.log(user.email)
      } else {
        yield put(loginSuccess(response));
        localStorage.setItem("authUser", JSON.stringify(response));
        localStorage.setItem("authemail", user.email)
        // history.push("/dashboard");
        window.location.href = '/dashboard';
      }

      // localStorage.setItem("authUser", JSON.stringify(response));
      // localStorage.setItem("authemail", user.email);
      // yield put(loginSuccess(response));
    }
    // history.push("/dashboard");
    window.location.href = '/dashboard';
    console.log(user.email)
  } catch (error) {
    yield put(apiError(error));
    if (error.response && error.response.status === 420) {
      history.push("/access");
      localStorage.setItem("authemail", user.email);
    } else {
      // history.push("/access");
      localStorage.setItem("authemail", user.email);
    }

    // history.push("/access");
    // localStorage.setItem("authemail", user.email)
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
