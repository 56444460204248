import React, { useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Form,
    Button,
    CardHeader,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"

function AddVendors() {
    const handleSubmit = e => {
        e.preventDefault()
        Adddealer()
    }

    const [inputList, setInputList] = useState([{ issues: "", days: "", BriefDetails: "" }])

    const [form, setform] = useState([])

    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...inputList]
        list[index][name] = value
        setInputList(list)
    }

    const handleRemoveClick = index => {
        const list = [...inputList]
        list.splice(index, 1)
        setInputList(list)
    }

    const handleAddClick = () => {
        setInputList([...inputList, { issues: "", days: "", BriefDetails: "" }])
    }

    const history = useHistory()

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token

    const Adddealer = () => {
        var token = datas

        const data = {
            timings: inputList,
            date: form.date,
        }

        axios
            .post(URLS.AddAtpHolidaylist, data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(
                res => {
                    if (res.status === 200) {
                        toast(res.data.message)
                        history.push("/AtpTimming")
                        sessionStorage.setItem(
                            "tost",
                            "Holiday slot has been Added Successfully"
                        )
                    }
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast(error.response.data.message)
                    }
                }
            )
    }

    const handlechange = e => {
        const myform = { ...form }
        myform[e.target.name] = e.target.value
        setform(myform)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Allude Doctor"
                        breadcrumbItem="Complaints"
                    />
                    <Row>
                        <Col xl="12">
                            <Button
                                onClick={history.goBack}
                                className="mb-3"
                                style={{ float: "right" }}
                                color="primary"
                            >
                                <i className="far fa-arrow-alt-circle-left"></i>
                                Back
                            </Button>{" "}
                        </Col>
                    </Row>

                    <Form
                        onSubmit={e => {
                            handleSubmit(e)
                        }}
                    >
                        <Card>
                            <CardBody>
                                <Row className="mt-2">
                                    <Row>
                                        {/* <Col md={3} >
                                            <div className="mb-3">
                                                <Label for="basicpill-firstname-input1">
                                                    Select Patient <span className="text-danger">*</span>
                                                </Label>
                                                <select className="form-select">
                                                    <option value="">Select</option>
                                                    <option value="Shanker">Shanker</option>
                                                    <option value="Sateesh">Sateesh</option>
                                                    <option value="Syed">Syed</option>
                                                </select>

                                            </div>
                                        </Col>
                                        <hr /> */}

                                        <Col md={12}>
                                            <div>
                                                <Row className="mb-3">
                                                    <Col md="12" sm="12">
                                                        <Label>Problem</Label>
                                                        <Input
                                                            type="text"
                                                            name="fromTime"
                                                            placeholder="Enter Problem"
                                                            value="High fever and Body pains"
                                                            onChange={e => handleInputChange(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {inputList.map((x, i) => {
                                                        return (
                                                            <>
                                                                <Row className="mb-3">
                                                                    <div key={i} className="box row">
                                                                        <Col md="3" sm="12" className="mb-1">
                                                                            <Label>Health Issue</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="fromTime"
                                                                                placeholder="Enter Health Issue"
                                                                                value={x.fromTime}
                                                                                onChange={e => handleInputChange(e, i)}
                                                                            />
                                                                        </Col>

                                                                        <Col md="3" sm="12" className="mb-1">
                                                                            <Label>Day’s</Label>
                                                                            <Input
                                                                                type="number"
                                                                                name="toTime"
                                                                                placeholder="Enter Day’s"
                                                                                value={x.toTime}
                                                                                onChange={e => handleInputChange(e, i)}
                                                                            />
                                                                        </Col>
                                                                        <Col md="3" sm="12" className="mb-1">
                                                                            <Label>Brief Details</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="Duration"
                                                                                placeholder="Enter Brief Details"
                                                                                value={x.Duration}
                                                                                onChange={e => handleInputChange(e, i)}
                                                                            />
                                                                        </Col>

                                                                        <Col sm="3">
                                                                            <Label className="mt-1"></Label>
                                                                            <div className="btn-box">
                                                                                {inputList.length !== 1 && (
                                                                                    <button
                                                                                        className="mr10 btn btn-outline-danger btn-sm m-1 mt-3"
                                                                                        type="button"
                                                                                        onClick={() => handleRemoveClick(i)}
                                                                                    >
                                                                                        Remove{" "}
                                                                                        <i className="bx bx-x-circle"></i>
                                                                                    </button>
                                                                                )}
                                                                                {inputList.length - 1 === i && (
                                                                                    <button
                                                                                        className="btn btn-sm btn-outline-info m-1 mt-3"
                                                                                        onClick={handleAddClick}
                                                                                    >
                                                                                        Add{" "}
                                                                                        <i className="bx bx-plus-circle"></i>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Row>
                                                            </>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </Col>

                                    </Row>
                                </Row>
                                <div style={{ float: "right" }}>
                                    <Button className="m-1 mt-2" color="info" type="submit">
                                        Submit to Allude <i className="fas fa-check-circle"></i>
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="bg-dark">
                            <CardHeader className="border border-white">
                                <h4 className="text-white ">ALLUDE SAY’S</h4>
                            </CardHeader>
                            <CardBody >
                                <div className="mb-3">
                                    <h5 >Most Probable Diagnosis</h5>
                                    <CardBody className="bg-white rounded">
                                        <div>
                                            <span>Medical diagnosis or the actual process of
                                                making a diagnosis is a cognitive process.</span>
                                            <div className="text-end">
                                                <i style={{ fontSize: "20px" }} className="bx bx-cloud-download text-primary" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                                <div className="mb-3">
                                    <h5>Treatment Suggestions</h5>
                                    <CardBody className="bg-white rounded">
                                        <div>
                                            <ul>
                                                <li>Antibiotics for bacteria pneumonia</li>
                                                <li>Fever management with aceteminophen. </li>
                                                <li>Adequate rest and hydration</li>
                                            </ul>
                                            <div className="text-end">
                                                <i style={{ fontSize: "20px" }} className="bx bx-cloud-download text-primary" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </CardBody>

                        </Card>
                        <Row>
                            <div className="text-end">
                                <Link to="/bookingprescription">
                                    <Button style={{ width: "120px" }} className="m-1 mb-2" color="warning" type="submit">
                                        Submit  <i className="fas fa-check-circle"></i>
                                    </Button>
                                </Link>
                            </div>
                        </Row>

                    </Form>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

export default AddVendors