import React, { useState } from "react"
import {
  CardBody,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Modal,
  Input,
  Label,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"
import ju from "../../assets/images/ju.png"
import adobephotoshop from "../../assets/images/users/avatar-7.jpg"
import classnames from "classnames"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

function TestsView() {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }
  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }
  const [activeTab1, setactiveTab1] = useState("1")
  const [text1, setText1] = useState([])
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm Allude! Ask me anything!",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ])


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Allude Doctor"
            breadcrumbItem="Prescription"
          />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                    </Col>
                    <Col md="6">
                      <b style={{ float: "right" }} className="text-primary text-right"> Type : Online</b>
                    </Col>
                  </Row>
                  <Col md={12}>
                    <div className="text-center">
                      <div className="avatar-md1 profile-user-wid mb-4 mt-1">
                        <span className="ml-5">
                          <img
                            src={adobephotoshop}
                            alt=""
                            className="avatar-md1 rounded-circle img-thumbnail"
                            height="150"
                            width="100"
                          />
                        </span>
                      </div>
                      <h5 className="mt-1 mb-1">#UI546546</h5>
                    </div>
                  </Col>

                  <ul className="list-unstyled mt-2">
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-phone text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Phone</h6>
                          <p className="text-muted fs-14 mb-0">9988776655</p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-mail-send text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Email</h6>
                          <p className="text-muted fs-14 mb-0">
                            Sameer@gmail.com
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-error-circle text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Gender / Age </h6>
                          <p className="text-muted fs-14 text-break mb-0">
                            Male / 21
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-map text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Location</h6>
                          <p className="text-muted fs-14 mb-0">Hyderabad.</p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-user text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Height / Weight</h6>
                          <p className="text-muted fs-14 mb-0">6.7ft / 60kgs</p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-duplicate text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Blood pressure</h6>
                          <p className="text-muted fs-14 mb-0">90mmHg</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <h5 className="text-primary"> More Info :</h5>
                    </Col>
                    {/* <Col md="6">
                      <Link to="/complaints"> <Button className="m-1" style={{float:"right"}} size="sm" color="warning" > <i className="bx bx-detail" ></i>Ask to Allude</Button></Link>
                      <a target="blank" href="/audiobooking"> <Button className="m-1" style={{float:"right"}} size="sm" color="primary" > <i className="bx bx-video" ></i> Video Call</Button> </a>
                    </Col> */}
                  </Row>

                  <Row className="task-dates">

                    <Col sm="12" xs="12">
                      <div className="mt-2">
                        <Label >Problem</Label>
                        <Input type="text" value="High fever and Body pains" />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="7">
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem className="border border-primary rounded m-1">
                          <NavLink
                            style={{ cursor: "pointer", padding: "10px" }}
                            className={classnames({
                              active: activeTab1 === "1",
                            })}
                            onClick={() => {
                              toggle1("1")
                            }}
                          >
                            Prescription Details
                          </NavLink>
                        </NavItem>
                        <NavItem className="border border-primary rounded m-1">
                          <NavLink
                            style={{ cursor: "pointer", padding: "10px" }}
                            className={classnames({
                              active: activeTab1 === "2",
                            })}
                            onClick={() => {
                              toggle1("2")
                            }}
                          >
                            Prescription Guidence
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    
                    <TabPane tabId="1">
                    <Row>
                      <Col md="12">
                        <div className="text-end">
                          <Button onClick={tog_small1} size="sm" color="info">Edit Details  <i className="bx bx-plus-circle" /></Button>
                        </div>
                      </Col>
                    </Row> 
                      <div >
                        <div className="mb-3">
                          <div className="mb-3">
                            <h5>Most Probable Diagnosis</h5>
                            <span>Medical diagnosis or the actual process of
                              making a diagnosis is a cognitive process.</span>
                          </div>
                          <div className="mb-3">
                            <h5>Treatment Suggestions</h5>
                            <div>
                              <ul>
                                <li>Antibiotics for bacteria pneumonia</li>
                                <li>Fever management with aceteminophen. </li>
                                <li>Adequate rest and hydration</li>
                              </ul>
                            </div>
                          </div>
                          <div className="mb-3">
                            <h5>Treatment Suggestions</h5>
                            <div>
                              <ul>
                                <li>Antibiotics for bacteria pneumonia</li>
                                <li>Fever management with aceteminophen. </li>
                                <li>Adequate rest and hydration</li>
                              </ul>
                            </div>
                          </div>
                        </div>


                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col md="12">
                          <div className="text-end">
                            <Button onClick={tog_small} size="sm" color="info">Add Medicine <i className="bx bx-plus-circle" /></Button>
                          </div>
                        </Col>
                      </Row>
                      <Table hover className="table table-bordered mt-2">
                        <thead>
                          <tr className="text-center">
                            <th>S.No</th>
                            <th>Medicine name</th>
                            <th>Times</th>
                            <th>Dosage</th>
                            <th>Duration</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            <td>1</td>
                            <td>Paracetmol</td>
                            <td>Twice</td>
                            <td>1-1-1 three </td>
                            <td>5 Day’s</td>
                            <td>
                              <Button
                                size="sm"
                                color="warning"
                                outline
                                onClick={tog_small}
                              >
                                <i className="bx bx-edit"></i>
                              </Button>
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td>2</td>
                            <td>Paracetmol</td>
                            <td>Twice</td>
                            <td>1-1-1 three </td>
                            <td>5 Day’s</td>
                            <td>
                              <Button
                                size="sm"
                                color="warning"
                                outline
                                onClick={tog_small}
                              >
                                <i className="bx bx-edit"></i>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>


                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Medicine</h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            <Form>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label>Medicine name <span className="text-danger">*</span> </Label>
                    <Input type="text" placeholder="Enter Medicine name" />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label>Time <span className="text-danger">*</span></Label>
                    <Input type="number" placeholder="Enter Times" />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label>Dosage <span className="text-danger">*</span></Label>
                    <Input type="text" placeholder="Enter Medicine name" />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label>Duration <span className="text-danger">*</span></Label>
                    <Input type="number" placeholder="Enter Medicine name" />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label>Duration</Label>
                    <textarea className="form-control" type="text" placeholder="Enter Instruction" />
                  </div>
                </Col>
              </Row>
            </Form>



            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small(false)
                }}
                color="danger"
                type="button"
                className="m-1"
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
              <Button
                onClick={() => {
                  setmodal_small(false)
                }}
                color="success"
                type="submit"
                className="m-1"
              >
                Submit <i className="bx bx-check-circle"></i>
              </Button>

            </div>
          </div>
        </Modal>
        <Modal
          size="xl"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Details</h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            <Form>
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <CKEditor
                      editor={ClassicEditor}
                      id="header"
                      data={text1}
                      onReady={editor => {
                        console.log("Editor is ready to use!", editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setText1(data)
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Form>

            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small1(false)
                }}
                color="danger"
                type="button"
                className="m-1"
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
              <Button
                onClick={() => {
                  setmodal_small1(false)
                }}
                color="success"
                type="submit"
                className="m-1"
              >
                Submit <i className="bx bx-check-circle"></i>
              </Button>

            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default TestsView
