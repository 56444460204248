import React, { useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Label,
  Modal,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import avatar1 from "../../assets/images/users/avatar-2.jpg"

function Profile() {
  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = () => {
    tog_small()
  }
  const [form, setform] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Doctor Profile" />

          <Card>
            <CardBody>
              <Row className="mb-5">
                <Col md={12}>
                  <h5 className="mb-1 mt-2">
                    Doctor Details:{" "}
                    <Button
                      onClick={() => {
                        getpopup()
                      }}
                      className="m-1"
                      color="info"
                      style={{ float: "right" }}
                    >
                      <i
                        style={{ fontSize: " 14px" }}
                        className="bx bx-edit"
                      ></i>
                      Edit Profile Request
                    </Button>
                  </h5>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col lg={4}>
                      <ul className="list-unstyled vstack gap-3 mb-0">
                        <div className="KKL">
                          <img src={avatar1} height="192px"></img>
                        </div>

                        <li>
                          <div className="d-flex mt-5">
                            <i className="bx bxs-buildings font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold"> Name: </h6>
                              <span className="text-muted">SANTHU </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex mt-4">
                            <i className="bx bx-money font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">
                                Identity Number:
                              </h6>
                              <span className="text-muted">YAS34513243243</span>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="d-flex mt-4">
                            <i className="bx bxs-home-circle font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">
                                Contact Number:
                              </h6>
                              9988776655
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex mt-4">
                            <i className="bx bx-mail-send font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">Email:</h6>
                              Santhu@gmail.com
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="d-flex mt-4">
                            <i className="bx bx-bookmark font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">Status :</h6>
                              <span className="text-muted">Active</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={8}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Date Of Joning:
                                </h6>
                                <p className="text-muted">20-02-2022</p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list mt-3">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Subscription:</h6>
                                <p className="text-muted">
                                  Paid / Exp : 20-02-2023
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Hospital Name</h6>
                                <p className="text-muted">Apollo</p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Speciality</h6>
                                <p className="text-muted">Nerologist</p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">Address</h6>
                                <p className="text-muted">
                                  6-3-905, Raj Bhavan Rd · 040 4567 4567 Open 24
                                  hours
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">
                                  Lattitude / Longitude
                                </h6>
                                <p className="text-muted">
                                  17.2020434 / 17.5255555
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">
                                  Postal Address
                                </h6>
                                <p className="text-muted">5080001</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Profile Request
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Title <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Title "
                    required
                    name="title"
                    value={form.title}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description "
                    required
                    name="Description"
                    rows="3"
                    value={form.title}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Image
                    <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Promotion Videos link"
                    required
                    name="title"
                    value=""
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </div>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile
