import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "Url"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { baseurl } from "Baseurls"

function AddVendors() {
  const [form, setform] = useState([])
  const [files1, setFiles1] = useState([])
  const [text1, setText1] = useState([])
  console.log(text1)
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }


  const history = useHistory()
  
  const handleSubmit = (e) => {
    e.preventDefault()
    var token = datas
    const dataArray = new FormData()
    dataArray.append("heading", form.heading)
    dataArray.append("description", text1)
    dataArray.append("specialisation", form.specialisation)
    for (let i = 0; i < files1.length; i++) {
      dataArray.append("image", files1[i])
    }

    axios
      .post(baseurl + 'blog/addblog', dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setTimeout(() =>{
              history.push("/Blog",)
            }, 2000)
            // history.push("/Blog",)
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const handlechange = (e) => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      heading: "",
      description: "",
      specialisation: "",
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Add Blog" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="12">
                    <Row>

                      <div className="mb-3 col-md-4">
                        <Label for="basicpill-firstname-input1">
                          Title <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Title"
                          required
                          value={form.heading}
                          name="heading"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-4">
                        <Label>Specialization   <span className="text-danger">*</span></Label>{" "}

                        {/* <select
                        value={form.Specialization}
                        name="Specialization"
                        onChange={e => {
                          handlechange(e)
                        }}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="Neurology">Neurology</option>
                      </select> */}
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Specialization"
                          required
                          value={form.specialisation}
                          name="specialisation"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>

                      <div className="mb-3 col-md-4">
                        <Label for="basicpill-firstname-input1">
                          Image  <span className="text-danger">700*400</span>
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Title"
                          required
                          value={files1.image}
                          name="image"
                          onChange={changeHandler1}
                        />
                      </div>

                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <CKEditor
                      editor={ClassicEditor}
                      required
                      id="header"
                      data={text1}
                      onReady={editor => {
                        console.log("Editor is ready to use!", editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setText1(data)
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="mb-4 mt-2" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        disabled={text1.length == 0}
                        className="btn btn-primary m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>{" "}
          </Form>
        </Container>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default AddVendors
