import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    setSelectedOptions(details)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  //Adddelear api integration

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("company_name", form.company_name)
    dataArray.append("company_mobile_number", form.company_mobile_number)
    dataArray.append("trading_number", form.trading_number)
    dataArray.append("vat", form.vat)
    dataArray.append("address", form.address)
    dataArray.append("supportEmail", form.supportEmail)
    dataArray.append("bookingEmail", form.bookingEmail)
    dataArray.append("name", form.name)
    dataArray.append("email", form.email)
    dataArray.append("password", form.password)
    dataArray.append("phone", form.phone)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("trading_image", selectedFiles[i])
    }

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("logo", selectedFiles1[i])
    }

    axios
      .post("", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Vendorslist")
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      company_name: "",
      company_mobile_number: "",
      trading_number: "",
      trading_image: "",
      vat: "",
      address: "",
      supportEmail: "",
      bookingEmail: "",
      cityId: "",
      name: "",
      email: "",
      password: "",
      phone: "",
      logo: "",
    })
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes1(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes1(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [cities, setcities] = useState([])
  // useEffect(() => {
  //   getallCities()
  // }, [])

  const getallCities = () => {
    var token = datas
    axios
      .get(
        "http://103.186.185.77:5021/api/v1/superadmin/city/allactivecities",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcities(res.data.data)
      })
  }

  const [inputList, setInputList] = useState([{ info: "", advantage: "" }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { info: "", advantage: "" }])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Doctor Admin" breadcrumbItem="Subscription" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#9640CF", fontWeight: "bold" }}
                    >
                      Add Subscription
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Plan Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Name"
                        required
                        value={form.company_name}
                        name="company_name"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Price"
                        required
                        value={form.company_name}
                        name="company_name"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Discount <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Discount"
                        required
                        value={form.company_mobile_number}
                        name="company_mobile_number"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Key <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Key"
                        required
                        value={form.company_mobile_number}
                        name="company_mobile_number"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Discription <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="6"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Discription"
                        required
                        value={form.address}
                        name="address"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Plan Image</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} required />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, Image Size - maximum
                            size 2 MB
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
             
                <Row className="mb-5">
                  <div className="mt-4">
                    <Label> Advantages</Label>
                    <Row>
                      {inputList.map((x, i) => {
                        return (
                          <>
                            <Row>
                              <div key={i} className="box row">
                                <Col md="5" sm="12" className="mb-1">
                                  <Input
                                    type="text"
                                    name="info"
                                    placeholder="Enter Plan Info"
                                    value={x.info}
                                    onChange={e => handleInputChange(e, i)}
                                  />
                                </Col>

                                <Col md="5" sm="12" className="mb-1">
                                  <Input
                                    type="text"
                                    name="advantage"
                                    placeholder="Enter Plan Advantage"
                                    value={x.advantage}
                                    onChange={e => handleInputChange(e, i)}
                                  />
                                </Col>

                                <Col sm="2">
                                  <div className="btn-box">
                                    {inputList.length !== 1 && (
                                      <button
                                        className="mr10 btn btn-outline-danger btn-sm m-1"
                                        type="button"
                                        onClick={() => handleRemoveClick(i)}
                                      >
                                        Remove{" "}
                                        <i className="bx bx-x-circle"></i>
                                      </button>
                                    )}
                                    {inputList.length - 1 === i && (
                                      <button
                                        className="btn btn-sm btn-outline-info m-1"
                                        onClick={handleAddClick}
                                      >
                                        Add{" "}
                                        <i className="bx bx-plus-circle"></i>
                                      </button>
                                    )}
                                  </div>
                                </Col>
                              </div>
                            </Row>
                          </>
                        )
                      })}
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <div className="text-end">
              <Button type="submit" color="success m-1 mb-5">
                Submit <i className="bx bx-check-circle"></i>
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
