import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Form,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
} from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ReactApexChart from "react-apexcharts"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import ReactPaginate from "react-paginate"
import { Link, useHistory } from "react-router-dom"
import { baseurl, imgUrl } from "Baseurls"
import { ToastContainer, toast } from "react-toastify"
import spinner from "../../assets/images/loader.gif"

const Dashboard = props => {
  const history = useHistory()
  useEffect(() => {
    GetDashboarddata()
    Getholidays()
  }, [])

  const [Bookingcards, setBookingcards] = useState([])
  const [patientsgraph, setpatientsgraph] = useState([])
  const [incomesgraph, setincomesgraph] = useState([])
  const [modalspin, setmodalspin] = useState(true)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const GetDashboarddata = () => {
    var token = datas
    axios
      .post(
        baseurl + "getdoctordashboard",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setBookingcards(res.data.card)
        setpatientsgraph(res.data.patients)
        setincomesgraph(res.data.incomes)
        setmodalspin(false)
      })
  }

  const reports = [
    {
      title: "Patient Consulted",
      iconClass: "fas fa-user-injured",
      description: Bookingcards?.patients?.total,
      description1: Bookingcards?.patients?.today,
    },
    {
      title: "Online Consulted",
      iconClass: "fas fa-blender-phone",
      description: Bookingcards?.online?.total,
      description1: Bookingcards?.online?.today,
    },
    {
      title: "Walk-in Consulted ",
      iconClass: "fas fa-user-md",
      description: Bookingcards?.direct?.total,
      description1: Bookingcards?.direct?.today,
    },
  ]

  const [viewholidaydata, setviewholidaydata] = useState([])

  const Getholidays = () => {
    var token = datas
    axios
      .post(
        baseurl + "holidays/getalldoctorholidays",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setviewholidaydata(res.data.holidaysArr)
      })
  }

  const [listPerPage] = useState(3)
  const [pageNumber, setPageNumber] = useState(0)
  const [bookingdata, setbookingdata] = useState([])

  const pagesVisited = pageNumber * listPerPage
  const lists = bookingdata.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(bookingdata.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const [form1, setform1] = useState([])
  const [form, setform] = useState([])
  const [blogs, setblogs] = useState([])

  useEffect(() => {
    getBookings()
    GetProfile()
    Get()
  }, [])

  const GetProfile = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctorauth/getdoctorprofile",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.doctorProfile)
      })
  }

  const getBookings = () => {
    var token = datas
    const bodydata = {
      consultationType: "",
    }
    axios
      .post(baseurl + "outpatient/getpendingoutpatients", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setbookingdata(res?.data?.outpatients)
      })
  }

  const Get = () => {
    var token = datas
    axios
      .post(
        baseurl + "blog/getApproved",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setblogs(res?.data?.blogs.slice(-3))
      })
  }

  const usedata1 = data => {
    sessionStorage.setItem("blogid", data._id)
    history.push("/Viewblog")
  }

  const handleChange = e => {
    let myData = { ...form }
    myData[e.target.name] = e.target.checked
    setform(myData)
    var token = datas
    const bodydata = {
      spotConsultation: e.target.checked,
    }
    axios
      .put(baseurl + "update", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          toast.success(res.data.message)
          GetProfile()
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const handlechange1 = e => {
    const myData = { ...form1 }
    myData[e.target.name] = e.target.value
    setform1(myData)
  }

  const getpopup = data => {
    tog_small()
    setform1(data)
  }
  const getpopup1 = data => {
    tog_small1()
    setform1(data)
  }
  const GetbookingId = data => {
    history.push("/ViewBooking")
    sessionStorage.setItem("bookingid", data._id)
  }

  const bookingacc = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      status: "Accepted",
    }
    axios
      .put(baseurl + "outpatient/updatebookingstatus/" + form1._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getBookings()
            getBookings123()
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const bookingcancel = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      status: "Rejected",
      rejectedReason: form1.rejectedReason,
    }
    axios
      .put(baseurl + "outpatient/updatebookingstatus/" + form1._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getBookings()
            getBookings123()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const series = [
    {
      name: "Walk-in",
      data: patientsgraph?.direct,
    },
    {
      name: "Online",
      data: patientsgraph?.online,
    },
    // {
    //   name: "Audio",
    //   data: [37, 42, 38, 26, 47, 50, 54, 55, 43, 50, 60, 90],
    // },
  ]

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Patients",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return +val + " Patients"
        },
      },
    },
  }

  const series1 = [
    {
      name: "Walk-in",
      data: incomesgraph?.direct,
    },
    {
      name: "Online",
      data: incomesgraph?.online,
    },
    // {
    //   name: "Audio",
    //   data: [37, 42, 38, 26, 47, 50, 54, 55, 43, 50, 60, 90],
    // },
  ]
  const options1 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Incomes",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "" + val
        },
      },
    },
  }

  // const redirectbooking = () => {
  //   history.push("/consulted");
  // };

  // Calendar
  const [bookingslist, setbookingslist] = useState([])
  const getBookings123 = () => {
    var token = datas
    const bodydata = {
      consultationType: "",
    }
    axios
      .post(baseurl + "outpatient/getacceptedoutpatients", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setbookingslist(res.data.outpatients)
      })
  }

  useEffect(() => {
    getBookings123()
  }, [])

  const events123 = bookingslist.map(data => ({
    title: "#" + data.bookingNo,
    _id: data._id,
    date: data.bookingDate,
  }))

  const redirectbooking = event => {
    sessionStorage.setItem("bookingid", event.event.extendedProps._id)
    console.log(event)
    history.push("/ViewBooking")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data.access
  var Roles1 = data

  return (
    <React.Fragment>
      <div className="page-content">
        {modalspin == true ? (
          <div className="text-center mt-4">
            <img src={spinner} style={{ width: "450px" }} />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title={props.t("Dashboards")}
              breadcrumbItem={props.t("Dashboard")}
            />

            <Row className="d-flex justify-content-center">
              <Col md="4">
                <div className="text-center">
                  <img
                    style={{ width: "100%", height: "200px" }}
                    src={imgUrl + form.clinicLogo}
                  />
                  <h5 className="mt-3">
                    <b>{form.clinicName}</b>
                  </h5>
                </div>
              </Col>
            </Row>

            {Roles?.walkin == true || Roles1?.type === "Doctor" ? (
              <>
                <Row>
                  <Col md="12">
                    {lists.length == 0 ? (
                      <h5 className="text-center">No Bookings...</h5>
                    ) : (
                      <>
                        {lists.map((data, key) => (
                          <Card key={key}>
                            <CardBody>
                              <div className="col-sm-12 ">
                                <div className="booking-container">
                                  <div className="row">
                                    <div className="col text-center">
                                      <b className="title-info">OP ID: </b>
                                      <br />

                                      <span
                                        onClick={() => {
                                          GetbookingId(data)
                                        }}
                                        type="button"
                                        className="title-value text-primary"
                                      >
                                        #{data.bookingNo}
                                      </span>
                                    </div>
                                    <div className="col text-center">
                                      <b className="title-info">OP Name: </b>
                                      <br />

                                      <span
                                        type="button"
                                        className="title-value "
                                      >
                                        {data.userName}
                                      </span>
                                    </div>
                                    <div className="col text-center">
                                      <b className="title-info">Date & Time</b>
                                      <br />

                                      <span className="title-value">
                                        {data.bookingDate} , {data.time}
                                      </span>
                                    </div>
                                    <div className="col text-center">
                                      <b className="title-info">Booking Slot</b>
                                      <br />

                                      <span className="title-value">
                                        {data.slot}
                                      </span>
                                    </div>
                                    <div className="col text-center">
                                      <b className="title-info">Type</b>
                                      <br />

                                      <span className="title-value">
                                        {data.consultationType}
                                      </span>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                      <div className="row">
                                        <div className="col-1">
                                          <b className="title-info">
                                            Problem:{" "}
                                          </b>
                                        </div>
                                        <div className="col-9">
                                          <p>{data.problem}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12 mt-4">
                                      <div className="booking-footer text-center">
                                        <Button
                                          onClick={() => {
                                            getpopup1(data)
                                          }}
                                          id="TooltipExample"
                                          color="primary"
                                          outline
                                          className="m-1"
                                          title="Accept"
                                        >
                                          <i className="bx bx-check-circle" />{" "}
                                          Accept
                                        </Button>

                                        <Button
                                          onClick={() => {
                                            getpopup(data)
                                          }}
                                          color="danger"
                                          id="TooltipExamples"
                                          outline
                                          className="m-1"
                                          title="Reject"
                                        >
                                          {" "}
                                          <i className="bx bx-x-circle" />
                                          Reject
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                      </>
                    )}

                    <Row>
                      <Col md="12">
                        <div className="d-flex " style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            {Roles?.bookings == true || Roles1?.type === "Doctor" ? (
              <>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardBody>
                        <h5 className="mb-3">Bookings</h5>
                        <FullCalendar
                          plugins={[dayGridPlugin]}
                          initialView="dayGridMonth"
                          eventClick={redirectbooking}
                          events={events123}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            {Roles?.payment == true || Roles1?.type === "Doctor" ? (
              <>
                <Row>
                  <Col xl="12">
                    <Row>
                      {reports.map((report, key) => (
                        <Col md="3" key={"_col_" + key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div className="text-center">
                                    <i
                                      className={
                                        report.iconClass +
                                        " font-size-24 border border-primary text-primary mb-1 p-2 rounded-circle"
                                      }
                                    ></i>

                                    <h5>{report.title}</h5>
                                  </div>
                                  <Row className="mt-3">
                                    <Col>
                                      <div className="text-center">
                                        <span
                                          style={{ fontSize: "10px" }}
                                          className="text-muted fw-medium"
                                        >
                                          {" "}
                                          Total Patients <br />{" "}
                                        </span>
                                        <b
                                          className="text-muted"
                                          style={{ fontSize: "18px" }}
                                        >
                                          {report.description}
                                        </b>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="text-center">
                                        <span
                                          style={{ fontSize: "10px" }}
                                          className="text-muted fw-medium"
                                        >
                                          {" "}
                                          Today Patients <br />{" "}
                                        </span>
                                        <b
                                          className="text-muted"
                                          style={{ fontSize: "18px" }}
                                        >
                                          {report.description1}
                                        </b>
                                        <br />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            {Roles?.bookings == true || Roles1?.type === "Doctor" ? (
              <>
                <Row>
                  <Col xl="6">
                    <Card>
                      <CardBody>
                        <div className="d-sm-flex flex-wrap">
                          <h4 className="card-title mb-4">Patients</h4>
                        </div>
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="bar"
                          height={350}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6">
                    <Card>
                      <CardBody>
                        <div className="d-sm-flex flex-wrap">
                          <h4 className="card-title mb-4">Incomes</h4>
                        </div>
                        <ReactApexChart
                          options={options1}
                          series={series1}
                          type="bar"
                          height={350}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            {Roles?.blog == true || Roles1?.type === "Doctor" ? (
              <>
                <h5 className="mb-3">
                  <b>Blogs</b>
                </h5>
                <Row>
                  {blogs.map((data, key) => (
                    <Col key={key} md="3" className="mt-4">
                      <a
                        onClick={() => {
                          usedata1(data)
                        }}
                        className="text-dark"
                      >
                        <Card>
                          <CardImg
                            className="img-fluid"
                            src={imgUrl + data.image}
                            alt="allude"
                            style={{ height: "150px" }}
                          />
                          <CardImgOverlay>
                            <CardTitle className="mt-0">
                              <Row>
                                <Col md={6}>
                                  <p
                                    style={{
                                      background: "white",
                                      color: "#B14BF4",
                                      borderRadius: "5px",
                                      padding: "4px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data.specialisation}
                                  </p>
                                </Col>
                              </Row>
                            </CardTitle>
                          </CardImgOverlay>
                          <CardBody>
                            <CardTitle className="mt-0">
                              <Row>
                                <Col md="2">
                                  <img
                                    src={imgUrl + data.doctorAvatar}
                                    style={{ height: "30px", width: "30px" }}
                                    className="rounded-circle"
                                  />
                                </Col>
                                <Col md="10">
                                  <small className="mt-3">
                                    <h6 style={{ color: "black" }}>
                                      {data.doctorName} (
                                      {data.doctorQualification.slice(0, 3) +
                                        "..."}
                                      )
                                    </h6>
                                  </small>
                                </Col>
                              </Row>
                            </CardTitle>
                            <CardText>
                              <div className="mt-3">
                                <b
                                  style={{
                                    color: "#B14BF4",
                                  }}
                                >
                                  {data.heading.length > 20
                                    ? data.heading.slice(0, 1).toUpperCase() +
                                      data.heading.slice(1, 20) +
                                      "..."
                                    : data.heading.slice(0, 1).toUpperCase() +
                                      data.heading.slice(1)}
                                </b>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.description.slice(0, 30),
                                  }}
                                ></div>
                              </div>
                            </CardText>

                            <CardText className="border-top pt-2">
                              <small className="text-muted">
                                <Row>
                                  <Col md="6">
                                    <a to="#" className="text-muted">
                                      <i className="bx bx-time align-middle me-1"></i>
                                      {data.date}
                                    </a>
                                  </Col>
                                  <Col style={{ float: "right" }} md="6">
                                    <a
                                      style={{ float: "right" }}
                                      to="#"
                                      className="text-muted"
                                    >
                                      {data.likesCount == "0" ? (
                                        <>
                                          <i className="bx bxs-heart align-middle text-grey me-1"></i>
                                        </>
                                      ) : (
                                        <>
                                          <i className="bx bxs-heart align-middle text-danger me-1"></i>
                                        </>
                                      )}
                                      ({data.likesCount})Likes
                                    </a>
                                  </Col>
                                </Row>
                              </small>
                            </CardText>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  ))}

                  <Col md="3">
                    <Link to="/allblogs">
                      <div className="text-center mt-5 pt-5 blink1"   style={{ fontSize: "20px" }}>
                        <i
                          style={{ fontSize: "50px" }}
                          title="View More Blogs"
                          className="bx bx-right-arrow-circle text-primary"
                        /><br></br>
                        All Blogs
                      </div>
                    </Link>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </Container>
        )}
        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div style={{ borderBottom: "0px" }} className="modal-header">
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                bookingcancel(e)
              }}
            >
              <div className="text-center">
                <i
                  style={{ fontSize: "100px" }}
                  className="bx bx-info-circle text-warning"
                />
                <h5>Do you want reject this booking</h5>

                <textarea
                  className="form-control mt-3"
                  name="rejectedReason"
                  placeholder="Enter Reason"
                  value={form1.rejectedReason}
                  onChange={e => {
                    handlechange1(e)
                  }}
                  type="text"
                  required
                />
              </div>

              <div className="text-center mt-3">
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  style={{ width: "100px" }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button
                  style={{ width: "100px" }}
                  className="m-1"
                  color="success"
                  type="submit"
                >
                  Yes <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div style={{ borderBottom: "0px" }} className="modal-header">
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                bookingacc(e)
              }}
            >
              <div className="text-center">
                <i
                  style={{ fontSize: "100px" }}
                  className="bx bx-info-circle text-warning"
                />
                <h5>Do you want accept this booking</h5>
              </div>

              <div className="text-center mt-3">
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  style={{ width: "100px" }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button
                  style={{ width: "100px" }}
                  className="m-1"
                  color="success"
                  type="submit"
                >
                  Yes <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  events: PropTypes.array,
  onGetEvents: PropTypes.func,
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}
// Calender.propTypes = {
//   events: PropTypes.array,
//   categories: PropTypes.array,
//   className: PropTypes.string,
//   onGetEvents: PropTypes.func,
//   onAddNewEvent: PropTypes.func,
//   onUpdateEvent: PropTypes.func,
//   onDeleteEvent: PropTypes.func,
//   onGetCategories: PropTypes.func,
// };

export default withTranslation()(Dashboard)
