import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
} from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

const Roles = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [ras, setras] = useState([])

  const check = {
    DashView: ras.DashView,

    broucherView: ras.broucherView,
    broucherEdit: ras.broucherEdit,

    digitalStickerView: ras.digitalStickerView,
    digitalStickerEdit: ras.digitalStickerEdit,

    staffAdd: ras.staffAdd,
    staffView: ras.staffView,
    staffEdit: ras.staffEdit,
    staffDelete: ras.staffDelete,

    agentAdd: ras.agentAdd,
    agentView: ras.agentView,
    agentEdit: ras.agentEdit,
    agentDelete: ras.agentDelete,

    customerAdd: ras.customerAdd,
    customerView: ras.customerView,
    customerEdit: ras.customerEdit,
    customerDelete: ras.customerDelete,

    eventAdd: ras.eventAdd,
    eventView: ras.eventView,
    eventEdit: ras.eventEdit,
    eventDelete: ras.eventDelete,

    meetingAdd: ras.meetingAdd,
    meetingView: ras.meetingView,
    meetingEdit: ras.meetingEdit,
    meetingDelete: ras.meetingDelete,

    ventureAdd: ras.ventureAdd,
    ventureView: ras.ventureView,
    ventureEdit: ras.ventureEdit,
    ventureDelete: ras.ventureDelete,

    notifyAdd: ras.notifyAdd,
    notifyView: ras.notifyView,
    notifyDelete: ras.notifyDelete,

    bannerAdd: ras.bannerAdd,
    bannerView: ras.bannerView,
    bannerEdit: ras.bannerEdit,
    bannerDelete: ras.bannerDelete,

    settingAdd: ras.settingAdd,
    settingEdit: ras.settingEdit,
    settingView: ras.settingView,
    settingDelete: ras.settingDelete,

    reportView: ras.reportView,

    transactionAdd: ras.transactionAdd,
    transactionView: ras.transactionView,

    supportView: ras.supportView,
    supportEdit: ras.supportEdit,
    supportDelete: ras.supportDelete,

    officeLocAdd: ras.officeLocAdd,
    officeLocView: ras.officeLocView,
    officeLocEdit: ras.officeLocEdit,
    officeLocDelete: ras.officeLocDelete,
  }

  const [form, setform] = useState([])

  const [ras1, setras1] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    Addrole()
  }

  const Addrole = () => {
    const token = datas
    const id = ras1
    axios
      .put(
        URLS.GetOneDep + id,
        {
          rolesPermissions: check,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  function handleChange(e) {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    var token = datas

    const dataArray = new FormData()
    dataArray.append("_id", e.target.value)

    axios
      .post(URLS.UpdateRoll, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          setras(res.data.depResult.rolesPermissions)
          setras1(res.data.depResult._id)
        },
        error => {
          if (error.response && error.response.status === 400) {
            // toast(error.response.data.message)
            setras1([])
            setras([])
          }
        }
      )
  }

  // useEffect(() => {
  //   getAllRoles()
  // }, [])

  const [rol, setrol] = useState([])

  const getAllRoles = () => {
    var token = datas
    axios
      .post(
        URLS.GetDep,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setrol(res.data.departments)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
            // setrol([""])
          }
        }
      )
  }

  const handleChange1s = e => {
    const myUser = { ...ras }
    myUser[e.target.name] = e.target.checked
    setras(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  // const roles = data.rolesAndPermissions
  // const roles1 = data.user.role

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Add Roles" />

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white mt-2">
                  <CardTitle>Role & Permissions</CardTitle>
                </CardHeader>

                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <Row>
                      <Col md={4}>
                        <Label> Roles</Label>{" "}
                        <span className="text-danger">*</span>
                        <select
                          value={form.role}
                          name="role"
                          onChange={e => {
                            handleChange(e)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {rol.map((data, key) => {
                            return (
                              <option key={key} value={data._id}>
                                {data.departmentName}
                              </option>
                            )
                          })}
                        </select>
                      </Col>
                    </Row>
                    <Row className=" mt-3">
                      <Col md={2}>
                        {" "}
                        <p className="">Dashboard: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DashView"
                            defaultChecked={ras.DashView}
                            value={ras.DashView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className=" mt-3">
                      <Col md={2}>
                        {" "}
                        <p className="">Doctors: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="supportView"
                            defaultChecked={ras.supportView}
                            value={ras.supportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="supportEdit"
                            defaultChecked={ras.supportEdit}
                            value={ras.supportEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="supportDelete"
                            defaultChecked={ras.supportDelete}
                            value={ras.supportDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Staff : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffView"
                            defaultChecked={ras.staffView}
                            value={ras.staffView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffAdd"
                            defaultChecked={ras.staffAdd}
                            value={ras.staffAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffEdit"
                            defaultChecked={ras.staffEdit}
                            value={ras.staffEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffDelete"
                            defaultChecked={ras.staffDelete}
                            value={ras.staffDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Users : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingAdd"
                            defaultChecked={ras.settingAdd}
                            value={ras.settingAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingEdit"
                            defaultChecked={ras.settingEdit}
                            value={ras.settingEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingView"
                            defaultChecked={ras.settingView}
                            value={ras.settingView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingDelete"
                            defaultChecked={ras.settingDelete}
                            value={ras.settingDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Settings : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingAdd"
                            defaultChecked={ras.settingAdd}
                            value={ras.settingAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingEdit"
                            defaultChecked={ras.settingEdit}
                            value={ras.settingEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingView"
                            defaultChecked={ras.settingView}
                            value={ras.settingView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingDelete"
                            defaultChecked={ras.settingDelete}
                            value={ras.settingDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <div className="mt-3" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-info m-1"
                      >
                        Submit{" "}
                        <i
                          className="fa fa-check-circle-o"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>{" "}
                    {/* </>
                    ) : (
                      ""
                    )} */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Roles
