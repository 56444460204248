import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { io } from "socket.io-client";
// import styled from "styled-components";
// import { allUsersRoute, host, sendMessageRoute, recieveMessageRoute } from "../utils/APIRoutes";
// import Logo from "../assets/logo.svg";
// import { BsEmojiSmileFill } from "react-icons/bs";
// import { IoMdSend } from "react-icons/io";
// import Picker from "emoji-picker-react";
import { v4 as uuidv4 } from "uuid";
import { baseurl, imgUrl } from "Baseurls";
// import ChatInput from "./ChatInput";
// import Logout from "./Logout";


export default function ChatComponent() {
    const host = "https://api.alludeapp.com";
    const history = useHistory();
    const socket = useRef();
    const scrollRef = useRef();
    const [contacts, setContacts] = useState([]);
    const [currentChat, setCurrentChat] = useState([]);
    console.log(currentChat)
    const [currentUser, setCurrentUser] = useState(undefined);
    const [messages, setMessages] = useState([]);
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [currentUserName, setCurrentUserName] = useState(undefined);
    const [currentUserImage, setCurrentUserImage] = useState(undefined);
    const [msg, setMsg] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [currentSelected, setCurrentSelected] = useState(undefined);

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token

    // useEffect(async () => {
    //     if (!localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
    //         history.push("/login");
    //     } else {
    //         setCurrentUser(
    //             await JSON.parse(
    //                 localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    //             )
    //         );
    //     }
    // }, []);

    useEffect(() => {
        if (currentUser) {
            socket.current = io(host);
            socket.current.emit("signin", currentUser._id);
        }
    }, [currentUser]);

    const getchatlist = () => {
        var token = datas
        // socket.on('connect', () => {
        axios
            .post(baseurl + 'chat/getdoctorchat', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setCurrentUser(res.data.chats[0])
                setContacts(res.data.chats)
            })
        // });
    }


    useEffect(() => {
        getchatlist();
    }, []);

    useEffect(async () => {
        const data = await JSON.parse(
            localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        );
        const response = await axios.post(recieveMessageRoute, {
            from: data._id,
            to: currentChat._id,
        });
        setMessages(response.data);
    }, [currentChat]);

    useEffect(() => {
        const getCurrentChat = async () => {
            if (currentChat) {
                await JSON.parse(
                    localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
                )._id;
            }
        };
        getCurrentChat();
    }, [currentChat]);

    const handleSendMsg = async (msg) => {
        socket.current.emit("send-msg", {
            sourceId: JSON.parse(localStorage.getItem("authUser")).userId,
            targetId: "2",
            msg,
        });
        await axios.post("https://api.alludeapp.com/v1/alludeapi/doctor/chat/doctorsendmessage/" + currentChat._id, {
            sourceId: JSON.parse(localStorage.getItem("authUser")).userId,
            targetId: "2",
            message: msg,
        });

        const msgs = [...messages];
        msgs.push({ fromSelf: true, message: msg });
        setMessages(msgs);
    };

    useEffect(() => {
        if (socket.current) {
            socket.current.on("msg-receive", (msg) => {
                setArrivalMessage({ fromSelf: false, message: msg });
            });
        }
    }, []);

    useEffect(() => {
        arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleChatChange = (chat) => {
        setCurrentChat(chat);
    };

    const changeCurrentChat = (index, contact) => {
        setCurrentSelected(index);
        handleChatChange(contact);
    };

    const handleEmojiPickerhideShow = () => {
        setShowEmojiPicker(!showEmojiPicker);
    };

    const handleEmojiClick = (event, emojiObject) => {
        let message = msg;
        message += emojiObject.emoji;
        setMsg(message);
    };

    const sendChat = (event) => {
        event.preventDefault();
        if (msg.length > 0) {
            handleSendMsg(msg);
            setMsg("");
        }
    };

    useEffect(async () => {
        const data = await JSON.parse(
            localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        );
        // setCurrentUserName(data.username);
        // setCurrentUserImage(data.avatarImage);
    }, []);

    return (
        <div className="container">
            <div className="container">
                <div className="brand">
                    {/* <img src={Logo} alt="logo" /> */}
                    <h3>snappy</h3>
                </div>
                <div className="contacts">
                    {contacts.map((contact, index) => {
                        return (
                            <div
                                key={contact._id}
                                className={`contact ${index === currentSelected ? "selected" : ""
                                    }`}
                                onClick={() => changeCurrentChat(index, contact)}
                            >
                                <div className="avatar">
                                    {/* <img
                    src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                    alt=""
                  /> */}
                                </div>
                                <div className="username">
                                    {/* <h3>{contact.username}</h3> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="current-user">
                    <div className="avatar">
                        <img
                            src={`data:image/svg+xml;base64,${currentUserImage}`}
                            alt="avatar"
                        />
                    </div>
                    <div className="username">
                        <h2>{currentUserName}</h2>
                    </div>
                </div>
            </div>
            <div className="chat-header">
                <div className="user-details">
                    <div className="avatar">
                        {/* <img
              src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
              alt=""
            /> */}
                    </div>
                    <div className="username">
                        {/* <h3>{currentChat.name}</h3> */}
                    </div>
                </div>
                {/* <Logout /> */}
            </div>
            <div className="chat-messages">
                {messages.map((message) => {
                    return (
                        <div ref={scrollRef} key={uuidv4()}>
                            <div
                                className={`message ${message.fromSelf ? "sended" : "recieved"
                                    }`}
                            >
                                <div className="content ">
                                    <p>{message.message}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="button-container">
                <div className="emoji">
                    <button className="btn btn-danger" onClick={handleEmojiPickerhideShow} >Click one</button>
                    {/* {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />} */}
                </div>
            </div>
            <form className="input-container" onSubmit={(event) => sendChat(event)}>
                <input
                    type="text"
                    placeholder="type your message here"
                    onChange={(e) => setMsg(e.target.value)}
                    value={msg}
                />
                <button type="submit">
                    Submit
                    {/* <IoMdSend /> */}
                </button>
            </form>
        </div>
    );
}
