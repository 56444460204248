import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { baseurl, imgUrl } from "Baseurls"
import { Link, useHistory } from "react-router-dom"

const Staff = () => {
  const history = useHistory()
  const [form, setform] = useState([])
  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        baseurl + "blog/getAllPending",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.blogs)
        setIsLoading(false)
      })
  }

  const custsearch = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const token = datas
    console.log(token)
    axios
      .post(
        baseurl + "blog/getAllPending?searchQuery=" + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setusers(res.data.blogs)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const usedata = data => {
    sessionStorage.setItem("blogid", data._id)
    history.push("/editblog")
  }
  const usedata1 = data => {
    sessionStorage.setItem("blogid", data._id)
    history.push("/Viewblog")
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(baseurl + "blog/deleteBlog/" + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const [rol, setrol] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  // const roles = data.rolesAndPermissions
  // const roles1 = data.user.role

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Blog List" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {/* {roles.staffAdd === true ||
                          roles1 === "superadmin" ? (
                            <> */}
                      <Link to="/NewBlog">
                        {" "}
                        <Button color="primary" title="New Blog">
                          New Blog <i className="bx bx-plus-circle"></i>
                        </Button>
                      </Link>
                      {/* </>
                          ) : (
                            ""
                          )} */}
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form.search}
                          onChange={custsearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>Sl No</th>
                          <th>Date</th>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Specialization</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 10 + key + 11}
                            </th>
                            <td>{data.date}</td>
                            <td>{data.heading}</td>
                            <td>
                              <img src={imgUrl + data.image} width={70} />
                            </td>
                            <td>{data.specialisation}</td>
                            <td>
                              <Button
                                className="rounded-pill"
                                size="sm"
                                outline
                                color="warning"
                                title="Status"
                              >
                                {data.status}
                              </Button>
                            </td>

                            <td>
                              {/* {roles.staffEdit === true ||
                              roles1 === "superadmin" ? (
                                <> */}
                              <Button
                                onClick={() => {
                                  usedata1(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="warning"
                                title="View"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-show"
                                ></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  usedata(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                                title="Edit"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>
                              {/* </>
                              ) : (
                                ""
                              )} */}

                              {/* {roles.staffDelete === true ||
                                  roles1 === "superadmin" ? (
                                    <> */}
                              <Button
                                size="sm"
                                className="m-1"
                                outline
                                color="danger"
                                onClick={() => {
                                  manageDelete(data)
                                }}
                                title="Delete"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-trash"
                                ></i>
                              </Button>
                              {/* </>
                                  ) : (
                                    "-"
                                  )} */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Staff
