import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Container, Table, Button } from "reactstrap"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const List = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctor list" />

          <Card>
            <CardBody>
              <Link to="/AddDoctors">
                <Button color="primary" className="m-2">
                  Add Doctor
                  <i className="bx bx-plus-circle"></i>
                </Button>
              </Link>
              <div className="table-responsive">
                <Table className="table table-bordered mb-2 mt-3">
                  <thead>
                    <tr>
                      <th>SlNo</th>
                      <th>Doctor Id</th>
                      <th>Profile</th>
                      <th>Name </th>
                      <th>Phone </th>
                      <th>Email</th>
                      <th>Address </th>
                      <th>Speciality </th>
                      <th>Join Date </th>
                      <th style={{ width: "100px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>ApolloDrc06</td>
                      <td>
                        <img src={avatar1} alt="" width="50px" />
                      </td>
                      <td>Finlande</td>
                      <td>8887744554</td>
                      <td>Finlande@gmail.com</td>
                      <td>Hyderabad</td>
                      <td>Heart Surgeon.</td>
                      <td>12-12-2020</td>
                      <td>
                        <Link to="/AddDoctors">
                          <Button
                            className="mr-2"
                            style={{
                              padding: "6px",
                              margin: "3px",
                            }}
                            color="success"
                            outline
                          >
                            <i className="bx bx-edit "></i>
                          </Button>
                        </Link>
                        <Link to="/ViewDoctor">
                          <Button
                            className="mr-2 "
                            style={{
                              padding: "6px",
                              margin: "3px",
                            }}
                            color="info"
                            outline
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default List
