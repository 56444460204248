
import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { baseurl } from "Baseurls"

function Country() {
    const [modal_small, setmodal_small] = useState(false)
    const [banner, setbanner] = useState([])
    const [form, setform] = useState([])
    console.log(form)
    const [form1, setform1] = useState([])
    const [viewdata, setViewdata] = useState([])

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    // const handleChange = (e, index) => {
    //     const { name, value } = e.target
    //     const list = [...form]
    //     list[index][name] = value
    //     setform(list)
    // }

    const Getredirectpages = () => {
        var token = datas
        axios
            .post(baseurl + 'getdoctormaintenance', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setViewdata(res?.data?.fee)
            })
    }


    useEffect(() => {
        Getalldep()
        Getredirectpages()
    }, [])

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token

    const Getalldep = () => {
        var token = datas
        axios
            .post(baseurl + 'consultation/getcnslt', {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(
                res => {
                    setform(res.data.consultation)
                    setform1(res.data.consultation)
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.message)
                    }
                }
            )
    }

    const formSubmit = (e) => {
        e.preventDefault()
        var token = datas
        const submitdata = {
            oldVideo: form.oldVideo,
            oldAmount: form.oldAmount,
            video: form.video,
            amount: form.amount,
            followupDays: form.followupDays,
            _id: form._id

        }

        // const bodydata = {
        //     consultationArrays: submitdata
        // }
        // const dataArray = new FormData()
        // dataArray.append("oldVideo", form.oldVideo)
        // dataArray.append("oldAmount", form.oldAmount)
        // dataArray.append("video", form.video)
        // dataArray.append("amount", form.amount)
        // dataArray.append("id", form.amount)

        axios
            .put(baseurl + 'consultation/editconsultation', submitdata, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(
                res => {
                    if (res.status === 200) {
                        toast.success(res.data.message)
                        Getalldep()
                    }
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.message)
                    }
                }
            )
    }



    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    // const roles = data.rolesAndPermissions
    // const roles1 = data.user.role

    return (
        <div>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Allude" breadcrumbItem="Consultation Fee" />

                        <Row>

                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Form
                                            onSubmit={e => {
                                                formSubmit(e)
                                            }}
                                        >
                                            {viewdata == false ? (
                                                <p className="text-danger text-center mb-3">You must be update the consultation fee</p>
                                            ) : ""}
                                            {/* {form1.map((data, i) => ( */}
                                            <Row >
                                                <Col md="2">
                                                    <div className="mb-3 mt-3">
                                                        <h5>
                                                            {form.country} :
                                                        </h5>
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Online Fee<span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Fee"
                                                            required
                                                            name="oldVideo"
                                                            value={form.oldVideo}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Offer Online Fee<span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Fee"
                                                            required
                                                            name="video"
                                                            value={form.video}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Direct Fee<span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Fee"
                                                            required
                                                            name="oldAmount"
                                                            value={form.oldAmount}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            Offer Direct Fee <span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Fee"
                                                            required
                                                            name="amount"
                                                            value={form.amount}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                            <Col md="2">
                                                    <div className="mb-3 mt-3">
                                                        <h5>
                                                        Followup Days :
                                                        </h5>
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                        Days<span className="text-danger">*</span>
                                                        </Label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="basicpill-firstname-input1"
                                                            placeholder="Enter Days"
                                                            required
                                                            name="followupDays"
                                                            value={form.followupDays}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div style={{ float: "right" }}>
                                                        <Button style={{ width: "100px" }} className="mt-4" color="primary" type="submit">
                                                            Submit <i className="fas fa-check-circle"></i>
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>
                    </Container>

                    <ToastContainer />
                </div>
            </React.Fragment>
        </div>
    )
}

export default Country