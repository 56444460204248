import React from "react"
import { CardBody, Container, Row, Col, Card,Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import adobephotoshop from "../../assets/images/users/avatar-7.jpg"
import ls from  '../../assets/images/med.png'
import { useHistory } from "react-router-dom"

function DoctorView() {
    const history = useHistory()
  return (
    <div>
      {" "}
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Allude Doctor" breadcrumbItem="Doctor Profile" />
            <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={4}>
                    <div className="text-center">
                      <div className="avatar-md1 profile-user-wid mb-2 mt-1">
                        <span className="ml-5">
                          <img
                            src={adobephotoshop}
                            alt=""
                            className="avatar-md1 rounded-circle img-thumbnail"
                            height="150"
                            width="100"
                          />
                        </span>
                      </div>
                    </div>

                    <ul className="list-unstyled vstack gap-3 mb-0">
                      <li>
                        <div className="d-flex mt-5">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold"> Name: </h6>
                            <span className="text-muted">Rahul </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">
                            Doctor Employee Id:
                            </h6>
                            <span className="text-muted">Apollodrc345</span>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">
                            Phone:
                            </h6>
                            9988776655
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">
                            Email:
                            </h6>
                            Rahul@gmail.com
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">
                            Join Date:
                            </h6>
                            12-05-2023
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex ">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">Experience :</h6>
                            <span className="text-muted">2 years</span>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="d-flex ">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">Status :</h6>
                            <span className="text-muted">Active</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={8}>
                    <h5 className="mb-3">HCE Details:</h5>

                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list mt-4">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">State / Mci Reg No:</h6>
                              <p className="text-muted">Sta11223344</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">
                              Speciality:
                              </h6>
                              <p className="text-muted">Urologist</p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">Field of Medicine </h6>
                              <p className="text-muted">Homeopathi</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 mb-1">Address</h6>
                              <p className="text-muted">
                                6-3-905, Raj Bhavan Rd · 040 4567 4567 Open 24
                                hours
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 mb-1">Description</h6>
                              <p className="text-muted">-</p>
                            </div>
                          </div>
                        </div>
                      </li>
                     

                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 mb-1">
                              State Registation Certificate

                              </h6>
                              <p className="text-muted"><img src={ls} height="80px"/></p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>{" "}
        </div>
      </React.Fragment>
    </div>
  )
}

export default DoctorView
