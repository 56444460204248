import React ,{useEffect, useState }from 'react'
import io from "socket.io-client";
function Chattingapp() {
const [message, setMessage] = useState("");
const [messageReceived, setMessageReceived] = useState("");
const [currentMessage, setCurrentMessage] = useState("");
const [messageList, setMessageList] = useState([]);
const socket = io.connect('https://api.alludeapp.com');
const joinRoom = (room) => {
    if (room !== "") {
      socket.emit("signin", room);
    }
  };

  
const sendMessage = async () => {
    console.log(localStorage.getItem("authUser"));
    if (currentMessage !== "") {
      const messageData = {
        sourceId:JSON.parse(localStorage.getItem("authUser")).userId,
        targetId:"2",
        message: currentMessage
      };

      await socket.emit("message", messageData);
      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
    }
  };

  useEffect(() => {
    joinRoom('6577014b07e8d7432e67a24e');
    socket.on("msg-receive", (data) => {
        console.log("msg-receive",data)
      setMessageList((list) => [...list, data]);
    });
  }, [socket]);

  useEffect(() => {
    // Establish connection to the Socket.IO server
    const socket = io('https://api.alludeapp.com'); // Replace YOUR_SERVER_PORT with the actual server port

    // Event listeners for different events
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    // Simulate signing in by emitting a 'signin' event with an ID
    const userId = JSON.parse(localStorage.getItem("authUser")).userId; // Replace with an actual user ID
    socket.emit('signin', userId);

    // Example of sending a message
    const message = {
      targetId: "23432Fedsfd", // Replace with the target user's ID
      text: currentMessage,
    };
    socket.emit('message', message);

    // Event listener for receiving a message
    socket.on('msg-receive', (receivedMessage) => {
      console.log('Received message:', receivedMessage);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);


  return ( <div className="chat-window" style={{marginTop:"100px"}}>
  <div className="chat-header">
    <p>Live Chat</p>
  </div>
  <div className="chat-body">
    
      {messageList.map((messageContent,i) => {
        return (
          <div key={i}
            className="message"
            
          >
            <div>
              <div className="message-content">
                <p>{messageContent.message}</p>
              </div>
              <div className="message-meta">
                <p id="time">{messageContent.time}</p>
                <p id="author">{messageContent.author}</p>
              </div>
            </div>
          </div>
        );
      })}
  </div>
  <div className="chat-footer">
    <input
      type="text"
      value={currentMessage}
      placeholder="Hey..."
      onChange={(event) => {
        setCurrentMessage(event.target.value);
      }}
    />
    <button onClick={sendMessage}>send</button>
  </div>
</div>
);

}

export default Chattingapp