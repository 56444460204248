import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  CardFooter,
  Form,
  Nav,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import adobephotoshop from "../../assets/images/users/avatar-7.jpg"
import ls from '../../assets/images/med.png'
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import axios from "axios";
import { baseurl } from "Baseurls";

function DoctorView() {
  const history = useHistory()
  const [Agent, setAgent] = useState([])
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }
  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  useEffect(() => {
    getAgent()
    // datass()
  }, [])

  const userId = sessionStorage.getItem('userid')
  const getAgent = () => {
    var token = datas
    const bodydata ={
      id:userId
    }
    axios
      .post(baseurl + 'outpatient/getoutpatientbyid', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.outpatient)
      })
  }


  return (
    <div>
      {" "}
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Allude" breadcrumbItem="User Details" />
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>{" "}
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="12">
                        <div className="text-primary p-3 mb-5">
                          {/* <h5 className="text-primary">Welcome Back !</h5>
                <p>CA Marketing Dashboard</p> */}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="12">
                        <div className="profile-user-wid">
                          <div className="text-center">
                            <img
                              style={{ width: "100px" }}
                              src={adobephotoshop}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <h5 className="font-size-15 text-center mt-1">
                          #DI102150
                        </h5>
                      </Col>
                      <ul className="list-unstyled mt-2">
                        <li>
                          <div className="d-flex">
                            <i className="bx bx-user-circle text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Name</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.name}</p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-phone text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Phone</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.phone}</p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-mail-send text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Email</h6>
                              <p className="text-muted fs-14 mb-0">
                              {Agent.email}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-error-circle text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Gender / Age </h6>
                              <p className="text-muted fs-14 text-break mb-0">
                              {Agent.gender} / {Agent.age}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-map text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Location</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.address}</p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-user text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Height / Weight</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.height} / {Agent.weight}</p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-duplicate text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Blood pressure</h6>
                              <p className="text-muted fs-14 mb-0">{Agent.bloodPressure}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="8">
                <Card>
                  <CardBody>
                    <h5>Consultants</h5>
                    <Table responsive bordered hover >
                      <thead>
                        <tr>
                          <th>SlNo</th>
                          <th>Date</th>
                          <th>Type</th>
                          <th>Problem</th>
                          <th>Since</th>
                          <th>Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>30/10/2023</td>
                          <td>Video</td>
                          <td>Fever</td>
                          <td>10</td>
                          <td>Direct</td>
                          <td>
                            <Button
                              className="m-1"
                              color="success"
                              size="sm"
                              outline
                              onClick={tog_small}
                            >
                              <i style={{ fontSize: "17px" }} className="fas fa-eye"></i>
                            </Button>
                            <Button
                              className="m-1"
                              color="warning"
                              size="sm"
                              outline
                              onClick={tog_small1}
                            >
                              <i style={{ fontSize: "17px" }} className="bx bx-joystick-alt"></i>
                            </Button>
                            {/* </Link> */}
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                  </CardBody>

                </Card>
              </Col>
            </Row>

            <Modal
              size="xl"
              isOpen={modal_small}
              toggle={() => {
                tog_small()
              }}
              centered
            >
              <div className="modal-header">
                <h5>Prescription Details</h5>
                <button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div style={{borderRight:"1px solid gray"}} className="col-md-4">
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <b>Name : </b>
                          <span> Shanker</span>
                        </div>
                        <div className="mb-3">
                          <b>Booking Id : </b>
                          <span> #BI54656</span>
                        </div>
                        <div className="mb-3">
                          <b> Date : </b>
                          <span>26/12/2023</span>
                        </div>
                        <div className="mb-3">
                          <b>Gender : </b>
                          <span>Male</span>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <b>Phone No: </b>
                          <span> 879655665</span>
                        </div>
                        <div className="mb-3">
                          <b>Age </b>
                          <span> 30</span>
                        </div>
                        <div className="mb-3">
                          <b>Booking Time : </b>
                          <span>06:30 PM</span>
                        </div>
                        <div className="mb-3">
                          <b>Consulted  : </b>
                          <span>Direct</span>
                        </div>
                      </Col>
                      <Col md="12">
                      <div className="mb-3">
                          <b>Address: </b>
                          <span> Hyderabad</span>
                        </div>
                      </Col>
                    </Row>


                  </div>
                  <div className="col-md-8 border-left border-dark">
                    <div >
                      <div className="mb-3">
                        <div className="mb-3">
                          <h5>Most Probable Diagnosis</h5>
                          <span>Medical diagnosis or the actual process of
                            making a diagnosis is a cognitive process.</span>
                        </div>
                        <div className="mb-3">
                          <h5>Treatment Suggestions</h5>
                          <div>
                            <ul>
                              <li>Antibiotics for bacteria pneumonia</li>
                              <li>Fever management with aceteminophen. </li>
                              <li>Adequate rest and hydration</li>
                            </ul>
                          </div>
                        </div>
                        <div className="mb-3">
                          <h5>Treatment Suggestions</h5>
                          <div>
                            <ul>
                              <li>Antibiotics for bacteria pneumonia</li>
                              <li>Fever management with aceteminophen. </li>
                              <li>Adequate rest and hydration</li>
                            </ul>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>

                </div>
              </div>
            </Modal>

            <Modal
              size="md"
              isOpen={modal_small1}
              toggle={() => {
                tog_small1()
              }}
              centered
            >
              <div className="modal-header">
                <h5>Medicines List</h5>
                <button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

                <Table hover className="table table-bordered">
                  <thead>
                    <tr className="text-center">
                      <th>S.No</th>
                      <th>Medicine name</th>
                      <th>Times</th>
                      <th>Dosage</th>
                      <th>Duration</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>1</td>
                      <td>Paracetmol</td>
                      <td>Twice</td>
                      <td>1-1-1 three </td>
                      <td>5 Day’s</td>
                      {/* <td>
                        <Button
                          size="sm"
                          color="warning"
                          outline
                          onClick={tog_small}
                        >
                          <i className="bx bx-edit"></i>
                        </Button>
                      </td> */}
                    </tr>
                    <tr className="text-center">
                      <td>2</td>
                      <td>Paracetmol</td>
                      <td>Twice</td>
                      <td>1-1-1 three </td>
                      <td>5 Day’s</td>
                      {/* <td>
                        <Button
                          size="sm"
                          color="warning"
                          outline
                          onClick={tog_small}
                        >
                          <i className="bx bx-edit"></i>
                        </Button>
                      </td> */}
                    </tr>
                  </tbody>
                </Table>

                <div className="mt-3" style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    color="danger"
                    type="button"
                    className="m-1"
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>

                </div>
              </div>
            </Modal>

          </Container>{" "}
        </div>
      </React.Fragment>
    </div>
  )
}

export default DoctorView
