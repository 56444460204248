import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  FormGroup,
} from "reactstrap"
import classnames from "classnames"
import { ToastContainer, toast } from "react-toastify"
import pback from "../../assets/images/ai2.jpg"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import axios from "axios"
import "react-toastify/dist/ReactToastify.css"
import SignatureCanvas from "react-signature-canvas"
import Select from "react-select"
import { baseurl, imgUrl } from "Baseurls"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import { updateData } from "Servicescalls"

const UserProfile = () => {
  const signatureRef = useRef(null)

  const clearSignature = () => {
    signatureRef.current.clear()
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [forms, setforms] = useState([])

  const [modal_small1, setmodal_small1] = useState(false)

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const saveSignature = () => {
    const token = datas
    const signatureDataURL = signatureRef.current.toDataURL()

    const image = new Image()
    image.src = signatureDataURL

    // Wait for the image to load
    image.onload = () => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)

      // Get the base64 data of the image
      const imageBase64 = canvas.toDataURL("image/png")

      const blob = dataURItoBlob(imageBase64)
      const formData = new FormData()
      formData.append("signature", blob, "signature.png")

      axios
        .put(baseurl + "doctorauth/updatedoctorsignature", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          res => {
            if (res.status === 200) {
              toast.success(res.data.message)
              GetProfile()
            }
          },
          error => {
            if (error.response && error.response.status === 400) {
              toast.error(error.response.data.message)
            }
          }
        )
    }
  }

  const dataURItoBlob = dataURI => {
    const byteString = atob(dataURI.split(",")[1])
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ab], { type: mimeString })
  }

  const [langu, setlangu] = useState([])

  const changeLangu = data => {
    setlangu(data)
  }

  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  const [passwordType, setPasswordType] = useState("password")
  const [passwordType1, setPasswordType1] = useState("password")
  const [passwordType12, setPasswordType12] = useState("password")

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }
  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const [Files1, setFiles1] = useState([])

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }

  const [Files2, setFiles2] = useState([])

  const changeHandler2 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles2(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const [activeTab1, setactiveTab1] = useState("5")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [admininfo, setadmininfo] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [address, setaddress] = useState("")
  const [coordinateds, setcoordinateds] = useState({
    lat: "",
    lng: "",
    address: "",
  })

  const handleSelects = value => {
    setaddress(value)
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => setcoordinateds(latLng))
  }

  const GetProfile = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctorauth/getdoctorprofile",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setadmininfo(res.data.doctorProfile)
        setaddress(res.data.doctorProfile.location)
        setform1(res.data.doctorProfile)
        const c = { ...coordinateds }
        c["lat"] = res.data.doctorProfile.latitude
        c["lng"] = res.data.doctorProfile.longitude
        setcoordinateds(c)
        setlangu(
          res?.data?.doctorProfile?.languages.map(data => ({
            value: data.language,
            label: data.language,
          }))
        )
      })
  }

  const [acountdata, setacountdata] = useState([])

  const GetBankdetails = () => {
    var token = datas
    axios
      .post(
        baseurl + "bank/getbankdetails",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setacountdata(res.data.bank)
      })
  }

  const [languagedata, setlanguagedata] = useState([])

  const GetLanguage = () => {
    var token = datas
    axios
      .post(
        baseurl + "getalllangauges",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setlanguagedata(res.data.languages)
      })
  }
  const [specilazdata, setspecilazdata] = useState([])

  const Getspecilaz = () => {
    var token = datas
    axios
      .post(
        baseurl + "getalldoctorspecialisation",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setspecilazdata(res.data.specialisations)
      })
  }

  const editprofiles = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("email", form1.email)
    dataArray.append("phone", form1.phone)
    dataArray.append("countryCode", form1.countryCode || "+91")
    dataArray.append("gender", form1.gender)
    dataArray.append("age", form1.age)
    dataArray.append(
      "languages",
      JSON.stringify(langu.map(data => ({ language: data.value })))
    )
    dataArray.append("specialisation", form1.specialisationId)
    dataArray.append("qualification", form1.qualification)
    dataArray.append("regiseredNumber", form1.regiseredNumber)
    dataArray.append("clinicName", form1.clinicName)
    dataArray.append("address", form1.address)
    dataArray.append("experience", form1.experience)
    dataArray.append("location", address)
    dataArray.append("latitude", coordinateds.lat)
    dataArray.append("longitude", coordinateds.lng)
    dataArray.append("description", form1.description)

    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("clinicLogo", Files1[i])
    }

    for (let i = 0; i < Files2.length; i++) {
      dataArray.append("banner", Files2[i])
    }

    axios
      .put(baseurl + "doctorauth/editdoctorprofile", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetProfile()
            setFiles1({
              clinicLogo: "",
            })
            setFiles2({
              banner: "",
            })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const changepsw = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("oldPassword", form.oldPassword)
    dataArray.append("newPassword", form.newPassword)
    dataArray.append("confirmPassword", form.confirmPassword)

    axios
      .post(baseurl + "doctorauth/changedoctorpassword", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetProfile()
            setform("")
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const clearForm = () => {
    setform({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    })
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editprofiles()
  }
  const handleSubmit = e => {
    e.preventDefault()
    changepsw()
  }

  const changeHandler = e => {
    const token = datas
    const dataArray = new FormData()
    for (let i = 0; i < e.target.files.length; i++) {
      dataArray.append("avatar", e.target.files[i])
    }
    axios
      .put(
        baseurl + "doctorauth/updatedoctoravatar",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
        {}
      )
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetProfile()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    GetProfile()
    GetBankdetails()
    GetLanguage()
    Getspecilaz()
  }, [])

  const options = languagedata.map(data => ({
    value: data.language,
    label: data.language,
  }))

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text")
      return
    }
    setPasswordType1("password")
  }

  const togglePassword12 = () => {
    if (passwordType12 === "password") {
      setPasswordType12("text")
      return
    }
    setPasswordType12("password")
  }

  const [locationName, setLocationName] = useState("")

  // useEffect(() => {
  //   const apiKey = "AIzaSyBAgjZGzhUBDznc-wI5eGRHyjVTfENnLSs"
  //   const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${admininfo.latitude},${admininfo.longitude}&key=${apiKey}`

  //   axios
  //     .get(apiUrl)
  //     .then(response => {
  //       if (response.data.results.length > 0) {
  //         setLocationName(response.data.results[0].formatted_address)
  //       } else {
  //         setLocationName("Location not found")
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Error fetching location:", error)
  //       setLocationName("Error fetching location")
  //     })
  // }, [admininfo])

  const [formss, setformss] = useState([])

  const handlechangesss = e => {
    const myForms = { ...formss }
    myForms[e.target.name] = e.target.value
    setformss(myForms)
  }
  console.log(formss)
  const acountchangesss = e => {
    const myForms = { ...acountdata }
    myForms[e.target.name] = e.target.value
    setacountdata(myForms)
  }

  const handleSubmit12 = async e => {
    e.preventDefault()
    const bodydata = {
      accountNumber: acountdata.accountNumber,
      accountHolderName: acountdata.accountHolderName,
      bankName: acountdata.bankName,
      branch: acountdata.branch,
      IfscCode: acountdata.IfscCode,
      phone: acountdata.phone,
      upiType: acountdata.upiType,
      upiId: acountdata.upiId,
    }
    try {
      const resonse = await updateData("bank/editbank", bodydata)
      var _data = resonse
      toast.success(_data.data.message)
      console.log(_data)
      GetBankdetails()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const getpopup1 = data => {
    tog_small1()
    setforms(data)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Allude Doctor" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="">
                    <Row>
                      <Col md={12}>
                        <div className="text-primary">
                          <h5 className="text-primary"></h5>
                          <p></p>
                        </div>

                        <img
                          style={{ height: "150px", width: "100%" }}
                          src={admininfo.banner == "" ? pback : imgUrl + admininfo.banner}
                          alt=""
                          className="img-fluid"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col md={2}>
                        <a className="auth-logo-light">
                          <div className="avatar-md1 profile-user-wid mb-4">
                            <span className="ml-5">
                              <img
                                src={imgUrl + admininfo.avatar}
                                // src={sai}
                                className="avatar-md1 rounded-circle img-thumbnail"
                                style={{ height: "120px", width: "90%" }}
                              />
                              <div className="text-center">
                                <b> ID: {admininfo.doctorId} </b>
                              </div>
                            </span>
                            <div className="text-center">
                              <Button
                                tag={Label}
                                className="mb-75 me-75 mt-3 text-center"
                                size="sm"
                                color="primary"
                              >
                                Upload{" "}
                                <i className="fas fa-cloud-upload-alt"></i>
                                <Input
                                  name="avatar"
                                  type="file"
                                  onChange={changeHandler}
                                  hidden
                                  accept="image/*"
                                />
                              </Button>
                            </div>
                          </div>
                        </a>
                      </Col>
                      {/* <Col md={3}>
                        <div className="mt-3">
                          <div className="row">
                            <div className="col col-sm-3">
                              <label>Name</label>
                              <br />
                              <label>Phone </label>
                            </div>
                            <div className="col">
                              <label>: {admininfo.name}</label>
                              <br />
                              <label>: {admininfo.phone}</label>
                              <br />
                            </div>
                          </div>


                          <p></p>
                        </div>
                      </Col> */}
                      <Col md={1} />
                      <Col md={9}>
                        <div className="mt-3">
                          <Nav pills className="navtab-bg nav-justified">
                            <NavItem className="border m-2">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "5",
                                })}
                                onClick={() => {
                                  toggle1("5")
                                }}
                              >
                                My Profile
                              </NavLink>
                            </NavItem>
                            <NavItem className="border m-2">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "9",
                                })}
                                onClick={() => {
                                  toggle1("9")
                                }}
                              >
                                Bank Account
                              </NavLink>
                            </NavItem>
                            <NavItem className="border m-2">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "6",
                                })}
                                onClick={() => {
                                  toggle1("6")
                                }}
                              >
                                Edit Profile
                              </NavLink>
                            </NavItem>
                            <NavItem className="border m-2">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "7",
                                })}
                                onClick={() => {
                                  toggle1("7")
                                }}
                              >
                                Password
                              </NavLink>
                            </NavItem>
                            <NavItem className="border m-2">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "8",
                                })}
                                onClick={() => {
                                  toggle1("8")
                                }}
                              >
                                Signature
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>

                <div className="mb-5">
                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <h5> About</h5>

                            <Row className="mt-4">
                              <Col md={8}>
                                <Row>
                                  <Col md={4}>
                                    <p>
                                      <b>Full Name</b>
                                    </p>
                                    <p>
                                      <b>Email</b>
                                    </p>
                                    <p>
                                      <b>Phone</b>
                                    </p>
                                    <p>
                                      <b>Gender</b>
                                    </p>
                                    <p>
                                      <b>Age</b>
                                    </p>
                                    <p>
                                      <b>Registration Number</b>
                                    </p>
                                    <p>
                                      <b>Status</b>
                                    </p>
                                    <p>
                                      <b>Location</b>
                                    </p>
                                    <p>
                                      <b>Clinic Logo</b>
                                    </p>
                                  </Col>
                                  <Col md={8}>
                                    <p>
                                      <b>: </b>
                                      <span>{admininfo.name} </span>
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <span> {admininfo.email}</span>
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <span>
                                        {" "}
                                        {admininfo.countryCode}{" "}
                                        {admininfo.phone}
                                      </span>
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <span> {admininfo.gender}</span>
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <span> {admininfo.age}</span>
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <span> {admininfo.regiseredNumber}</span>
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <span>
                                        {admininfo.status == true
                                          ? "Active"
                                          : "Inactive"}
                                      </span>
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <span>{admininfo.location}</span>
                                      {/* <a href={`https://www.google.com/maps?q=${admininfo.latitude},${admininfo.longitude}`} target="blank">
                                        {locationName}
                                      </a> */}
                                    </p>
                                    <p>
                                      <b>: </b>
                                      <img
                                        src={imgUrl + form1.clinicLogo}
                                        height="50px"
                                        width="50px"
                                      ></img>
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <form
                        onSubmit={e => {
                          handleSubmit1(e)
                        }}
                      >
                        <h5>Edit Profile</h5>
                        <Row>
                          <Col md={3} className="mb-3">
                            <Label>
                              Full Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="name"
                              placeholder="Enter User Name"
                              type="text"
                              className="form-control"
                              value={form1.name}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>

                          <Col md={3} className="mb-3">
                            <Label>
                              Email <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="email"
                              placeholder="Enter Email"
                              type="email"
                              className="form-control"
                              value={form1.email}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>

                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Phone <span className="text-danger">*</span>
                              </Label>

                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <select
                                      value={form1.countryCode}
                                      name="countryCode"
                                      onChange={e => {
                                        handleChange1(e)
                                      }}
                                    >
                                      <option value="+91">+91 </option>
                                      <option value="+1">+1</option>
                                      <option value="+61">+61 </option>
                                    </select>
                                  </div>
                                </div>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  placeholder="Enter Phone"
                                  required
                                  value={form1.phone}
                                  name="phone"
                                  onChange={e => {
                                    handleChange1(e)
                                  }}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md={3} className="mb-3">
                            <Label>
                              Gender <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="gender"
                              value={form1.gender}
                              onChange={e => {
                                handleChange1(e)
                              }}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </Col>

                          <Col md={3} className="mb-3">
                            <Label>
                              Age <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="age"
                              placeholder="Enter Age"
                              type="number"
                              className="form-control"
                              value={form1.age}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>
                          <Col md={3} className="mb-3">
                            <Label>
                              Languages <span className="text-danger">*</span>
                            </Label>
                            <Select
                              isMulti
                              name="colors"
                              options={options}
                              value={langu}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={changeLangu}
                            />
                          </Col>
                          <Col md={3} className="mb-3">
                            <Label>
                              Clinic Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="clinicName"
                              placeholder="Enter Clinic Name"
                              type="text"
                              className="form-control"
                              value={form1.clinicName}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>
                          <Col md={3} className="mb-3">
                            <Label>
                              Clinic Logo <span className="text-danger">*</span>
                            </Label>
                            <Button
                              onClick={() => {
                                getpopup1(imgUrl + form1.clinicLogo)
                              }}
                              className="mr-2"
                              style={{
                                padding: "1px",
                              }}
                              color="info"
                              outline
                              type="button"
                            >
                              <i
                                style={{ fontSize: " 14px" }}
                                className="fas fa-eye"
                              ></i>
                            </Button>
                            <Input
                              name="clinicLogo"
                              placeholder="Enter State"
                              type="file"
                              className="form-control"
                              value={Files1.clinicLogo}
                              onChange={changeHandler1}
                            />
                          </Col>

                          <Col md={3} className="mb-3">
                            <Label>
                              Banner <span className="text-danger">*</span>
                            </Label>
                            <Input
                              placeholder="Enter State"
                              type="file"
                              className="form-control"
                              name="banner"
                              value={Files2.banner}
                              onChange={changeHandler2}
                            />
                          </Col>

                          <Col md={3} className="mb-3">
                            <Label>
                              Registration Number{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="regiseredNumber"
                              placeholder="Enter Registration Number"
                              type="text"
                              className="form-control"
                              value={form1.regiseredNumber}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>
                          <Col md={3} className="mb-3">
                            <Label>
                              Qualification{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="qualification"
                              placeholder="Enter Qualification"
                              type="text"
                              className="form-control"
                              value={form1.qualification}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>
                          <Col md={3} className="mb-3">
                            <Label>
                              Specialization{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              className="form-select"
                              name="specialisationId"
                              value={form1.specialisationId}
                              // select={form1.specialisationId }
                              onChange={e => {
                                handleChange1(e)
                              }}
                            >
                              <option value="">Select</option>
                              {specilazdata.map((data, key) => (
                                <option key={key} value={data._id}>
                                  {data.specialisation}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col md={3} className="mb-3">
                            <Label>
                              Experience <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="experience"
                              placeholder="Enter Experience"
                              type="text"
                              className="form-control"
                              value={form1.experience}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>

                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input3">
                                Location <span className="text-danger">*</span>
                              </Label>
                              <PlacesAutocomplete
                                value={address}
                                onChange={setaddress}
                                onSelect={handleSelects}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div
                                    key={suggestions.description}
                                    className="form-group "
                                  >
                                    <input
                                      {...getInputProps({
                                        placeholder: "Search location ...",
                                        className:
                                          "location-search-input form-control",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item"
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            }

                                        return (
                                          // <></>
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                            key={suggestion.placeId}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </Col>

                          <Col md={3}>
                            <div className="mb-3">
                              <label>Latitude</label>
                              <input
                                id="name"
                                type="number"
                                className="form-control"
                                placeholder="Enter latitude"
                                name="latittude"
                                value={coordinateds.lat}
                                disabled="disabled"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Longitude</label>
                              <input
                                id="name"
                                type="number"
                                className="form-control"
                                placeholder="Enter longitude"
                                name="longitude"
                                value={coordinateds.lng}
                                disabled="disabled"
                                required
                              />
                            </div>
                          </Col>

                          {/* <Col md={4}>
                            <Label>
                              Address <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              name="address"
                              placeholder="Enter Address"
                              type="text"
                              className="form-control"
                              value={form1.address}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col> */}

                          <Col md={4} className="mb-3">
                            <Label>
                              Description <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              name="description"
                              placeholder="Enter Description"
                              type="text"
                              className="form-control"
                              value={form1.description}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </Col>
                        </Row>

                        <div className="mt-3" style={{ float: "right" }}>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </form>
                    </TabPane>
                    <TabPane tabId="7">
                      <form
                        onSubmit={e => {
                          handleSubmit(e)
                        }}
                      >
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <h5>Change Password</h5>

                              <Row className="mt-3">
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Current Password{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div className="input-group">
                                      <input
                                        name="oldPassword"
                                        placeholder="Current Password"
                                        className="form-control"
                                        required
                                        type={passwordType}
                                        value={form.oldPassword}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                      <div className="input-group-btn">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() => {
                                            togglePassword()
                                          }}
                                        >
                                          {passwordType === "password" ? (
                                            <i
                                              className="fa fa-eye-slash"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      New Password{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div className="input-group">
                                      <input
                                        name="newPassword"
                                        placeholder="New Password"
                                        required
                                        type={passwordType1}
                                        className="form-control"
                                        value={form.newPassword}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                      <div className="input-group-btn">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() => {
                                            togglePassword1()
                                          }}
                                        >
                                          {passwordType1 === "password" ? (
                                            <i
                                              className="fa fa-eye-slash"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Confirm Password{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div className="input-group">
                                      <input
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        required
                                        type={passwordType12}
                                        className="form-control"
                                        value={form.confirmPassword}
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                      />
                                      <div className="input-group-btn">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() => {
                                            togglePassword12()
                                          }}
                                        >
                                          {passwordType12 === "password" ? (
                                            <i
                                              className="fa fa-eye-slash"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardText>
                          </Col>
                        </Row>
                        <div style={{ float: "right" }}>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </form>
                    </TabPane>
                    <TabPane tabId="8">
                      <Row>
                        <Col xl={12} lg={12}>
                          <div className="text-end mb-3">
                            <Button
                              onClick={() => {
                                setmodal_small(true)
                              }}
                              color="warning"
                              outline
                            >
                              View <i className="bx bx-show" />
                            </Button>
                          </div>
                          <Card>
                            <CardBody>
                              <h4>Signature </h4>
                              <>
                                <div>
                                  <SignatureCanvas
                                    ref={signatureRef}
                                    value={form1.signature}
                                    penColor="black"
                                    canvasProps={{ width: 900, height: 300 }}
                                  />
                                </div>
                                <div>
                                  <Button
                                    color="danger"
                                    className="m-1"
                                    onClick={clearSignature}
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={saveSignature}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </>
                            </CardBody>{" "}
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="9">
                      <form
                        onSubmit={e => {
                          handleSubmit12(e)
                        }}
                      >
                        <h5>Edit Account details</h5>
                        <Row>
                          <Col md="3">
                            <div className="form-check">
                              <input
                                onChange={e => {
                                  handlechangesss(e)
                                }}
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="Account"
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                Account details
                              </label>
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="form-check">
                              <input
                                onChange={e => {
                                  handlechangesss(e)
                                }}
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios2"
                                value="UPI"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios2"
                              >
                                UPI ID
                              </label>
                            </div>
                          </Col>
                        </Row>

                        {formss.exampleRadios == "UPI" ? (
                          <Row className="mt-3">
                            <Col md={3} className="mb-3">
                              <Label>
                                UPI ID <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="upiId"
                                placeholder="Enter UPI ID"
                                type="text"
                                className="form-control"
                                value={acountdata.upiId}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>

                            <Col md={3} className="mb-3">
                              <Label>
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="phone"
                                placeholder="Enter Phone Number"
                                type="text"
                                className="form-control"
                                value={acountdata.phone}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>
                            <Col md={3} className="mb-3">
                              <Label>
                                UPI Type <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="upiType"
                                placeholder="Enter UPI Type"
                                type="text"
                                className="form-control"
                                value={acountdata.upiType}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <Row className="mt-3">
                            <Col md={3} className="mb-3">
                              <Label>
                                Bank Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="bankName"
                                placeholder="Enter Bank Name"
                                type="text"
                                className="form-control"
                                value={acountdata.bankName}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>
                            <Col md={3} className="mb-3">
                              <Label>
                                Account Holder Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="accountHolderName"
                                placeholder="Enter Account Holder Name"
                                type="text"
                                className="form-control"
                                value={acountdata.accountHolderName}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>
                            <Col md={3} className="mb-3">
                              <Label>
                                Account Number{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="accountNumber"
                                placeholder="Enter Account Number"
                                type="number"
                                className="form-control"
                                value={acountdata.accountNumber}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>
                            <Col md={3} className="mb-3">
                              <Label>
                                IFSC Code <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="IfscCode"
                                placeholder="Enter IFSC Code"
                                type="text"
                                className="form-control"
                                value={acountdata.IfscCode}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>
                            <Col md={3} className="mb-3">
                              <Label>
                                Branch <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="branch"
                                placeholder="Enter Branch"
                                type="text"
                                className="form-control"
                                value={acountdata.branch}
                                onChange={e => {
                                  acountchangesss(e)
                                }}
                              />
                            </Col>
                          </Row>
                        )}

                        <div className="mt-3" style={{ float: "right" }}>
                          <Button
                            style={{ width: "100px" }}
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </form>
                    </TabPane>
                  </TabContent>
                </div>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Signature
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              {form1.signature == "" ? (
                "No Signature Data"
              ) : (
                <img
                  src={imgUrl + form1.signature}
                  style={{ width: "100%", height: "200px" }}
                />
              )}

              <div className="mt-3" style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Clinic Logo
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img alt="" src={forms} width="100%" />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
