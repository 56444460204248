import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Card, CardBody, Col, Row, Button } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import images
import { useHistory } from "react-router-dom"
import avtar1 from "../../assets/images/users/avatar-2.jpg"
import img1 from "../../assets/images/ds1.jpg"
import sai from "../../assets/images/sai.jpg"
import axios from "axios"
import { baseurl, imgUrl } from "Baseurls"

const BlogDetails = () => {
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const blogids = sessionStorage.getItem("blogid")
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const Get = () => {
    var token = datas
    const bodydata = {
      _id: blogids,
    }
    axios
      .post(baseurl + "blog/getBlog", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.data)
        setform1(res.data.isLiked)
      })
  }

  const Addlikes = () => {
    var token = datas
    const bodydata = {
      blogId: blogids,
    }
    axios
      .post(baseurl + "likedislikeblog", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        Get()
      })
  }

  useEffect(() => {
    Get()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Details" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="mb-5">
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <Row>
                            <Col lg="1">
                              <img
                                src={imgUrl + form.doctorAvatar}
                                style={{ width: "50px", height: "50px" }}
                                className="rounded-circle border border-primary"
                              />
                            </Col>
                            <Col lg="5">
                              <h5>
                                {" "}
                                {form.doctorName} ({form.doctorQualification})
                              </h5>
                              <p className="text-muted">
                                {" "}
                                {form.doctorSpecialisation}{" "}
                              </p>
                            </Col>
                            <Col className="text-end mt-3" lg="6">
                              <p className="text-muted">
                                {form.date + "," + form.time}
                              </p>
                            </Col>
                          </Row>

                          <div className="mt-2">
                            <img
                              src={imgUrl + form.image}
                              alt=""
                              style={{ width: "100%", height: "400px" }}
                              className="img-thumbnail mx-auto d-block"
                            />
                          </div>
                          <Row className="mt-3">
                            <Col md="6">
                              <h5>{form.heading}</h5>
                              <b>{form.specialisation}</b>
                            </Col>
                            <Col style={{ float: "right" }} md="6">
                              <a
                                style={{ float: "right" }}
                                className="text-muted"
                              >
                                {form1 == true ? (
                                  <i
                                    onClick={() => {
                                      Addlikes()
                                    }}
                                    style={{ fontSize: "17px" }}
                                    className="bx bxs-heart align-middle text-danger me-1"
                                    title="DisLike"
                                  ></i>
                                ) : (
                                  <i
                                    onClick={() => {
                                      Addlikes()
                                    }}
                                    style={{ fontSize: "17px" }}
                                    className="bx bx-heart align-middle text-danger me-1"
                                    title="Like"
                                  ></i>
                                )}
                                ({form.likesCount})Likes
                              </a>
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <div
                              className="text-muted font-size-14"
                              dangerouslySetInnerHTML={{
                                __html: form.description,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogDetails
