import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data.access
  var Roles1 = data

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            {Roles?.dashboard == true || Roles1?.type === "Doctor" ? (
              <>
                <li>
                  <Link to="/dashboard">
                    <i className="bx bx-home"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            {Roles?.bookings == true || Roles1?.type === "Doctor" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-book-content"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles1?.type === "Doctor" ? (
                      <>
                        <li>
                          <Link to="/pendingops">{props.t("Pending")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles1?.type === "Doctor" ? (
                      <>
                        <li>
                          <Link to="/BookingList">{props.t("Scheduled")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    <li>
                      <Link to="/consulted">{props.t("Consulted")}</Link>
                    </li>
                    {Roles1?.type === "Doctor" ? (
                      <>
                        <li>
                          <Link to="/rejectedbookings">
                            {props.t("Cancelled")}
                          </Link>
                        </li>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {Roles?.walkin == true || Roles1?.type === "Doctor" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-id-card"></i>
                    <span>{props.t("Walk-In OP")}</span>
                  </Link>

                  <ul className="sub-menu">
                    <li>
                      <Link to="/User">{props.t("Walk-In OP")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.payment == true || Roles1?.type === "Doctor" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-rupee"></i>
                    <span>{props.t("Payment")}</span>
                  </Link>

                  <ul className="sub-menu">
                    <li>
                      <Link to="/wallet">{props.t("Wallet")}</Link>
                    </li>
                    <li>
                      <Link to="/Payment">{props.t("Transactions")}</Link>
                    </li>
                  </ul>
                </li>{" "}
              </>
            ) : (
              ""
            )}

            {Roles?.request == true || Roles1?.type === "Doctor" ? (
              <>
                <li>
                  <Link to="/Support">
                    <i className="bx bx-timer"></i>
                    <span>{props.t("Make a Request")}</span>
                  </Link>
                </li>{" "}
              </>
            ) : (
              ""
            )}

            {Roles?.blog == true || Roles1?.type === "Doctor" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-receipt"></i>
                    <span>{props.t("Blog's")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/Blog">{props.t("Add Blog")}</Link>
                    </li>
                    <li>
                      <Link to="/ApprovedBlog">
                        {props.t("Approved Blog List")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/RejectedBlog">
                        {props.t("Reject Blog List")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/allblogs">{props.t("All Blogs")}</Link>
                    </li>
                  </ul>
                </li>{" "}
              </>
            ) : (
              ""
            )}

            {Roles?.settings == true || Roles1?.type === "Doctor" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/SlotList">{props.t("Slot Making")}</Link>
                    </li>
                    <li>
                      <Link to="/Plans">{props.t("Plans")}</Link>
                    </li>
                    <li>
                      <Link to="/consultation">
                        {props.t("Consultation Fee")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Staff">{props.t("Receptionist")}</Link>
                    </li>
                    <li>
                      <Link to="/Faqs">{props.t("Faqs")}</Link>
                    </li>

                    <li>
                      <Link to="/About">{props.t("About")}</Link>
                    </li>
                    <li>
                      <Link to="/PrivacyPolicy">
                        {props.t("Privacy Policy")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Terms">{props.t("Terms & Conditions")}</Link>
                    </li>
                    <li>
                      <Link to="/RefundPolicy">{props.t("Refund Policy")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
