import React, { useState, useEffect } from "react"
import { ZIM } from "zego-zim-web"
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
import logo from "../../assets/images/logos.png" // Ensure to import your logo
import { CardBody, Row, Col, Card } from "reactstrap"

const App = () => {
  const [zp, setZP] = useState(null) // State to hold Zego UI Kit instance

  useEffect(() => {
    // Generate token
    const userID = localStorage.getItem("doctorsid")
    const userName = localStorage.getItem("doctorsName")
    const appID = 1526271279
    const serverSecret = "d8453aecf522ccaad8aabfe854cb94a9"

    // Generate token for testing
    const TOKEN = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      0, // Room ID (use 0 for no specific room)
      userID,
      userName
    )

    // Create Zego UI Kit instance with configuration
    const zpInstance = ZegoUIKitPrebuilt.create(TOKEN, {
      wsServer: "wss://webliveroom1526271279-api.coolzcloud.com/ws",
      turnOnMicrophoneWhenJoining: true,
      turnOnCameraWhenJoining: true,
      showMyCameraToggleButton: true,
      showMyMicrophoneToggleButton: true,
      showAudioVideoSettingsButton: true,
      showScreenSharingButton: true,
      showTextChat: true,
      showUserList: true,
      maxUsers: 2,
      layout: "Auto",
      showLayoutButton: false,
      scenario: {
        mode: "OneONoneCall",
        config: {
          role: "Host",
        },
      },
    })

    // Add ZIM plugin to the instance
    zpInstance.addPlugins({ ZIM })

    // Store the instance in state
    setZP(zpInstance)

    // Cleanup function to destroy the instance on component unmount
    return () => {
      if (zpInstance) {
        zpInstance.destroy()
      }
    }
  }, []) // Empty dependency array to run once on mount

  const invite = () => {
    if (!zp) return // Ensure zp is initialized before using it

    const targetUser = {
      userID: localStorage.getItem("Userids"),
      userName: localStorage.getItem("UserNames"),
    }

    zp.sendCallInvitation({
      callees: [targetUser],
      callType: ZegoUIKitPrebuilt.InvitationTypeVideoCall,
      timeout: 60,
    })
      .then(res => {
        console.warn(res)
      })
      .catch(err => {
        console.warn(err)
      })
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-6">
            <img src={logo} alt="Logo" />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div
          className="myCallContainer"
          style={{ width: "100vw", height: "100vh" }}
        >
          <div className="container">
            <Row className="justify-content-center" style={{ top: "50%" }}>
              <Col md={8}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <h4>
                          <b className="text-primary ">
                            Video Call Invitation :
                          </b>
                        </h4>
                      </Col>

                      <Col md="6 text-end">
                        <button onClick={invite} className="btn btn-primary">
                          <i className="bx bx-video"></i> Invite
                        </button>
                      </Col>
                      <Col md="12" className="text-center mt-4 mb-3">
                        <img
                          src={
                            "https://media.istockphoto.com/id/1334047539/vector/female-patient-having-video-call-with-doctor-online-at-home-telemedicine-telehealth.jpg?s=612x612&w=0&k=20&c=kZ_C2phBqA8InHHE8NxOAy2ZSx1Xe5HP1xXmcE9Xy08="
                          }
                          alt="Logo"
                          height="400px"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
