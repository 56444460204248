import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

function CurrectPlan() {
  const pricings = {
    id: 1,
    title: "Weekly",
    description: "0% / OFF",
    icon: "bx bx-check-circle",
    price: "500",
    duration: "Per month",
    link: "",
    features: [
      { title: "Code Generation 2 (Per day)" },
      { title: "Access Chat Bot Free" },
      { title: "Text Character Length 1000" },
      { title: "Max Token Limit 2000" },
    ],
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-4 mt-5">
                <h4>Currect Plan</h4>
                <p className="text-muted">
                 
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="4" md="6"></Col>
            <Col xl="4" md="6" >
              <Card className="plan-box">
                <CardBody className="p-4">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5>{pricings.title}</h5>
                      <p className="text-muted">{pricings.description}</p>
                    </div>
                    <div className="ms-3">
                      <i
                        className={"bx " + pricings.icon + " h1 text-primary"}
                      />
                    </div>
                  </div>
                  <div className="py-4">
                    <h2>
                      {pricings.price}/{" "}
                      <span className="font-size-13">{pricings.duration}</span>
                    </h2>
                  </div>
                  <div className="text-center">
                    <Link className="btn btn-primary btn-sm ">Expired ON 31-2-2024 </Link>
                  </div>

                  <div className="plan-features mt-5">
                    {pricings.features.map((feature, key) => (
                      <p key={"_feature_" + key}>
                        <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                        {feature.title}
                      </p>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CurrectPlan
