import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Row,
  Input,
  TabContent,
  TabPane,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Container,
  CardImgOverlay,
  CardImg,
  CardTitle,
  CardText,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
//import images
import small from "../../assets/images/ds1.jpg"
import img1 from "../../assets/images/ds1.jpg"
import sai from "../../assets/images/sai.jpg"
import { baseurl, imgUrl } from "Baseurls"
import { Link, useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import axios from "axios"

const BlogList = () => {
  const history = useHistory()
  const [activeTab, toggleTab] = useState("1")
  const [blogs, setblogs] = useState([])
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        baseurl + "blog/getApproved",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setblogs(res.data.blogs)
      })
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = blogs.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(blogs.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const usedata1 = data => {
    sessionStorage.setItem("blogid", data._id)
    history.push("/Viewblog")
  }

  const searchAll = e => {
    var token = datas
    axios
      .post(
        baseurl + "blog/getApproved?searchQuery=" + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setblogs(res.data.blogs)
      })
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Allude Doctor" breadcrumbItem="Blog List" />
            <Row>
              <Col md="3" className="mb-3 offset-md-9">
                <div>
                  <Input
                    type="search"
                    className="form-control rounded-pill"
                    placeholder="Search.."
                    onChange={e => {
                      searchAll(e)
                    }}
                    name="search"
                  />
                </div>
              </Col>
              <Col xl={12} lg={12}>
                <Row>
                  {lists.map((data, key) => (
                    <Col key={key} md="3" className="mt-4">
                      <a
                        onClick={() => {
                          usedata1(data)
                        }}
                        className="text-dark"
                        title="View Blog"
                      >
                        <Card>
                          <CardImg
                            className="img-fluid"
                            src={imgUrl + data.image}
                            alt="allude"
                            style={{ height: "150px" }}
                          />
                          <CardImgOverlay>
                            <CardTitle className="mt-0">
                              <Row>
                                <Col md={6}>
                                  <p
                                    style={{
                                      background: "white",
                                      color: "#B14BF4",
                                      borderRadius: "5px",
                                      padding: "4px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data.specialisation}
                                  </p>
                                </Col>
                              </Row>
                            </CardTitle>
                          </CardImgOverlay>
                          <CardBody>
                            <CardTitle className="mt-0">
                              <Row>
                                <Col md="2">
                                  <img
                                    src={imgUrl + data.doctorAvatar}
                                    style={{ height: "30px", width: "30px" }}
                                    className="rounded-circle"
                                  />
                                </Col>
                                <Col md="10">
                                  <small className="mt-3">
                                    <h6 style={{ color: "black" }}>
                                      {data.doctorName} (
                                      {data.doctorQualification.slice(0, 3) +
                                        "..."}
                                      )
                                    </h6>
                                  </small>
                                </Col>
                              </Row>
                            </CardTitle>
                            <CardText>
                              <div className="mt-3">
                                <b
                                  style={{
                                    color: "#B14BF4",
                                  }}
                                >
                                  {data.heading.length > 20
                                    ? data.heading.slice(0, 1).toUpperCase() +
                                      data.heading.slice(1, 20) +
                                      "..."
                                    : data.heading.slice(0, 1).toUpperCase() +
                                      data.heading.slice(1)}
                                </b>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.description.slice(0, 30),
                                  }}
                                ></div>
                              </div>
                            </CardText>

                            <CardText className="border-top pt-2">
                              <small className="text-muted">
                                <Row>
                                  <Col md="6">
                                    <a to="#" className="text-muted">
                                      <i className="bx bx-time align-middle me-1"></i>
                                      {data.date}
                                    </a>
                                  </Col>
                                  <Col style={{ float: "right" }} md="6">
                                    <a
                                      style={{ float: "right" }}
                                      to="#"
                                      className="text-muted"
                                    >
                                      {data.likesCount == "0" ? (
                                        <>
                                          <i className="bx bxs-heart align-middle text-grey me-1"></i>
                                        </>
                                      ) : (
                                        <>
                                          <i className="bx bxs-heart align-middle text-danger me-1"></i>
                                        </>
                                      )}
                                      ({data.likesCount})Likes
                                    </a>
                                  </Col>
                                </Row>
                              </small>
                            </CardText>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col sm="12">
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default BlogList
