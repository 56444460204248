import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { baseurl } from "Baseurls"

function Payment() {
  const [amounts, setamounts] = useState([])
  const [wallet, setwallet] = useState([])
  const [form2, setform2] = useState([])
  const [show2, setshow2] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handlechange = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  const GetAll = () => {
    var token = datas
    axios
      .post(
        baseurl + "wallet/getallwithdrawalrequest",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setwallet(res.data)
        setamounts(res.data.walletRequest)
      })
  }

  useEffect(() => {
    GetAll()
  }, [])

  const AddRequest = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      description: form2.description,
      amount: form2.amount,
    }
    axios
      .post(baseurl + "wallet/addWalletRequest", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAll()
            clearData()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = amounts.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(amounts.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const clearData = () => {
    setform2({
      amount: "",
      description: "",
    })
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Allude Doctor" breadcrumbItem="Wallet" />
            {show2 == true ? (
              <Card className="p-4">
                <Form
                  onSubmit={e => {
                    filterCustomer(e)
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-4">
                        <Label>Date Range</Label>
                        <Flatpickr
                          required
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                          name="date"
                          onChange={e => {
                            handleDateChange(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="text-end mt-4">
                        <Button type="submit" color="success m-1" outline>
                          Submit <i className="bx bx-check-circle"></i>
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            setshow2(!show2)
                          }}
                          color="danger m-1"
                          outline
                        >
                          Cancel <i className="bx bx-x-circle"></i>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            ) : (
              ""
            )}

            <Card>
              <CardBody>
                <Row>
                  <Col md="6">
                    <Button
                      type="button"
                      color="primary"
                      onClick={tog_small}
                      title="Withdraw Request"
                    >
                      Withdraw Request <i className="bx bx-wallet-alt"></i>
                    </Button>
                  </Col>
                  <Col md="6" className="text-end">
                    <h5 className="text-danger">Wallet: {wallet.wallet}</h5>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-bordered mb-2 mt-3">
                    <thead>
                      <tr>
                        <th>SlNo</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Amount</th>
                        <th>Transaction Id / Booking Id </th>
                        <th>Message</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lists.map((data, key) => (
                        <tr key={key}>
                          <td>{(pageNumber - 1) * 10 + key + 11}</td>
                          <td>{data.date}</td>
                          <td>{data.time}</td>
                          <td>{data.amount}</td>
                          <td>{data.transactionId}</td>
                          <td>{data.description}</td>
                          <td>{data.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="mt-3" style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    total={lists.length}
                  />
                </div>
              </CardBody>
            </Card>
            <Modal
              size="md"
              isOpen={modal_small}
              toggle={() => {
                tog_small()
              }}
              centered
            >
              <div className="modal-header">
                <h5> Withdraw Request</h5>
                <button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={e => {
                    AddRequest(e)
                  }}
                >
                  <div>
                    <div className="mb-3">
                      <Label>
                        Amount <span className="text-danger">*</span>
                      </Label>
                      <input
                        min={500}
                        name="amount"
                        value={form2.amount}
                        onChange={e => {
                          handlechange(e)
                        }}
                        type="number"
                        required
                        className="form-control"
                        placeholder="Enter Amount"
                      />
                      <small className="text-danger">Minimum : 500</small>
                    </div>
                    <div>
                      <Label>Message </Label>
                      <textarea
                        name="description"
                        value={form2.description}
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-control"
                        placeholder="Enter Message"
                      />
                    </div>
                  </div>
                  <div className="mt-3" style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        setmodal_small(false)
                      }}
                      color="danger"
                      type="button"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                    <Button className="m-1" color="primary" type="submit">
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
          </Container>
          <ToastContainer />
        </div>
      </React.Fragment>
    </div>
  )
}

export default Payment
