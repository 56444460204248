import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Label,
  Spinner,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { format } from "date-fns"
import axios from "axios"
import { baseurl } from "Baseurls"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

function Ventures() {
  const [Agent, setAgent] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const [show2, setshow2] = useState(false)

  const history = useHistory()

  const handlechange = e => {
    let myForm = { ...form }
    myForm[e.target.name] = e.target.value
    setform(myForm)
  }

  const [phonedata, setphonedata] = useState([])

  const handlechangephone = e => {
    let myForm = { ...phonedata }
    myForm[e.target.name] = e.target.value
    setphonedata(myForm)
    const bodydata = {
      phone: myForm.phone,
    }
    var token = datas
    axios
      .post(baseurl + "outpatient/getoplist", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.doctOp)
      })
  }

  const handlechange1 = e => {
    let myForm = { ...form1 }
    myForm[e.target.name] = e.target.value
    setform1(myForm)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    getAgent()
    // datass()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles1 = data


  var doctorId = data.doctorId

  const getAgent = () => {
    var token = datas

    if (Roles1?.type === "Doctor") {
      axios
        .post(
          baseurl + "outpatient/getalloutpatients",
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(res => {
          setAgent(res.data.outpatients)
        })
    } else {
      axios
        .post(
          baseurl + "outpatient/getResAllOutPatients",
          {doctorId: doctorId},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(res => {
          setAgent(res.data.outpatients)
        })
    }
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Agent.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Agent.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const setpopup = data => {
    setform1(data)
    setshow1(true)
  }
  const Agentid1 = data => {
    sessionStorage.setItem("userid", data._id)
    history.push("/ViewUser")
  }

  const [address, setaddress] = useState("")
  const [coordinateds, setcoordinateds] = useState({
    lat: "",
    lng: "",
    address: "",
  })
  const [address1, setaddress1] = useState("")
  const [coordinateds1, setcoordinateds1] = useState({
    lat: "",
    lng: "",
    address: "",
  })

  const handleSelects = value => {
    setaddress(value)
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => setcoordinateds(latLng))
  }

  const handleSelects1 = value => {
    setaddress1(value)
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => setcoordinateds1(latLng))
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data.access
  var Roles1 = data

  const AddOPs = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      name: form.name,
      email: form.email,
      countryCode: form.countryCode || "+91",
      phone: phonedata.phone,
      temperature: form.temperature,
      grbs: form.grbs,
      age: form.age,
      gender: form.gender,
      bp: form.bp,
      height: form.height, 
      weight: form.weight,
      address: address,
      problem: form.problem,
      latitude: coordinateds.lat,
      longitude: coordinateds.lng,
    }
    axios
      .post(baseurl + "outpatient/addoutpatient", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            if (Roles1?.type === "Doctor") {
              history.push("/BookingList")
            }
            setshow(false)
            getAgent()
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const EditOPs = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      name: form1.name,
      email: form1.email,
      countryCode: form1.countryCode,
      phone: form1.phone,
      age: form1.age,
      gender: form1.gender,
      bp: form1.bp,
      height: form1.height,
      weight: form1.weight,
      price: form1.price,
      address: address1,
      problem: form1.problem,
      latitude: coordinateds1.lat,
      longitude: coordinateds1.lng,
    }
    axios
      .put(baseurl + "outpatient/updateoutpatient/" + form1._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getAgent()
            setshow1(false)
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const Search = e => {
    const token = datas

    if (Roles1?.type === "Doctor") {
      axios
        .post(
          baseurl + "outpatient/getalloutpatients?searchQuery=",
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(res => {
          setAgent(res.data.outpatients)
        })
    } else {
      axios
        .post(
          baseurl + "outpatient/getResAllOutPatients?searchQuery=",
          {doctorId: doctorId},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(res => {
          setAgent(res.data.outpatients)
        })
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveVenture + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAgent()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const clearForm = () => {
    setform({
      name: "",
      countryCode: "",
      phone: "",
      age: "",
      gende: "",
      bp: "",
      height: "",
      weight: "",
      price: "",
      address: "",
      problem: "",
    })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  // const roles = data.rolesAndPermissions
  // const roles1 = data.user.role

  const [datastate, setDatastate] = useState([])

  let dates = []

  const handleDateChange = NewDate => {
    console.log(NewDate[0])

    const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd")
    const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd")
    dates.push(date1)
    dates.push(date2)
    setDatastate(dates)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Walk-In OP" />

          {show ? (
            <Card>
              <CardBody>
                <h5>Start Booking</h5>
                <Form
                  onSubmit={e => {
                    AddOPs(e)
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Name"
                          required
                          value={form.name}
                          name="name"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Email 
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Email"
                        
                          value={form.email}
                          name="email"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Phone <span className="text-danger">*</span>
                        </Label>

                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <select
                                value={form.countryCode}
                                name="countryCode"
                                onChange={e => {
                                  handlechange(e)
                                }}
                              >
                                <option value="+91">+91 </option>
                                <option value="+1">+1</option>
                                <option value="+61">+61 </option>
                              </select>
                            </div>
                          </div>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Phone"
                            required
                            value={phonedata.phone}
                            name="phone"
                            onChange={e => {
                              handlechangephone(e)
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    {/* <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Email"
                          required
                          value={form.email}
                          name="email"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Profile Pic<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Age"
                          required
                          name="Age"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col> */}
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Gender <span className="text-danger">*</span>
                        </Label>
                        <select
                          required
                          value={form.gender}
                          name="gender"
                          onChange={e => {
                            handlechange(e)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </Col>
                    {/* <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          DOB<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Age"
                          required
                          value={form.Age}
                          name="Age"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col> */}
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Age (Years.months)
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Age"
                          required
                          value={form.age}
                          name="age"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Weight (kgs)
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter weight"
                          value={form.weight}
                          name="weight"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Height (cms)
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Height"
                          value={form.height}
                          name="height"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Temperature
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Temperature"
                          value={form.temperature}
                          name="temperature"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">GRBS</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter GRBS"
                          value={form.grbs}
                          name="grbs"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">BP</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter BP"
                          value={form.bp}
                          name="bp"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    {/* <Col md="3">
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Typle<span className="text-danger">*</span>
                          </Label>
                          <select className="form-select">
                            <option value="">Select</option>
                            <option value="Direct">Direct</option>
                            <option value="Online">Online</option>
                          </select>
                        </div>
                      </Col> */}
                    {/* <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Price<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Price"
                          required
                          value={form.price}
                          name="price"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col> */}
                    {/* <Col lg="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Address <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          type="text"
                          className="form-control "
                          id="basicpill-firstname-input1"
                          placeholder="Enter Address"
                          required
                          value={form.address}
                          name="address"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col> */}
                    <Col md={3}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input3">
                          Location <span className="text-danger">*</span>
                        </Label>
                        <PlacesAutocomplete
                          value={address}
                          onChange={setaddress}
                          onSelect={handleSelects}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div
                              key={suggestions.description}
                              className="form-group "
                            >
                              <input
                                {...getInputProps({
                                  placeholder: "Search location ...",
                                  className:
                                    "location-search-input form-control",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item"
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      }

                                  return (
                                    // <></>
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={suggestion.placeId}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Problem <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Problem"
                          required
                          value={form.problem}
                          name="problem"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="text-end" md="12">
                      <Button
                        onClick={() => {
                          setshow(false)
                        }}
                        type="button"
                        color="danger"
                        className="m-1"
                      >
                        Cancel <i className="bx bx-x-circle" />
                      </Button>
                      <Button type="submit" color="primary" className="m-1">
                        Submit <i className="bx bx-check-circle" />{" "}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          ) : (
            ""
          )}

          {show1 ? (
            <Card>
              <CardBody>
                <h5>Edit OP</h5>
                <Form
                  onSubmit={e => {
                    EditOPs(e)
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Name"
                          required
                          value={form1.name}
                          name="name"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Phone</Label>

                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <select
                                value={form1.countryCode}
                                name="countryCode"
                                onChange={e => {
                                  handlechange1(e)
                                }}
                              >
                                <option value="+91">+91 </option>
                                <option value="61">61 </option>
                                <option value="90">90 </option>
                              </select>
                            </div>
                          </div>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Phone"
                            value={form1.phone}
                            name="phone"
                            onChange={e => {
                              handlechange1(e)
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Gender<span className="text-danger">*</span>
                        </Label>
                        <select
                          required
                          value={form1.gender}
                          name="gender"
                          onChange={e => {
                            handlechange1(e)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </Col>
                    {/* <Col md="3">
                     <div className="mb-2">
                       <Label for="basicpill-firstname-input1">
                         DOB<span className="text-danger">*</span>
                       </Label>
                       <Input
                         type="date"
                         className="form-control"
                         id="basicpill-firstname-input1"
                         placeholder="Enter Age"
                         required
                         value={form.Age}
                         name="Age"
                         onChange={e => {
                           handlechange(e)
                         }}
                       />
                     </div>
                   </Col> */}
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">Age</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Age"
                          value={form1.age}
                          name="age"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">Weight</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter weight"
                          value={form1.weight}
                          name="weight"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">Height</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Height"
                          value={form1.height}
                          name="height"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">BP</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter BP"
                          value={form1.bp}
                          name="bp"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col>

                    {/* <Col md="3">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Price<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Price"
                          required
                          value={form1.price}
                          name="price"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col> */}
                    {/* <Col lg="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Address <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          type="text"
                          className="form-control "
                          id="basicpill-firstname-input1"
                          placeholder="Enter Address"
                          required
                          value={form1.address}
                          name="address"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col> */}
                    <Col md={3}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input3">
                          Location <span className="text-danger">*</span>
                        </Label>
                        <PlacesAutocomplete
                          value={address1}
                          onChange={setaddress1}
                          onSelect={handleSelects1}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div
                              key={suggestions.description}
                              className="form-group "
                            >
                              <input
                                {...getInputProps({
                                  placeholder: "Search location ...",
                                  className:
                                    "location-search-input form-control",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item"
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      }

                                  return (
                                    // <></>
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={suggestion.placeId}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="mb-2">
                        <Label for="basicpill-firstname-input1">
                          Problem<span className="text-danger">*</span>
                        </Label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Problem"
                          required
                          value={form1.problem}
                          name="problem"
                          onChange={e => {
                            handlechange1(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="text-end" md="12">
                      <Button
                        onClick={() => {
                          setshow1(false)
                        }}
                        type="button"
                        color="danger"
                        className="m-1"
                      >
                        Cancel <i className="bx bx-x-circle" />
                      </Button>
                      <Button type="submit" color="primary" className="m-1">
                        Submit <i className="bx bx-check-circle" />{" "}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          ) : (
            ""
          )}

          {show2 == true ? (
            <Card className="p-4">
              <Form
                onSubmit={e => {
                  filterCustomer(e)
                }}
              >
                <Row>
                  <Col md="3">
                    <div className="mb-4">
                      <Label>Date Range</Label>

                      <Flatpickr
                        required
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        name="date"
                        // value={selectedDate}
                        onChange={e => {
                          handleDateChange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="text-end mt-4">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow2(!show2)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          ) : (
            ""
          )}

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    {/* {roles.ventureAdd === true || roles1 === "superadmin" ? (
                      <> */}
                    <Col md="6">
                      <Button
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="primary"
                        title="New Op"
                      >
                        New OP <i className="bx bx-plus-circle"></i>
                      </Button>
                    </Col>
                    {/* </>
                    ) : (
                      ""
                    )}{" "} */}
                    <Col md="6">
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          onChange={e => {
                            Search(e)
                          }}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                      {/* <Button style={{ float: "right", marginRight: "10px", marginLeft: "10px" }} onClick={() => { setshow2(!show2) }} color="warning">
                        Filter <i className="bx bx-filter-alt"></i>
                      </Button> */}
                      <div
                        style={{
                          float: "right",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                        className="text-end mt-2"
                      >
                        {/* <b>OP's Count: {Agent.length}</b> */}
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-2">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Date</th>
                          <th>OP Id</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Gender</th>
                          <th>Age</th>
                          <th>Problem</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <th>{(pageNumber - 1) * 10 + key + 11}</th>
                            <td>{data.date}</td>
                            <td>
                              {/* <Link to="/ViewUser"> */}
                              {data.outPatientNo}
                              {/* </Link> */}
                            </td>
                            <td>{data.name}</td>
                            <td>{data.phone}</td>
                            <td>{data.gender}</td>
                            <td>{data.age}</td>
                            <td>{data.problem}</td>
                            {/* <td>
                              <Button
                                onClick={() => {
                                  Agentid1(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="warning"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="fas fa-eye"
                                ></i>
                              </Button>

                              <Button
                                onClick={() => {
                                  setpopup(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>
                              <Button
                              onClick={() => {
                                manageDelete(data)
                              }}
                              size="sm"
                              className="m-1"
                              outline
                              color="danger"
                            >
                              <i
                                style={{ fontSize: " 14px" }}
                                className="bx bxs-trash"
                              ></i>
                            </Button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                  {/* </>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
