import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Modal, Card, CardBody, Container, FormFeedback, Input, Label, Form, Button } from "reactstrap";
import { withRouter, Link, useHistory  } from "react-router-dom";
import profile from "../../assets/images/latest/locked.png";
import logo from "../../assets/images/logo allude color.png";
import { imgUrl, baseurl } from "Baseurls"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"

const ForgetPasswordPage = () => {
    const history = useHistory()
    // var gets = localStorage.getItem("authUser")
    // var data = JSON.parse(gets)
    // var datas = data.token

    const [modal_small, setmodal_small] = useState(false)
    function tog_small() {
        setmodal_small(!modal_small)
    }

    const [form, setform] = useState([])
    const [Files, setFiles] = useState("")

    const changeHandler = (e) => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png") {
            setFiles(e.target.files)
        } else {
            e.target.value = null
            toast("file format not supported.Pls choose Image")
        }
    }

    const handleChange = (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    const AddRequest = (e) => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("email", localStorage.getItem('authemail'))
        dataArray.append("description", form.description)
        for (let i = 0; i < Files.length; i++) {
            dataArray.append("image", Files[i])
        }
        axios
            .post(baseurl + 'doctorauth/addunblockrequest', dataArray,
                {
                    // headers: { Authorization: `Bearer ${token}` },
                })
            .then(
                res => {
                    if (res.status === 200) {
                        toast.success(res.data.message)
                        setmodal_small(false)
                    }
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.message)
                    }
                }
            )
    }

    // const Getredirectpages = () => {
    //     var token = datas
    //     axios
    //         .post(baseurl + 'getdoctormaintenance', {},
    //             {
    //                 headers: { Authorization: `Bearer ${token}` },
    //             }
    //         )
    //         .then(res => {
    //             if (res?.data?.maintenance?.underMaintenance == false) {
    //                 history.push('/')
    //             } else if (res?.data?.doctor?.status == false) {
    //                 history.push('/access')
    //             } else {
    //                 null
    //             }
    //         })
    // }
    // useEffect(() => {
    //     Getredirectpages();
    //     const intervalId = setInterval(() => {
    //         Getredirectpages();
    //     }, 10000);
    //     return () => clearInterval(intervalId);
    // }, [])


    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-2 pt-sm-5">
                <Container>
                    <div>
                        <Link to="/login">
                            <img src={logo} />
                        </Link>
                    </div>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Row>
                                <img src={profile} />
                                <p style={{ fontSize: "16px" }}>
                                    <b>Reason:</b> The administrator disabled your information because you misused our programme. <Link to="/"> Login </Link>
                                </p>
                                <div className="text-center mt-2">
                                    <Button onClick={tog_small} className="w-25" color="primary">Request  <i className="bx bx-right-arrow-circle" /></Button>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="md"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                    centered
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => { AddRequest(e) }}>
                            <div>
                                <div className="mb-3">
                                    <Label>Message <span className="text-danger">*</span></Label>
                                    <textarea onChange={(e) => { handleChange(e) }} name="description" value={form.description} rows="5" required className="form-control" placeholder="Enter Message" />
                                </div>
                                <div>
                                    <Label>File</Label>
                                    <input name="image" onChange={changeHandler} type="file" required className="form-control" placeholder="Enter Message" />
                                </div>
                            </div>
                            <div className="mt-3" style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"

                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="primary" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <ToastContainer />
            </div>
        </React.Fragment>
    );
};

ForgetPasswordPage.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
