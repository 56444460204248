import React, { useEffect, useState } from 'react'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Table, Button, Row, Col } from 'reactstrap'
import { Card, CardBody, Container, Modal, Form, Label } from 'reactstrap'
import { Link } from 'react-router-dom'

function Slotlist() {
    const [modal_small1, setmodal_small1] = useState(false)
    function tog_small1() {
        setmodal_small1(!modal_small)
    }

    const [form2, setform2] = useState([]);
    console.log(form2)

    const getpopup1 = () => {
        tog_small1()

    }

    const handlechange2 = (e) => {
        let myUser = { ...form2 };
        myUser[e.target.name] = e.target.value;
        setform2(myUser);
    };
    // const changehandle = e => {
    //     // const file = e.target.files
    //     setFile(e.target.files)
    // }
    const addUsers2 = () => {

        var params = {
            SlNo: form2.SlNo,
            Date: form2.Date,
            FromTime: form2.FromTime,
            ToTime: form2.ToTime,

        }
        alert(JSON.stringify(params));
        setmodal_small1(false)

    }

    const usersSubmit2 = (e) => {
        e.preventDefault();
        addUsers2();


    };


    const [modal_small, setmodal_small] = useState(false)
    function tog_small() {
        setmodal_small(!modal_small)
    }

    const [form, setform] = useState([]);
    console.log(form)



    const [form1, setform1] = useState([]);
    console.log(form1)
    const addUsers1 = () => {
        var params = {
            SlNo: form.SlNo,
            Date: form.Date,
            FromTime: form.FromTime,
            ToTime: form.ToTime,

        }
        alert(JSON.stringify(params))
    }
    useEffect(() => {

        var text = [{
            SlNo: 1,
            Date: "2023-09-27",
            FromTime: "12:00",
            ToTime: "04:00",

        },
        {
            SlNo: 2,
            Date: "2023-09-02",
            FromTime: "01:00",
            ToTime: "05:00",

        }]
        setform(text);

    }, [])


    const getpopup = (data) => {
        setform1(data)
        tog_small()

    }

    const handleChange1 = (e) => {
        let myUser = { ...form1 };
        myUser[e.target.name] = e.target.value;
        setform1(myUser);
    };
    // const changehandle = e => {
    //     // const file = e.target.files
    //     setFile(e.target.files)
    // }
    const addUsers = () => {

        var params = {

            FromTime: form1.FromTime,
            ToTime: form1.ToTime,


        }
        alert(JSON.stringify(params));
        setmodal_small(false)

    }

    const usersSubmit1 = (e) => {
        e.preventDefault();
        addUsers();


    };




    return (
        <div>
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Allude Doctor" breadcrumbItem="Add Slot" />

                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        {/* <h5>Holidays's List</h5> */}
                                        <Col>
                                            <Link to="/addslot">
                                                <Button color="primary" className="m-2">
                                                    Add Slot
                                                    <i className="bx bx-plus-circle"></i>
                                                </Button>
                                            </Link>
                                        </Col>
                                        <div>
                                            <div className="table-responsive">
                                                <Table className="table table-bordered mb-2 mt-3">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>S.No</th>
                                                            <th>Date</th>
                                                            <th>Session</th>
                                                            <th>Start Time</th>
                                                            <th>End Time</th>
                                                            <th>Slots Count</th>
                                                            <th style={{ width: "100px" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {from2.map((data, key) => ( */}
                                                        <tr className="text-center">
                                                            <td>1</td>
                                                            <td>17/10/2023</td>
                                                            <td>2</td>
                                                            <td>10:00 AM</td>
                                                            <td>2:00 PM</td>
                                                            <td>30</td>
                                                           
                                                            <td style={{ width: "150px" }}>
                                                                <Link to="/addslot">
                                                                    <Button
                                                                        // onClick={() => {
                                                                        //   AgentidS(data)
                                                                        // }}
                                                                        size="sm"
                                                                        className="m-1"
                                                                        outline
                                                                        color="success"
                                                                    >
                                                                        <i
                                                                            style={{ fontSize: " 14px" }}
                                                                            className="bx bx-edit"
                                                                        ></i>
                                                                    </Button>
                                                                </Link>
                                                                <Link to="/SlotList">
                                                                    <Button
                                                                        // onClick={() => {
                                                                        //   AgentidS(data)
                                                                        // }}
                                                                        size="sm"
                                                                        className="m-1"
                                                                        outline
                                                                        color="warning"
                                                                    >
                                                                        <i
                                                                            style={{ fontSize: " 14px" }}
                                                                            className="bx bx-show-alt"
                                                                        ></i>
                                                                    </Button>
                                                                </Link>
                                                                <Button
                                                                    //   onClick={() => {
                                                                    //     manageDelete(data)
                                                                    //   }}
                                                                    size="sm"
                                                                    className="m-1"
                                                                    color="danger"
                                                                    outline
                                                                >
                                                                    <i className="bx bx-trash"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        {/* ))} */}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
            </React.Fragment>


        </div >
    )
}

export default Slotlist