import PropTypes from "prop-types";
import React, {useState} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { ToastContainer, toast } from "react-toastify"
import { addData } from "Servicescalls"

const ForgetPasswordPage = () => {
  const history = useHistory()

  const [form, setform] = useState([])
  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
};

 // Add function

 const handleSubmit = async e => {
  e.preventDefault()
  const bodydata = {
    email: form.email,
  }
  try {
    const resonse = await addData("doctorauth/sendotp", bodydata)
    var _data = resonse
    console.log(_data)
    toast.success(_data.data.message)
    sessionStorage.setItem("forgotemail", form.email)
    history.push("/otp")

  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      toast.error(error.response.data.message)
    } else {
      // toast.error("An error occurred. Please try again.")
    }
  }
}


  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Allude.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {handleSubmit(e)}}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={(e)=>{handleChange(e)}}
                          // onChange={validation.handleChange}
                          // onBlur={validation.handleBlur}
                          // value={validation.values.email || ""}
                          // invalid={
                          //   validation.touched.email && validation.errors.email ? true : false
                          // }
                        />
                        {/* {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null} */}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p className="mb-0">
                          © {new Date().getFullYear()} VRAH Telehealth Services Private Limited
                        </p>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);


// import React, { useState } from 'react';
// import axios from 'axios';

// const GPTComponent = () => {
//   const [inputText, setInputText] = useState('');
//   const [outputText, setOutputText] = useState('');
//   console.log(inputText)
//   console.log(outputText)
//   const [tests, setTests] = useState([]);
//   console.log(tests)
//   const model = "gpt-3.5-turbo-0301";

//   const handleGenerateResponse = async () => {
//     try {
//       const response = await axios.post(
//         'https://api.openai.com/v1/chat/completions',
//         {
//           model,
//           messages: [
//             { role: 'system', content: 'You are a helpful assistant.' },
//             { role: 'user', content: inputText },
//           ],
//           stream: true,
//         },
//         {
//           headers: {
//             Authorization: 'Bearer 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',
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       const stream = response.data.choices[0].message.content.getReader();
//       console.log(stream)
//       const parsedData = await readStreamPromise(stream);
//       console.log(parsedData)
//       setTests(parsedData);

//       const deltaContents = parsedData.map((item) =>
//         item ? item.choices[0].delta.content || '' : ''
//       );

//       setOutputText(deltaContents.join(''));
//     } catch (error) {
//       console.error('Error generating response:', error);
//     }
//   };

//   function readStreamPromise(reader) {
//     let chunksReceived = [];
//     return new Promise((resolve, reject) => {
//       function readStream() {
//         reader
//           .read()
//           .then(({ done, value }) => {
//             if (done) {
//               resolve(chunksReceived);
//               return;
//             }
//             const chunk = new TextDecoder('utf-8').decode(value);
//             const chunks = chunk.split('data: ');
//             let listChunk = chunks.slice(1).map((x) => {
//               try {
//                 return JSON.parse(x);
//               } catch {
//                 return null;
//               }
//             });
//             chunksReceived = chunksReceived.concat(listChunk);
//             readStream();
//           })
//           .catch((error) => {
//             reject(error); // handle any errors that occur during reading
//           });
//       }
//       readStream();
//     });
//   }

//   return (
//     <div>
//       <textarea
//         value={inputText}
//         onChange={(e) => setInputText(e.target.value)}
//       />
//       <button onClick={handleGenerateResponse}>Response</button>
//       <div>
//         <strong>Output:</strong>
//         <p>{outputText}</p>
//         <ul>
//           {tests.map((item, index) => (
//             <li key={index}>{item.choices[0].delta.content}</li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default GPTComponent;




