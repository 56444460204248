import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Form,
  Label,
} from "reactstrap"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { baseurl } from "Baseurls"
import { CSVLink } from "react-csv"

function Ventures() {
  const [userInCsv, setuserInCsv] = useState([])

  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
      getBookings(tab)
    }
  }

  const tabbydata =
    activeTab1 == "2" ? "Direct" : activeTab1 == "3" ? "Call" : ""

  //model
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }
  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [form, setform] = useState([])

  const [bookingslist, setbookingslist] = useState([])
  const [bookingslistcount, setbookingslistcount] = useState([])

  const history = useHistory()

  const [form1, setform1] = useState([])

  const handlechange1 = e => {
    const myData = { ...form1 }
    myData[e.target.name] = e.target.value
    setform1(myData)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    getBookings()
  }, [])

  const getBookings = tab => {
    var token = datas
    const bodydata = {
      fromDate: "",
      toDate: "",
      consultationType: tab == "2" ? "Direct" : tab == "3" ? "Call" : "",
    }
    axios
      .post(baseurl + "outpatient/getAccepted", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setbookingslist(res.data.outpatients)
        setbookingslistcount(res.data.bookingCount)
        setuserInCsv(
          res.data.outpatients.map((data, index) => ({
            "Sl.No": index + 1,
            "Booking Id": data.bookingNo,
            "Full Name": data.userName,
            Phone: data.userPhone,
            "Date & Time": data.date + "-" + data.time,
            Type: data.consultationType,
            Slot: data.bookingDate + "-" + data.slot,
            Problem: data.problem,
            Amount: data.basePrice,
          }))
        )
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = bookingslist.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(bookingslist.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Search = e => {
    const token = datas
    const bodydata = {
      fromDate: "",
      toDate: "",
      consultationType: tabbydata,
    }
    axios
      .post(
        baseurl + "outpatient/getAccepted?searchQuery=" + `${e.target.value}`,
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setbookingslist(res.data.outpatients)
            setbookingslistcount(res.data.bookingCount)
            setuserInCsv(
              res.data.outpatients.map((data, index) => ({
                "Sl.No": index + 1,
                "Booking Id": data.bookingNo,
                "Full Name": data.userName,
                Phone: data.userPhone,
                "Date & Time": data.date + "-" + data.time,
                Type: data.consultationType,
                Slot: data.bookingDate + "-" + data.slot,
                Problem: data.problem,
                Amount: data.basePrice,
              }))
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const Agentid = data => {
    history.push("/ViewBooking")
    sessionStorage.setItem("bookingid", data._id)
    sessionStorage.setItem("views", "false")
  }

  const getpopup = data => {
    tog_small()
    setform(data)
  }

  const getpopup1 = data => {
    tog_small1()
    setform(data)
  }

  const bookingacc = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      status: "Accepted",
    }
    axios
      .put(baseurl + "outpatient/updatebookingstatus/" + form._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getBookings()
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }
  const bookingcancel = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      status: "Rejected",
      rejectedReason: form1.rejectedReason,
    }
    axios
      .put(baseurl + "outpatient/updatebookingstatus/" + form._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getBookings()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const csvReport = {
    filename: "Booking List",
    data: userInCsv,
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetActinFiliter()
  }

  const GetActinFiliter = () => {
    var token = datas
    const bodydata = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      consultationType: tabbydata,
    }

    axios
      .post(baseurl + "outpatient/getAccepted", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setbookingslist(res.data.outpatients)
        setbookingslistcount(res.data.bookingCount)
        setuserInCsv(
          res.data.outpatients.map((data, index) => ({
            "Sl.No": index + 1,
            "Booking Id": data.bookingNo,
            "Full Name": data.userName,
            Phone: data.userPhone,
            "Date & Time": data.date + "-" + data.time,
            Type: data.consultationType,
            Slot: data.bookingDate + "-" + data.slot,
            Problem: data.problem,
            Amount: data.basePrice,
          }))
        )
      })
  }

  const hidefilter = () => {
    setfilter(false)
    setfilters({
      fromDate: "",
      toDate: "",
    })
    getBookings()
  }

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Allude Doctor"
            breadcrumbItem="Scheduled Bookings"
          />
          <Row>
            <Col>
              <Row className="mb-3">
                <Col md="5">
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem className="border border-primary rounded m-1">
                      <NavLink
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={classnames({
                          active: activeTab1 === "1",
                        })}
                        onClick={() => {
                          toggle1("1")
                        }}
                      >
                        Total : {bookingslistcount.totalBookings}
                      </NavLink>
                    </NavItem>
                    <NavItem className="border border-primary rounded m-1">
                      <NavLink
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={classnames({
                          active: activeTab1 === "2",
                        })}
                        onClick={() => {
                          toggle1("2")
                        }}
                      >
                        Walk-In : {bookingslistcount.directBookings}
                      </NavLink>
                    </NavItem>
                    <NavItem className="border border-primary rounded m-1">
                      <NavLink
                        style={{ cursor: "pointer", padding: "10px" }}
                        className={classnames({
                          active: activeTab1 === "3",
                        })}
                        onClick={() => {
                          toggle1("3")
                        }}
                      >
                        Online : {bookingslistcount.callBookings}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        {filter ? (
                          <>
                            <Form
                              onSubmit={e => {
                                getfilter(e)
                              }}
                            >
                              <Row className="mb-4">
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      From Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      onChange={e => {
                                        handleChangeflt(e)
                                      }}
                                      name="fromDate"
                                      value={filters.fromDate}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      To Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      onChange={e => {
                                        handleChangeflt(e)
                                      }}
                                      name="toDate"
                                      value={filters.toDate}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mt-4">
                                    <Button
                                      type="submit"
                                      className="m-1"
                                      color="info"
                                    >
                                      <i className="fas fa-check-circle"></i>{" "}
                                      search
                                    </Button>
                                    <Button
                                      onClick={hidefilter}
                                      className="m-1"
                                      color="danger"
                                    >
                                      <i className="fas fa-times-circle"></i>{" "}
                                      Cancel
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Button
                            className="m-1"
                            onClick={() => {
                              setfilter(!filter)
                            }}
                            color="info"
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                          <CSVLink {...csvReport}>
                            <button
                              className="btn btn-success"
                              type="button"
                              style={{ marginLeft: "20px" }}
                            >
                              <i className="fas fa-file-excel"></i> Excel
                            </button>
                          </CSVLink>{" "}
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              onChange={e => {
                                Search(e)
                              }}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin  table-responsive">
                        <Table hover className="table table-bordered mb-4 mt-2">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Booking Id</th>
                              <th>Full Name</th>
                              <th>Phone</th>
                              <th>Date & Time</th>
                              <th>Type</th>
                              <th>Slot</th>
                              <th>Problem </th>
                              <th>Amount </th>
                              <th>View </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.length == 0 ? (
                              <tr className="text-center">
                                <th colSpan={9}>No data...</th>
                              </tr>
                            ) : (
                              <>
                                {lists.map((data, key) => (
                                  <tr className="text-center" key={key}>
                                    <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                    <td>{data.bookingNo}</td>
                                    <td>{data.userName}</td>
                                    <td>{data.userPhone}</td>
                                    <td>
                                      {data.date} , {data.time}
                                    </td>
                                    <td>
                                      {data.consultationType == "Direct" ||
                                      data.consultationType == "Clinic"
                                        ? "Walk-In"
                                        : data.consultationType}
                                    </td>
                                    <td>
                                      <span>{data.bookingDate}</span>
                                      <br />
                                      <span>({data.slot})</span>
                                    </td>
                                    <td>{data.problem}</td>
                                    <td>{data.basePrice}</td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Agentid(data)
                                        }}
                                        color="info"
                                        outline
                                        title="View"
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        {filter ? (
                          <>
                            <Form
                              onSubmit={e => {
                                getfilter(e)
                              }}
                            >
                              <Row className="mb-4">
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      From Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      onChange={e => {
                                        handleChangeflt(e)
                                      }}
                                      name="fromDate"
                                      value={filters.fromDate}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      To Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      onChange={e => {
                                        handleChangeflt(e)
                                      }}
                                      name="toDate"
                                      value={filters.toDate}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mt-4">
                                    <Button
                                      type="submit"
                                      className="m-1"
                                      color="info"
                                    >
                                      <i className="fas fa-check-circle"></i>{" "}
                                      search
                                    </Button>
                                    <Button
                                      onClick={hidefilter}
                                      className="m-1"
                                      color="danger"
                                    >
                                      <i className="fas fa-times-circle"></i>{" "}
                                      Cancel
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Button
                            className="m-1"
                            onClick={() => {
                              setfilter(!filter)
                            }}
                            color="info"
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                          <CSVLink {...csvReport}>
                            <button
                              className="btn btn-success"
                              type="button"
                              style={{ marginLeft: "20px" }}
                            >
                              <i className="fas fa-file-excel"></i> Excel
                            </button>
                          </CSVLink>{" "}
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              onChange={e => {
                                Search(e)
                              }}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin  table-responsive">
                        <Table hover className="table table-bordered mb-4 mt-2">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Booking Id</th>
                              <th>Full Name</th>
                              <th>Phone</th>
                              <th>Date & Time</th>
                              <th>Type</th>
                              <th>Slot</th>
                              <th>Problem </th>
                              <th>Amount </th>
                              <th>View </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.length == 0 ? (
                              <tr className="text-center">
                                <th colSpan={9}>No data...</th>
                              </tr>
                            ) : (
                              <>
                                {lists.map((data, key) => (
                                  <tr className="text-center" key={key}>
                                    <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                    <td>{data.bookingNo}</td>
                                    <td>{data.userName}</td>
                                    <td>{data.userPhone}</td>
                                    <td>
                                      {data.date} , {data.time}
                                    </td>
                                    <td>
                                      {data.consultationType == "Direct" ||
                                      data.consultationType == "Clinic"
                                        ? "Walk-In"
                                        : data.consultationType}
                                    </td>
                                    <td>
                                      <span>{data.bookingDate}</span>
                                      <br />
                                      <span>({data.slot})</span>
                                    </td>
                                    <td>{data.problem}</td>
                                    <td>{data.basePrice}</td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Agentid(data)
                                        }}
                                        color="info"
                                        outline
                                        title="View"
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        {filter ? (
                          <>
                            <Form
                              onSubmit={e => {
                                getfilter(e)
                              }}
                            >
                              <Row className="mb-4">
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      From Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      onChange={e => {
                                        handleChangeflt(e)
                                      }}
                                      name="fromDate"
                                      value={filters.fromDate}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                      To Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      onChange={e => {
                                        handleChangeflt(e)
                                      }}
                                      name="toDate"
                                      value={filters.toDate}
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mt-4">
                                    <Button
                                      type="submit"
                                      className="m-1"
                                      color="info"
                                    >
                                      <i className="fas fa-check-circle"></i>{" "}
                                      search
                                    </Button>
                                    <Button
                                      onClick={hidefilter}
                                      className="m-1"
                                      color="danger"
                                    >
                                      <i className="fas fa-times-circle"></i>{" "}
                                      Cancel
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Button
                            className="m-1"
                            onClick={() => {
                              setfilter(!filter)
                            }}
                            color="info"
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                          <CSVLink {...csvReport}>
                            <button
                              className="btn btn-success"
                              type="button"
                              style={{ marginLeft: "20px" }}
                            >
                              <i className="fas fa-file-excel"></i> Excel
                            </button>
                          </CSVLink>{" "}
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              onChange={e => {
                                Search(e)
                              }}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin  table-responsive">
                        <Table hover className="table table-bordered mb-4 mt-2">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Booking Id</th>
                              <th>Full Name</th>
                              <th>Phone</th>
                              <th>Date & Time</th>
                              <th>Type</th>
                              <th>Slot</th>
                              <th>Problem </th>
                              <th>Amount </th>
                              <th>View </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.length == 0 ? (
                              <tr className="text-center">
                                <th colSpan={9}>No data...</th>
                              </tr>
                            ) : (
                              <>
                                {lists.map((data, key) => (
                                  <tr className="text-center" key={key}>
                                    <th>{(pageNumber - 1) * 10 + key + 11}</th>
                                    <td>{data.bookingNo}</td>
                                    <td>{data.userName}</td>
                                    <td>{data.userPhone}</td>
                                    <td>
                                      {data.date} , {data.time}
                                    </td>
                                    <td>
                                      {data.consultationType == "Direct" ||
                                      data.consultationType == "Clinic"
                                        ? "Walk-In"
                                        : data.consultationType}
                                    </td>
                                    <td>
                                      <span>{data.bookingDate}</span>
                                      <br />
                                      <span>({data.slot})</span>
                                    </td>
                                    <td>{data.problem}</td>
                                    <td>{data.basePrice}</td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Agentid(data)
                                        }}
                                        color="info"
                                        outline
                                        title="View"
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div style={{ borderBottom: "0px" }} className="modal-header">
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  bookingcancel(e)
                }}
              >
                <div className="text-center">
                  <i
                    style={{ fontSize: "100px" }}
                    className="bx bx-info-circle text-warning"
                  />
                  <h5>Do you want reject this booking</h5>

                  <textarea
                    className="form-control mt-3"
                    name="rejectedReason"
                    placeholder="Enter Reason"
                    value={form1.rejectedReason}
                    onChange={e => {
                      handlechange1(e)
                    }}
                    type="text"
                    required
                  />
                </div>

                <div className="text-center mt-3">
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    style={{ width: "100px" }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button
                    style={{ width: "100px" }}
                    className="m-1"
                    color="success"
                    type="submit"
                  >
                    Yes <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            size="md"
            isOpen={modal_small1}
            toggle={() => {
              tog_small1()
            }}
            centered
          >
            <div style={{ borderBottom: "0px" }} className="modal-header">
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  bookingacc(e)
                }}
              >
                <div className="text-center">
                  <i
                    style={{ fontSize: "100px" }}
                    className="bx bx-info-circle text-warning"
                  />
                  <h5>Do you want accept this booking</h5>
                </div>
                <div className="text-center mt-3">
                  <Button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    style={{ width: "100px" }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button
                    style={{ width: "100px" }}
                    className="m-1"
                    color="success"
                    type="submit"
                  >
                    Yes <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
