import React, { useState, useEffect } from "react"
import {
  CardBody,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Modal,
  Input,
  Label,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"
import logo from "../../assets/images/favs.png"
import adobephotoshop from "../../assets/images/users/avatar-7.jpg"
import classnames from "classnames"
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react"
import axios from "axios"
import { baseurl, imgUrl } from "Baseurls"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"

const API_KEY = "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"
function TestsView() {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }
  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm Allude! Ask me anything!",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ])
  const [isTyping, setIsTyping] = useState(false)

  const handleSendRequest = async message => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    }

    setMessages(prevMessages => [...prevMessages, newMessage])
    setIsTyping(true)

    try {
      const response = await processMessageToChatGPT([...messages, newMessage])
      const content = response.choices[0]?.message?.content
      if (content) {
        const chatGPTResponse = {
          message: content,
          sender: "ChatGPT",
        }
        setMessages(prevMessages => [...prevMessages, chatGPTResponse])
      }
    } catch (error) {
      console.error("Error processing message:", error)
    } finally {
      setIsTyping(false)
    }
  }

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = chatMessages.map(messageObject => {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user"
      return { role, content: messageObject.message }
    })

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        { role: "system", content: "I'm a Student using ChatGPT for learning" },
        ...apiMessages,
      ],
    }

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })

    return response.json()
  }

  // booking details
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [bookingslist, setbookingslist] = useState([])
  const [bookingslist1, setbookingslist1] = useState([])
  const [prescription, setprescription] = useState([])
  const bookingId = sessionStorage.getItem("bookingid")

  useEffect(() => {
    getBookings()
    GetProfile()
  }, [])

  const getBookings = () => {
    var token = datas
    const bodydata = {
      id: bookingId,
    }
    axios
      .post(baseurl + "outpatient/getbookingbyid", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setbookingslist(res?.data?.booking)
        setbookingslist1(res?.data?.user)
        localStorage.setItem("Userids", res?.data?.user._id)
        localStorage.setItem("UserNames", res?.data?.user.name)
        const bodydata1 = {
          userId: res?.data?.user?._id,
        }
        axios
          .post(baseurl + "prescription/getprescriptionsbyuserid", bodydata1, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setprescription(res?.data?.prescriptions)
          })
      })
  }

  const Bookingbyid = () => {
    sessionStorage.setItem("bookingid", bookingslist._id)
    history.push("/alludeai")
  }

  const Deletedep = () => {
    var token = datas
    var remid = { id: bookingslist._id }
    axios
      .post(baseurl + "prescription/sendprescription", remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getBookings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = () => {
    const confirmBox = window.confirm("Latest Perscription send to whatsapp app ?")
    if (confirmBox === true) {
      Deletedep()
    }
  }

  const Bookingbyid1 = () => {
    sessionStorage.setItem("bookingid", bookingslist._id)
    history.push("/followup")
  }

  const Agentid = () => {
    sessionStorage.setItem("userId", bookingslist1._id)
    history.push("/enquiries")
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = prescription.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(prescription.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const [viewdata, setviewdata] = useState([])
  const [viewdataSuggestion, setviewdataSuggestion] = useState([])
  const [viewdatamedicine, setviewdatamedicine] = useState([])

  const getPoppup = data => {
    setviewdata(data)
    setviewdataSuggestion(data.alludeSuggestion)
    tog_small()
  }
  const getPoppup1 = data => {
    setviewdata(data)
    setviewdatamedicine(data.medicine)
    tog_small1()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles1 = data

  const GetProfile = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctorauth/getdoctorprofile",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        // setadmininfo(res.data.doctorProfile)
        localStorage.setItem("doctorsid", res.data.doctorProfile._id)
        localStorage.setItem("doctorsName", res.data.doctorProfile.name)
      })
  }

  const VideoiD = () => {
    // history.push("/audiobooking")
    window.open("/audiobooking", "_blank")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs
            title="Allude Doctor"
            breadcrumbItem="Booking Details"
          /> */}

          <Breadcrumbs
            title="Booking"
            breadcrumbItem={
              bookingslist.status == "Accepted"
                ? "Scheduled" + " Booking" + " / " + " Booking Details"
                : bookingslist.status == "Completed"
                ? "Consulted" + " Booking" + " / " + " Booking Details"
                : bookingslist.status == "Rejected"
                ? "Cancelled" + " Booking" + " / " + " Booking Details"
                : "Pending" + " Booking" + " / " + " Booking Details"
            }
          />

          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <b className="text-primary"> User Details </b>
                    </Col>
                    <Col md="6">
                      <b
                        style={{ float: "right" }}
                        className="text-primary text-right"
                      >
                        {" "}
                        Type :
                        {bookingslist.consultationType == "Direct" ||
                        bookingslist.consultationType == "Clinic"
                          ? "Walk-In"
                          : bookingslist.consultationType}
                      </b>
                    </Col>
                  </Row>
                  <Col md={12}>
                    <div className="text-center">
                      <div className="avatar-md1 profile-user-wid mb-4 mt-1">
                        <span className="ml-5">
                          {bookingslist.consultationType == "Clinic" ? (
                            <img
                              src={logo}
                              alt=""
                              className="avatar-md1 rounded-circle img-thumbnail"
                              style={{ height: "100px" }}
                              width="100"
                            />
                          ) : (
                            <img
                              src={imgUrl + bookingslist1.avatar}
                              alt=""
                              className="avatar-md1 rounded-circle img-thumbnail"
                              style={{ height: "100px" }}
                              width="100"
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>

                  <ul className="list-unstyled mt-2">
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-user-circle text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Name</h6>
                          <p className="text-muted fs-14 mb-0">
                            {bookingslist.userName}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-phone text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Phone</h6>
                          <p className="text-muted fs-14 mb-0">
                            {bookingslist1.countryCode} {bookingslist1.phone}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-mail-send text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Email</h6>
                          <p className="text-muted fs-14 mb-0">
                            {bookingslist1.email}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-error-circle text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Gender / Age </h6>
                          <p className="text-muted fs-14 text-break mb-0">
                            {bookingslist.gender} / {bookingslist.age}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-map text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Address</h6>
                          <p className="text-muted fs-14 mb-0">
                            {bookingslist.address == "" ? (
                              <>{bookingslist1.address}</>
                            ) : (
                              <>{bookingslist.address}</>
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>

            <Col md={8}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <h5 className="text-primary"> Present Consultation :</h5>
                    </Col>
                    {Roles1?.type === "Doctor" ? (
                      <>
                        <Col md="8">
                          {bookingslist.consultationType == "Call" ? (
                            <>
                              <Button
                                disabled={bookingslist.status != "Accepted"}
                                onClick={() => {
                                  Bookingbyid()
                                }}
                                className="m-1"
                                style={{ float: "right" }}
                                size="sm"
                                color="warning"
                                title="AI Assisted Rx"
                              >
                                {" "}
                                <i className="bx bx-detail"></i> AI Assisted Rx{" "}
                              </Button>
                              {/* <a href="/audiobooking" target="_blank"> */}
                              <Button
                                disabled={bookingslist.status != "Accepted"}
                                className="m-1"
                                style={{ float: "right" }}
                                size="sm"
                                color="primary"
                                title="Video Call"
                                onClick={() => {
                                  VideoiD()
                                }}
                              >
                                {" "}
                                <i className="bx bx-video"></i> Video Call
                              </Button>
                              {/* </a> */}
                            </>
                          ) : (
                            <Button
                              disabled={bookingslist.status != "Accepted"}
                              onClick={() => {
                                Bookingbyid()
                              }}
                              className="m-1"
                              style={{ float: "right" }}
                              size="sm"
                              color="warning"
                              title="AI Assisted Rx"
                            >
                              {" "}
                              <i className="bx bx-detail"></i> AI Assisted Rx{" "}
                            </Button>
                          )}

                          {bookingslist.status == "Completed" ? (
                            <Button
                              onClick={() => {
                                Bookingbyid1()
                              }}
                              style={{ float: "right" }}
                              className="m-1"
                              size="sm"
                              color="info"
                              disabled={bookingslist.followupEnable == false}
                              title="Follow up"
                            >
                              <i className="bx bx-label"></i> Follow up
                            </Button>
                          ) : (
                            ""
                          )}
                          {sessionStorage.getItem("views") == "falses" ? (
                            <>
                              <Button
                                onClick={() => {
                                  manageDelete()
                                }}
                                className="m-1"
                                style={{ float: "right" }}
                                size="sm"
                                color="success"
                                title="Whats App"
                              >
                                {" "}
                                <i className="bx bxl-whatsapp"></i> Whats App
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row className="task-dates">
                    <Col sm="6" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">
                          <b>Booking Id :</b> {bookingslist.bookingNo}
                        </h5>
                      </div>
                    </Col>
                    <Col sm="6" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">
                          <b>Booking Date & Time :</b>{" "}
                          {bookingslist.bookingDate}, {bookingslist.time}
                        </h5>
                      </div>
                    </Col>
                    <Col sm="3" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">
                          <b> Temperature :</b> {bookingslist.temperature}
                        </h5>
                      </div>
                    </Col>
                    <Col sm="4" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">
                          <b>GRBS :</b> {bookingslist.grbs}
                        </h5>
                      </div>
                    </Col>

                    <Col sm="4" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">
                          <b>Height :</b> {bookingslist.height} (CM)
                        </h5>
                      </div>
                    </Col>

                    <Col sm="3" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">
                          <b>Weight :</b> {bookingslist.weight} (Kgs)
                        </h5>
                      </div>
                    </Col>
                    <Col sm="3" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">
                          <b>Blood pressure :</b> {bookingslist.bloodPressure}
                        </h5>
                      </div>
                    </Col>
                    <Col sm="3" xs="6">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <b>Slot :</b> {bookingslist.slot}
                        </h5>
                      </div>
                    </Col>
                    <Col sm="12" xs="12">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <b>Problem :</b> <br /> {bookingslist.problem}
                        </h5>
                      </div>
                    </Col>
                    {sessionStorage.getItem("views") == "true" ? (
                      <>
                        <Col sm="12" xs="12">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              <b>Rejected Reason :</b> <br />{" "}
                              {bookingslist.rejectedReason}
                            </h5>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>

                  {bookingslist.status == "Rejected" ? (
                    ""
                  ) : (
                    <>
                      <h5 className="text-primary mt-4">
                        Previous Consultations :
                      </h5>
                      <Row>
                        <div className="table-responsive">
                          <Table hover className="table table-bordered mt-2">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Booking No</th>
                                <th>Problem</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 5 + key + 6}</td>
                                  <td>{data.date}</td>
                                  <td>#{data.bookingNo}</td>
                                  <td>{data.problems}</td>
                                  <td>
                                    <Button
                                      className="m-1"
                                      color="success"
                                      size="sm"
                                      outline
                                      onClick={() => {
                                        getPoppup(data)
                                      }}
                                      title="View"
                                    >
                                      <i
                                        style={{ fontSize: "17px" }}
                                        className="fas fa-eye"
                                      ></i>
                                    </Button>
                                    {/* <Button
                                  className="m-1"
                                  color="warning"
                                  size="sm"
                                  outline
                                  onClick={() => { getPoppup1(data) }}
                                >
                                  <i style={{ fontSize: "17px" }} className="bx bx-joystick-alt"></i>
                                </Button> */}
                                    {/* </Link> */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                <Row className="mt-2 mb-5">
            <Col md={12}>
              {" "}
              <div
                style={{
                  position: "relative",
                  height: "540px",
                }}
              >
                <MainContainer>
                  <ChatContainer style={{background :'black'}}>
                    <MessageList className="mt-4" style={{background :'black'}}
                      scrollBehavior="smooth"
                      typingIndicator={
                        isTyping ? (
                          <TypingIndicator content="Alldude is typing" />
                        ) : null
                      }
                    >
                      {messages.map((message, i) => {
                        console.log(message)
                        return <Message key={i} model={message}  />
                      })}
                    </MessageList>
                    <MessageInput  
                      placeholder="Send a Message"
                      onSend={handleSendRequest}
                    />
                  </ChatContainer>
                </MainContainer>
              </div>
            </Col>
          </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          {/* <Row className="mb-5">
            <Col md="12">
              <Card>
                <CardBody></CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>

        <Modal
          size="xl"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Prescription Details</h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="row">
              <div style={{ borderRight: "1px solid gray" }} className="col-md-4">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <b>Name : </b>
                      <span> {bookingslist1.name}</span>
                    </div>
                    <div className="mb-3">
                      <b>Gender : </b>
                      <span>{bookingslist1.gender}</span>
                    </div>
                    <div className="mb-3">
                      <b>Booking Id : </b>
                      <span> #{viewdata.bookingNo}</span>
                    </div>
                    <div className="mb-3">
                      <b> Date : </b>
                      <span>{viewdata.date}</span>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <b>Phone No: </b>
                      <span> {bookingslist1.phone}</span>
                    </div>
                    <div className="mb-3">
                      <b>Age </b>
                      <span> {bookingslist1.age}</span>
                    </div>
                    <div className="mb-3">
                      <b>Booking Time : </b>
                      <span>{viewdata.time}</span>
                    </div>
                    <div className="mb-3">
                      <b>Prescription No : </b>
                      <span>{viewdata.prescriptionNo}</span>
                    </div>
                  </Col>
                
                  <Col md="12">
                    <div className="mb-3">
                      <b>Problems: </b>
                      <span> {viewdata.problems}</span>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <b>Brief Details: </b>
                      <span> {viewdata.briefDetails}</span>
                    </div>
                  </Col>

                </Row>

              </div>
              <div className="col-md-8 border-left border-dark">
                <div >
                  <div className="mb-3">
                    {viewdataSuggestion.map((message, index) => (
                      <>
                        <p key={index}>{message.split('\n').map((paragraph, i) => (
                          <div key={i}>
                            {paragraph}
                            <br />
                          </div>
                        ))}
                        </p>
                      </>
                    ))}
                  </div>


                </div>
              </div>
            </div> */}
            <div>
              {viewdata.prescription == undefined ||
              viewdata.prescription == "" ? (
                <h1 className="text-center">No Data..</h1>
              ) : (
                <iframe
                  style={{ width: "100%", height: "400px" }}
                  src={imgUrl + viewdata.prescription}
                />
              )}
            </div>

            <div className="mt-2" style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small(false)
                }}
                color="danger"
                style={{ width: "100px" }}
                type="button"
              >
                Close <i className="fas fa-times-circle"></i>
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Medicines List</h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Table hover className="table table-bordered">
              <thead>
                <tr className="text-center">
                  <th>S.No</th>
                  <th>Medicine name</th>
                  <th>Times</th>
                  <th>Dosage</th>
                  <th>Duration</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {viewdatamedicine.length == 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No Data...
                    </td>
                  </tr>
                ) : (
                  <>
                    {viewdatamedicine.map((data, key) => (
                      <tr key={key} className="text-center">
                        <td>{key + 1}</td>
                        <td>{data.medicineName}</td>
                        <td>{data.timesPerDay}</td>
                        <td>{data.dosage}</td>
                        <td>{data.duration}</td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>

            <div className="mt-3" style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small1(false)
                }}
                color="danger"
                type="button"
                className="m-1"
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default TestsView
