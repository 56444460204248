import React, { useState, useEffect } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    Row,
    Button,
    Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "Url"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { baseurl, imgUrl } from "Baseurls"

function AddVendors() {
    const [form, setform] = useState([])
    const [files1, setFiles1] = useState([])
    const [text1, setText1] = useState([])
    const [modal_small, setmodal_small] = useState(false)
    function tog_small() {
        setmodal_small(!modal_small)
      }

    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token

    const changeHandler1 = e => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png") {
            setFiles1(e.target.files)
        } else {
            e.target.value = null
            toast("file format not supported.Pls choose Image")
        }
    }

    const blogids = sessionStorage.getItem('blogid')
    const Get = () => {
        var token = datas
        const bodydata = {
            _id: blogids
        }
        axios
            .post(baseurl + 'blog/getBlog', bodydata,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(res => {
                setform(res.data.data)
                setText1(res.data.data.description) 
                setFiles1(res.data.data.image) 
                setIsLoading(false)
            })
    }

    useEffect(() => {
        Get()
    }, [])


    const history = useHistory()
    const handleSubmit = (e) => {
        e.preventDefault()
        var token = datas
        const dataArray = new FormData()
        dataArray.append("heading", form.heading)
        dataArray.append("description", text1)
        dataArray.append("specialisation", form.specialisation)
        for (let i = 0; i < files1.length; i++) {
            dataArray.append("image", files1[i])
        }

        axios
            .put(baseurl + 'blog/editBlog/' + blogids, dataArray, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(
                res => {
                    if (res.status === 200) {
                        toast.success(res.data.message)
                        clearForm()
                        setTimeout(() => {
                            history.push("/Blog");
                        }, 2000);

                        // history.push("/Blog",
                        //     // sessionStorage.setItem("tost", "News has been Added successfully")
                        // )
                    
                    }
                },
                error => {
                    if (error.response && error.response.status === 400) {
                        toast(error.response.data.message)
                    }
                }
            )
    }

    const handlechange = (e) => {
        const myform = { ...form }
        myform[e.target.name] = e.target.value
        setform(myform)
    }

    const clearForm = () => {
        setform({
            heading: "",
            description: "",
            specialisation: "",
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Allude Doctor" breadcrumbItem="Edit Blog" />
                    <Form
                        onSubmit={e => {
                            handleSubmit(e)
                        }}
                    >
                        <Row>
                            <Col xl="12">
                                <Button
                                    onClick={history.goBack}
                                    className="mb-3"
                                    style={{ float: "right" }}
                                    color="primary"
                                >
                                    <i className="far fa-arrow-alt-circle-left"></i>
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <Row className="mt-2">
                                    <Col lg="12">
                                        <Row>

                                            <div className="mb-3 col-md-4">
                                                <Label for="basicpill-firstname-input1">
                                                    Title <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="basicpill-firstname-input1"
                                                    placeholder="Enter Title"
                                                    required
                                                    value={form.heading}
                                                    name="heading"
                                                    onChange={e => {
                                                        handlechange(e)
                                                    }}
                                                />
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <Label>Specialization   <span className="text-danger">*</span></Label>{" "}

                                                {/* <select
                        value={form.Specialization}
                        name="Specialization"
                        onChange={e => {
                          handlechange(e)
                        }}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="Neurology">Neurology</option>
                      </select> */}
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="basicpill-firstname-input1"
                                                    placeholder="Enter Specialization"
                                                    required
                                                    value={form.specialisation}
                                                    name="specialisation"
                                                    onChange={e => {
                                                        handlechange(e)
                                                    }}
                                                />
                                            </div>

                                            <div className="mb-3 col-md-4">
                                                <Row>
                                                    <Col >
                                                        <Label for="basicpill-firstname-input1">
                                                            Image
                                                        </Label>
                                                    </Col>
                                                    <Col className="text-end" >
                                                        <i onClick={tog_small} className="bx bx-show-alt text-danger" />
                                                    </Col>
                                                </Row>
                                                <Input
                                                    type="file"
                                                    className="form-control"
                                                    id="basicpill-firstname-input1"
                                                    placeholder="Enter Title"
                                                    // required
                                                    name="image"
                                                    onChange={changeHandler1}
                                                />
                                            </div>

                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Label for="basicpill-firstname-input1">
                                            Description <span className="text-danger">*</span>
                                        </Label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="header"
                                            data={text1}
                                            onReady={editor => {
                                                console.log("Editor is ready to use!", editor)
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData()
                                                setText1(data)
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <div className="mb-4 mt-2" style={{ float: "right" }}>
                                            <button
                                                type="submit"
                                                style={{ width: "120px" }}
                                                className="btn btn-primary m-1"
                                            >
                                                Submit <i className="fas fa-check-circle"></i>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>{" "}
                    </Form>
                </Container>

                <ToastContainer />

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                    centered
                >
                    <div style={{borderBottom:"0px"}} className="modal-header">
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <img src={imgUrl + files1} style={{width:"100%", height:"300px"}} />
                    </div>
                </Modal>

            </div>
        </React.Fragment>
    )
}

export default AddVendors
