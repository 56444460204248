import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { baseurl } from "Baseurls"

const Banner = () => {
  const [banner, setbanner] = useState([])

  useEffect(() => {
    getAllbenners()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getAllbenners = () => {
    var token = datas
    let bodydata = {
      type: "Doctor"
    }
    axios
      .post(baseurl + 'faq/getAll', bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setbanner(res.data.faqs)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }


  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  // const roles = data.rolesAndPermissions
  // const roles1 = data.user.role

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="FAQ" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-5">General Questions</CardTitle>
                  {banner.map((data, key) => (
                    <div key={key} className="faq-box d-flex mb-4">
                      <div className="flex-shrink-0 me-3 faq-icon">
                        <i className="bx bx-help-circle font-size-20 text-success" />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-15">{data.question}</h5>
                        <p className="text-muted">
                          {data.answer}
                        </p>
                      </div>
                    </div>
                  ))}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
