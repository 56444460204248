import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Label,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { baseurl, imgUrl } from "Baseurls"
import { formatDate } from "@fullcalendar/react"

function Support() {
  const [modal_small, setmodal_small] = useState(false)
  const [requests, setrequests] = useState([])
  const [form1, setform1] = useState([])

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = requests.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(requests.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Files1, setFiles1] = useState("")
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }

  const AddRequest = (e) => {
    e.preventDefault()
    var token = datas
    const dataArray = new FormData()
    dataArray.append('reason', form1.reason)
    dataArray.append('description', form1.description)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }

    axios
      .post(baseurl + 'doctorRequest/adddoctorRequest', dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            GetAllRequest()
            setmodal_small(false)
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const GetAllRequest = () => {
    var token = datas
    axios
      .post(baseurl + 'doctorRequest/getAll', {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setrequests(res.data.doctorRequest)
      })
  }

  const clearForm1 = () => {
    setform1({
      reason: "",
      description: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }
  useEffect(() => {
    GetAllRequest()
  }, [])

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(baseurl + 'doctorRequest/getAll?searchQuery=' + `${e.target.value}`, {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setrequests(res.data.doctorRequest)
      })
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Make  a Request" />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Button
                        onClick={() => {
                          getpopup(data)
                        }}
                        className="mr-2 "
                        color="primary"
                        style={{
                          padding: "6px",
                          margin: "3px",

                        }}
                        title="Add Request"
                      >
                        Add Request
                      </Button>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                    </Col>
                  </Row>

                  <div>
                    <div className="table-responsive">

                      <Table className="table table-bordered mb-4 mt-2">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Ticket Id</th>
                            <th>Date / Time</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Description</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 10 + key + 11}</th>
                              <td>{data.ticketId}</td>
                              <td>
                                {data?.logCreatedDate?.slice(0, 10)} / {data?.logCreatedDate?.slice(11, 16)}
                              </td>
                              <td>{data.reason}</td>
                              <td>
                                <img src={imgUrl + data.image} style={{width:"70px", height:"70px"}} />
                              </td>
                              <td> {data.description}</td>
                              <td> {data.status}</td>

                              {/* <td>
                                {data.status == "completed" ? (
                                  ""
                                ) : (
                                  <Button
                                    onClick={() => {
                                      getpopup(data)
                                    }}
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="success"
                                    outline
                                  >
                                    <i className="bx bx-edit "></i>
                                  </Button>
                                )}

                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Request
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                AddRequest(e)
              }}
            >
            {/* <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Title <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="reason"
                    value={form1.reason}
                    onChange={(e) => { handleChange1(e) }}
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Title"
                    required
                  />
                </div>
              </Col>*/}
            <Col md={12}>
            <div className="mb-3">
  <Label for="basicpill-title-select">
    Title <span className="text-danger">*</span>
  </Label>
  <Input
    type="select"
    name="reason"
    value={form1.reason}
    onChange={(e) => { handleChange1(e) }}
    className="form-control"
    id="basicpill-title-select"
    required
  >
    <option value="">Select </option>
    <option value="Payment Issue">Payment Issue</option>
    <option value="Account Issues">Account Issues</option>
    <option value="Billing and Payments">Billing and Payments</option>
    <option value="Technical Support">Technical Support</option>
    <option value="Subcription or Service Information">Subcription or Service Information</option>
    <option value="Complaints and Feedback">Complaints and Feedback</option>
    <option value="Cancellation and Returns">Cancellation and Returns</option>
    <option value="General Inquiries">General Inquiries</option>
    <option value="Security Concerns">Security Concerns</option>
  </Input>
</div>
            </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    File
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler1}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    value={form1.description}
                    onChange={(e) => { handleChange1(e) }}

                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    required
                  />
                </div>
              </Col>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
