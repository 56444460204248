import React, { useState } from "react";
import ZegoUIKitPrebuilt from "@zegocloud/zego-uikit-prebuilt";

function Callingfun() {
  const [pipViewVisible, setPipViewVisible] = useState(true);

  const togglePipView = (visible) => {
    setPipViewVisible(visible);
  };

  return (
    <div>
      <div>
        <ZegoUIKitPrebuilt pipViewVisible={pipViewVisible} />
        <button onClick={() => togglePipView(false)}>Hide pip view</button>
        <button onClick={() => togglePipView(true)}>Show pip view</button>
      </div>
    </div>
  );
}

export default Callingfun;
