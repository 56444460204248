// import React, { useEffect, useState } from "react"
// import PropTypes from "prop-types"
// import { Link } from "react-router-dom"
// import { isEmpty, map } from "lodash"
// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   TabContent,
//   TabPane,
// } from "reactstrap"
// import classnames from "classnames"

// //Import Scrollbar
// import PerfectScrollbar from "react-perfect-scrollbar"
// import "react-perfect-scrollbar/dist/css/styles.css"

// //Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb"
// import images from "assets/images"
// import {
//   addMessage as onAddMessage,
//   getChats as onGetChats,
//   getContacts as onGetContacts,
//   getGroups as onGetGroups,
//   getMessages as onGetMessages,
// } from "store/actions"
// import axios from "axios"

// //redux
// import { useSelector, useDispatch } from "react-redux"

// const Chat = () => {
//   //meta title

//   const dispatch = useDispatch()

//   const { chats, groups, contacts, messages } = useSelector(state => ({
//     chats: state.chat.chats,
//     groups: state.chat.groups,
//     contacts: state.chat.contacts,
//     messages: state.chat.messages,
//   }))

//   const [messageBox, setMessageBox] = useState(null)
//   // const Chat_Box_Username2 = "Henry Wells"
//   const [currentRoomId, setCurrentRoomId] = useState(1)
//   // eslint-disable-next-line no-unused-vars
//   const [currentUser, setCurrentUser] = useState({
//     name: "Henry Wells",
//     isActive: true,
//   })

//   const [activeTab, setactiveTab] = useState("1")
//   const [Chat_Box_Username, setChat_Box_Username] = useState("Steven Franklin")
//   // eslint-disable-next-line no-unused-vars
//   const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("online")
//   const [curMessage, setcurMessage] = useState("")

//   useEffect(() => {
//     dispatch(onGetChats())
//     dispatch(onGetGroups())
//     dispatch(onGetContacts())
//     dispatch(onGetMessages(currentRoomId))
//   }, [onGetChats, onGetGroups, onGetContacts, onGetMessages, currentRoomId])

//   useEffect(() => {
//     if (!isEmpty(messages)) scrollToBottom()
//   }, [messages])

//   const toggleTab = tab => {
//     if (activeTab !== tab) {
//       setactiveTab(tab)
//     }
//   }

//   //Use For Chat Box
//   const userChatOpen = (id, name, status, roomId) => {
//     setChat_Box_Username(name)
//     setCurrentRoomId(roomId)
//     dispatch(onGetMessages(roomId))
//   }

//   const addMessage = (roomId, sender) => {
//     const message = {
//       id: Math.floor(Math.random() * 100),
//       roomId,
//       sender,
//       message: curMessage,
//       createdAt: new Date(),
//     }
//     setcurMessage("")
//     dispatch(onAddMessage(message))
//   }

//   const scrollToBottom = () => {
//     if (messageBox) {
//       messageBox.scrollTop = messageBox.scrollHeight + 1000
//     }
//   }

//   const [messagess, setMessages] = useState([]) // Store a list of messages
//   const [inputText, setInputText] = useState("")
//   const apiKey = "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"
//   const endpoint = "https://api.openai.com/v1/chat/completions"

//   const sendMessage = async () => {
//     const userMessage = { role: "user", content: inputText }

//     // Add the user's message to the conversation
//     setMessages([...messagess, userMessage])

//     // Clear the input field
//     setInputText("")

//     // Make the API request
//     try {
//       const response = await fetch(endpoint, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${apiKey}`,
//         },
//         body: JSON.stringify({
//           model: "gpt-3.5-turbo",
//           messages: [...messagess, userMessage], // Include the entire conversation
//         }),
//       })

//       if (!response.ok) {
//         throw new Error("API request failed")
//       }

//       const data = await response.json()
//       const assistantMessage = {
//         role: "assistant",
//         content: data.choices[0].message.content,
//       }

//       // Add the AI's response to the conversation
//       setMessages([...messagess, assistantMessage])
//     } catch (error) {
//       console.error("Error:", error)
//     }
//   }
//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Breadcrumbs title="Allude Doctor" breadcrumbItem="Prescriptions" />

//           <Row>
//             <Col lg="12">
//               <div className="d-lg-flex">
//                 <div className="chat-leftsidebar me-lg-4">
//                   <Card>
//                     <CardBody className="mb-4">
//                       <div>
//                         <div className="chat-leftsidebar-nav mt-3">
//                           <Nav pills justified>
//                             <NavItem>
//                               <NavLink
//                                 className={classnames({
//                                   active: activeTab === "1",
//                                 })}
//                                 onClick={() => {
//                                   toggleTab("1")
//                                 }}
//                               >
//                                 <i className="bx bx-chat font-size-20 d-sm-none" />
//                                 <span className="d-none d-sm-block">
//                                   Allude Doctor Prescriptions
//                                 </span>
//                               </NavLink>
//                             </NavItem>
//                           </Nav>

//                           <TabContent activeTab={activeTab} className="py-4">
//                             <TabPane tabId="1">
//                               <div>
//                                 <h5 className="font-size-14 mb-3">Recent</h5>
//                                 <ul
//                                   className="list-unstyled chat-list"
//                                   id="recent-list"
//                                 >
//                                   <PerfectScrollbar style={{ height: "400px" }}>
//                                     {map(chats, chat => (
//                                       <li
//                                         key={chat.id + chat.status}
//                                         className={
//                                           currentRoomId === chat.roomId
//                                             ? "active"
//                                             : ""
//                                         }
//                                       >
//                                         <Link
//                                           to="#"
//                                           onClick={() => {
//                                             userChatOpen(
//                                               chat.id,
//                                               chat.name,
//                                               chat.status,
//                                               chat.roomId
//                                             )
//                                           }}
//                                         >
//                                           <div className="d-flex">
//                                             <div className="align-self-center me-3">
//                                               <i
//                                                 className={
//                                                   chat.status === "online"
//                                                     ? "mdi mdi-circle text-success font-size-10"
//                                                     : chat.status ===
//                                                       "intermediate"
//                                                     ? "mdi mdi-circle text-warning font-size-10"
//                                                     : "mdi mdi-circle font-size-10"
//                                                 }
//                                               />
//                                             </div>
//                                             <div className="align-self-center me-3">
//                                               <img
//                                                 src={images[chat.image]}
//                                                 className="rounded-circle avatar-xs"
//                                                 alt=""
//                                               />
//                                             </div>

//                                             <div className="flex-grow-1 overflow-hidden">
//                                               <h5 className="text-truncate font-size-14 mb-1">
//                                                 {chat.name}
//                                               </h5>
//                                               <p className="text-truncate mb-0">
//                                                 {chat.description}
//                                               </p>
//                                             </div>
//                                             <div className="font-size-11">
//                                               {chat.time}
//                                             </div>
//                                           </div>
//                                         </Link>
//                                       </li>
//                                     ))}
//                                   </PerfectScrollbar>
//                                 </ul>
//                               </div>
//                             </TabPane>
//                           </TabContent>
//                         </div>
//                       </div>
//                     </CardBody>
//                   </Card>
//                 </div>

//                 <div className="w-100 user-chat">
//                   <Card>
//                     <div className="p-4 border-bottom ">
//                       <Row>
//                         <Col md="4" xs="9">
//                           <h5 className="font-size-15 mb-1">
//                             {Chat_Box_Username}
//                           </h5>

//                           <p className="text-muted mb-0">
//                             <i
//                               className={
//                                 Chat_Box_User_Status === "online"
//                                   ? "mdi mdi-circle text-success align-middle me-1"
//                                   : Chat_Box_User_Status === "intermediate"
//                                   ? "mdi mdi-circle text-warning align-middle me-1"
//                                   : "mdi mdi-circle align-middle me-1"
//                               }
//                             />
//                             {Chat_Box_User_Status}
//                           </p>
//                         </Col>
//                         <Col md="8" xs="3"></Col>
//                       </Row>
//                     </div>

//                     <div>
//                       <div
//                         className="chat-conversation p-3"
//                         style={{ background: "black", color: "white" }}
//                       >
//                         <ul className="list-unstyled">
//                           <PerfectScrollbar
//                             style={{ height: "400px" }}
//                             containerRef={ref => setMessageBox(ref)}
//                           >
//                             {messagess.map((message, index) => (
//                               <div
//                                 key={index}
//                                 className={`message ${message.role}`}
//                               >
//                                 {message.content}
//                               </div>
//                             ))}
//                           </PerfectScrollbar>
//                         </ul>
//                       </div>
//                       <div className="p-3 chat-input-section">
//                         <Row>
//                           <Col>
//                             <div className="position-relative">
//                               <input
//                                 type="text"
//                                 placeholder="Type a message..."
//                                 value={inputText}
//                                 className="form-control chat-input"
//                                 onChange={e => setInputText(e.target.value)}
//                               />
//                             </div>
//                           </Col>
//                           <Col className="col-auto">
//                             <Button
//                               color="primary"
//                               onClick={sendMessage}
//                               className="btn btn-primary btn-rounded chat-send w-md "
//                             >
//                               <span className="d-none d-sm-inline-block me-2">
//                                 Send
//                               </span>{" "}
//                               <i className="mdi mdi-send" />
//                             </Button>
//                           </Col>
//                         </Row>
//                       </div>
//                     </div>
//                   </Card>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   )
// }

// Chat.propTypes = {
//   chats: PropTypes.array,
//   groups: PropTypes.array,
//   contacts: PropTypes.array,
//   messages: PropTypes.array,
//   onGetChats: PropTypes.func,
//   onGetGroups: PropTypes.func,
//   onGetContacts: PropTypes.func,
//   onGetMessages: PropTypes.func,
//   onAddMessage: PropTypes.func,
// }

// export default Chat

import React, { useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react"

const API_KEY = "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"

function Prescriptions() {
  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm Allude! Ask me anything!",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ])
  const [isTyping, setIsTyping] = useState(false)

  const handleSendRequest = async message => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    }

    setMessages(prevMessages => [...prevMessages, newMessage])
    setIsTyping(true)

    try {
      const response = await processMessageToChatGPT([...messages, newMessage])
      const content = response.choices[0]?.message?.content
      if (content) {
        const chatGPTResponse = {
          message: content,
          sender: "ChatGPT",
        }
        setMessages(prevMessages => [...prevMessages, chatGPTResponse])
      }
    } catch (error) {
      console.error("Error processing message:", error)
    } finally {
      setIsTyping(false)
    }
  }

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = chatMessages.map(messageObject => {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user"
      return { role, content: messageObject.message }
    })

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        { role: "system", content: "I'm a Student using ChatGPT for learning" },
        ...apiMessages,
      ],
    }

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })

    return response.json()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Prescriptions" />
          <Row className="mt-2 mb-5">
            <Col md={12}>
              {" "}
              <div
                style={{
                  position: "relative",
                  height: "540px",
                  // width: "700px",
                }}
              >
                <MainContainer>
                  <ChatContainer style={{background :'black'}}>
                    <MessageList className="mt-4" style={{background :'black'}}
                      scrollBehavior="smooth"
                      typingIndicator={
                        isTyping ? (
                          <TypingIndicator content="Alldude is typing" />
                        ) : null
                      }
                    >
                      {messages.map((message, i) => {
                        console.log(message)
                        return <Message key={i} model={message}  />
                      })}
                    </MessageList>
                    <MessageInput  
                      placeholder="Send a Message"
                      onSend={handleSendRequest}
                    />
                  </ChatContainer>
                </MainContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Prescriptions
