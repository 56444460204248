import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import Select from "react-select"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    setSelectedOptions(details)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  //Adddelear api integration

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("company_name", form.company_name)
    dataArray.append("company_mobile_number", form.company_mobile_number)
    dataArray.append("trading_number", form.trading_number)
    dataArray.append("vat", form.vat)
    dataArray.append("address", form.address)
    dataArray.append("supportEmail", form.supportEmail)
    dataArray.append("bookingEmail", form.bookingEmail)
    dataArray.append("name", form.name)
    dataArray.append("email", form.email)
    dataArray.append("password", form.password)
    dataArray.append("phone", form.phone)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("trading_image", selectedFiles[i])
    }

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("logo", selectedFiles1[i])
    }

    axios
      .post("", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Vendorslist")
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      company_name: "",
      company_mobile_number: "",
      trading_number: "",
      trading_image: "",
      vat: "",
      address: "",
      supportEmail: "",
      bookingEmail: "",
      cityId: "",
      name: "",
      email: "",
      password: "",
      phone: "",
      logo: "",
    })
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes1(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes1(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [cities, setcities] = useState([])
  // useEffect(() => {
  //   getallCities()
  // }, [])

  const getallCities = () => {
    var token = datas
    axios
      .get(
        "http://103.186.185.77:5021/api/v1/superadmin/city/allactivecities",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcities(res.data.data)
      })
  }
  const [inputList, setInputList] = useState([{ info: "", advantage: "" }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { info: "", advantage: "" }])
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Allude timeslot"
            breadcrumbItem="Add Doctor Consultation Making"
          />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <h5
                    className="mb-4"
                    style={{ color: "#0079FF", fontWeight: "bold" }}
                  >
                    General Information
                  </h5>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Doctor Name <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value="">Select</option>
                        <option value="">Charles Brown /Neurologist </option>
                      </select>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Employee Name / Employee Id<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Employee Id"
                        required
                        value={form.t2}
                        name="t2"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col lg="12">
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Tariff / Cost<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Tariff / Cost"
                            required
                            value={form.email}
                            name="email"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>


                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Date
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Conifirm Password"
                            required
                            value={form.password}
                            name="password"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>


                      <Col md={9}>
                        <div>
                          <Row>
                            {inputList.map((x, i) => {
                              return (
                                <>
                                  <Row>
                                    <div key={i} className="box row">
                                      <Col md="5" sm="12" className="mb-1">
                                        <Label>From Time</Label>
                                        <Input
                                          type="time"
                                          name="info"
                                          placeholder="Enter From Time"
                                          value={x.info}
                                          onChange={e =>
                                            handleInputChange(e, i)
                                          }
                                        />
                                      </Col>

                                      <Col md="5" sm="12" className="mb-1">
                                        <Label>To Time</Label>
                                        <Input
                                          type="time"
                                          name="advantage"
                                          placeholder="Enter To Time"
                                          value={x.advantage}
                                          onChange={e =>
                                            handleInputChange(e, i)
                                          }
                                        />
                                      </Col>

                                      <Col sm="2">
                                        <Label className="mt-1"></Label>
                                        <div className="btn-box">
                                          {inputList.length !== 1 && (
                                            <button
                                              className="mr10 btn btn-outline-danger btn-sm m-1 mt-3"
                                              type="button"
                                              onClick={() =>
                                                handleRemoveClick(i)
                                              }
                                            >
                                              Remove{" "}
                                              <i className="bx bx-x-circle"></i>
                                            </button>
                                          )}
                                          {inputList.length - 1 === i && (
                                            <button
                                              className="btn btn-sm btn-outline-info m-1 mt-3"
                                              onClick={handleAddClick}
                                            >
                                              Add{" "}
                                              <i className="bx bx-plus-circle"></i>
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                    </div>
                                  </Row>
                                </>
                              )
                            })}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={12}>
                    <div className=" mb-2" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-info m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
