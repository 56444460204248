import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty, map } from "lodash"
import { Button, Card, Col, Container, Row } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { baseurl, imgUrl } from "Baseurls"

const Chat = () => {
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [messages, setmessages] = useState([])
  const [userdata, setuserdata] = useState([])
  const [messageBox, setMessageBox] = useState(null)
  useEffect(() => {
    getchatlistview()
    GetProfile()
  }, [])

  const bookingId = sessionStorage.getItem("bookingid")

  const getchatlistview = () => {
    var token = datas
    const bodydata = {
      bookingId: bookingId,
    }
    axios
      .post(baseurl + "chat/getAllpanelMessages", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setuserdata(res.data.userData)
        setmessages(res.data.messages)
        setmessages(list => [...list, res.data.messages])
      })
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom()
  }, [messages])

  const [form, setform] = useState([])
  const GetProfile = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctorauth/getdoctorprofile",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.doctorProfile)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude" breadcrumbItem="Chat" />
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="w-100 user-chat">
                  <Card>
                    <div className="pt-3 border-bottom ">
                      <Row>
                        <Col md="6">
                          <div className="d-flex">
                            <div className="align-self-center me-3"></div>
                            <div className="align-self-center me-3">
                              <img
                                src={imgUrl + userdata.avatar}
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>

                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="text-truncate font-size-14 mb-1 mt-1">
                                {userdata.name}
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col style={{ paddingRight: "45px" }} md="6">
                          <Button
                            size="sm"
                            onClick={history.goBack}
                            className="mr-3 mb-2"
                            style={{ float: "right" }}
                            color="primary"
                            outline
                          >
                            <i className="far fa-arrow-alt-circle-left"></i>
                            Back
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-3">
                        <ul className="list-unstyled">
                          <PerfectScrollbar
                            style={{ height: "350px" }}
                            containerRef={ref => setMessageBox(ref)}
                          >
                            {messages &&
                              map(messages, message => (
                                <>
                                  {message.message == null  ? (
                                    <></>
                                  ) : (
                                    <>
                                      <li
                                        key={"test_k" + message.id}
                                        className={
                                          message.fromSelf === false
                                            ? ""
                                            : "right"
                                        }
                                      >
                                        <div className="conversation-list">
                                          <div className="ctext-wrap">
                                            <div className="conversation-name">
                                              {message.sender}
                                            </div>
                                            <div>
                                              {message.fromSelf === false ? (
                                                <>
                                                  <h6 className="text-truncate font-size-13 mb-3 text-primary">
                                                    {userdata.name}
                                                  </h6>
                                                </>
                                              ) : (
                                                <>
                                                  <h6 className="text-truncate font-size-13 mb-3 text-danger">
                                                    {form.name}
                                                  </h6>
                                                </>
                                              )}
                                            </div>
                                            {message.messageType == "Image" ||
                                            message.messageType == "Pdf" ? (
                                              <a
                                                href={
                                                  imgUrl + message.attachment
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {message.messageType ==
                                                "Image" ? (
                                                  <>
                                                    <img
                                                      style={{
                                                        width: "250px",
                                                        height: "200px",
                                                      }}
                                                      src={
                                                        imgUrl +
                                                        message.attachment
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <h3
                                                      style={{ color: "red" }}
                                                    >
                                                      <i
                                                        className="bx bxs-file-pdf"
                                                        style={{
                                                          fontSize: "40px",
                                                        }}
                                                      ></i>{" "}
                                                    </h3>
                                                  </>
                                                )}
                                              </a>
                                            ) : (
                                              <>
                                                <p>{message.message}</p>
                                                <p className="chat-time mb-0">
                                                  <i className="bx bx-time-five align-middle me-1" />
                                                  {message.date}, {message.time}
                                                </p>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  )}
                                </>
                              ))}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

export default Chat
