import React, { useRef } from "react"
import SignatureCanvas from "react-signature-canvas"
import { CardBody, Row, Card, Col, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const SignaturePad = () => {
  const signatureRef = useRef(null)

  const clearSignature = () => {
    signatureRef.current.clear()
  }

  const saveSignature = () => {
    const signatureDataURL = signatureRef.current.toDataURL()
    console.log(signatureDataURL)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Signature Pad" />
          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <CardBody>
                  <h4>Signature Pad</h4>
                  <div>
                    <SignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{ width: 800, height: 400 }}
                    />
                  </div>
                  <div>
                    <Button
                      color="danger"
                      className="m-1"
                      onClick={clearSignature}
                    >
                      Clear
                    </Button>
                    <Button color="primary" onClick={saveSignature}>
                      Save
                    </Button>
                  </div>
                </CardBody>{" "}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SignaturePad
