import React, { useState, useEffect } from "react";
import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";

function App() {
  const [zp, setZP] = useState(null); // State to hold Zego UI Kit instance

  useEffect(() => {
    const userID = "66112fbe57def33bbb23effe";
    const userName = "sateesh";
    const appID = 1526271279;
    const serverSecret = "d8453aecf522ccaad8aabfe854cb94a9";
    const roomID = "testRoom"; // Assign a valid room ID

    const TOKEN = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      userID,
      userName
    );

    const zpInstance = ZegoUIKitPrebuilt.create(TOKEN);
    setZP(zpInstance);

    zpInstance.addPlugins({ ZIM });

    zpInstance.joinRoom({
      container: "#video-container",
      sharedLinks: [],
      scenario: {
        mode: ZegoUIKitPrebuilt.VideoConference,
      },
      config: {
        role: "Host",
        wsServer: "wss://webliveroom1526271279-api.coolzcloud.com/ws",
        turnOnMicrophoneWhenJoining: true,
        turnOnCameraWhenJoining: true,
        showMyCameraToggleButton: true,
        showMyMicrophoneToggleButton: true,
        showAudioVideoSettingsButton: true,
        showScreenSharingButton: true,
        showTextChat: true,
        showUserList: true,
        maxUsers: 2,
        layout: "Auto",
        showLayoutButton: false,
      },
    });

    return () => {
      if (zpInstance) {
        zpInstance.destroy();
      }
    };
  }, []);

  const invite = () => {
    if (!zp) return;

    const targetUser = {
      userID: "661130b957def33bbb23f558",
      userName: "vijaya",
    };

    zp.sendCallInvitation({
      callees: [targetUser],
      callType: ZegoUIKitPrebuilt.InvitationTypeVideoCall,
      timeout: 60,
    })
      .then((res) => {
        console.warn(res);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <div>
      <button onClick={invite}>Invite</button>
      {/* Container for Zego video */}
      <div id="video-container" style={{ width: "100%", height: "100vh" }}></div>
    </div>
  );
}

export default App;
