import React, { useState, useEffect } from "react"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  Label,
} from "reactstrap"
//Import Pricing Cards
import CardPricing from "./CardPricing"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { imgUrl, baseurl } from "Baseurls"
import success from "../../assets/images/brands/success.gif"
import useRazorpay from "react-razorpay"
import logo from "../../assets/images/logos.png"

const PagesPricing = () => {
  //meta title
  const [Razorpay] = useRazorpay()
  var gets = localStorage.getItem("authUser")
  const expdate = new Date(sessionStorage.getItem("Expirydate"))
  const expdate1 = new Date()
  console.log(expdate)
  console.log(expdate1)
  var data = JSON.parse(gets)
  var datas = data.token
  const [modal_small2, setmodal_small2] = useState(false)
  const [subscriptions, setsubscriptions] = useState([])
  const [subscriptions1, setsubscriptions1] = useState([])
  const [subscriptionsexpiry, setsubscriptionsexpiry] = useState([])
  const [subtax, setsubtax] = useState("")

  const pricings = [
    {
      id: 1,
      title: "Weekly",
      description: "0% / OFF",
      icon: "bx bx-check-circle",
      price: "500",
      duration: "Per month",
      link: "",
      features: [
        { title: "Code Generation 2 (Per day)" },
        { title: "Access Chat Bot Free" },
        { title: "Text Character Length 1000" },
        { title: "Max Token Limit 2000" },
      ],
    },
    {
      id: 2,
      title: "Monthly",
      description: "10% / OFF",
      icon: "bx bx-check-circle",
      price: "1000",
      duration: "Per month",
      link: "",
      features: [
        { title: "Code Generation 2 (Per day)" },
        { title: "Access Chat Bot Free" },
        { title: "Text Character Length 1000" },
        { title: "Max Token Limit 2000" },
      ],
    },
    {
      id: 3,
      title: "Quarterly",
      description: "15% / OFF",
      icon: "bx bx-check-circle",
      price: "1500",
      duration: "Per month",
      link: "",
      features: [
        { title: "Code Generation 2 (Per day)" },
        { title: "Access Chat Bot Free" },
        { title: "Text Character Length 1000" },
        { title: "Max Token Limit 2000" },
      ],
    },
    {
      id: 4,
      title: "Yearly",
      description: "20% / OFF",
      icon: "bx bx-check-circle",
      price: "2000",
      duration: "Per month",
      link: "",
      features: [
        { title: "Code Generation 2 (Per day)" },
        { title: "Access Chat Bot Free" },
        { title: "Text Character Length 1000" },
        { title: "Max Token Limit 2000" },
      ],
    },
  ]
  const currectpricing = {
    id: 1,
    title: "Weekly",
    description: "0% / OFF",
    icon: "bx bx-check-circle",
    price: "500",
    duration: "Per month",
    link: "",
    features: [
      { title: "Code Generation 2 (Per day)" },
      { title: "Access Chat Bot Free" },
      { title: "Text Character Length 1000" },
      { title: "Max Token Limit 2000" },
    ],
  }

  useEffect(() => {
    GetAllPlans()
    GetGoingplan()
    GetAllPlansexpiry()
  }, [])

  const GetAllPlans = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscribe/getalldoctorplans",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubscriptions(res.data.subscriptions)
        setsubtax(res.data.tax)
      })
  }
  const GetAllPlansexpiry = () => {
    var token = datas
    axios
      .post(
        baseurl + "upgradedstatus",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubscriptionsexpiry(res.data)
      })
  }

  const GetGoingplan = () => {
    var token = datas
    axios
      .post(
        baseurl + "subscribe/getactivesubscribes",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setsubscriptions1(res.data.subscribeResult)
      })
  }

  const Bookssub = data => {
    console.log(data)
    const totalvalue =
      parseFloat(data.price) -
      parseFloat(data.price) * (parseFloat(data.discount) / 100)
    const totalvalue1 = totalvalue + parseFloat(subtax)
    var token = datas
    const options = {
      // key: "rzp_live_QtIdhYMkW8VDdw",
      key: "rzp_test_sU7saBgXAhWSrx",
      amount: totalvalue1 * 100,
      currency: "INR",
      name: "VRAH Telehealth",
      description: "VRAH Telehealth Transaction",
      image: logo,

      handler: function (response) {
        // const dataArray = new FormData();
        // dataArray.append(
        //   "transactionId",response.razorpay_payment_id
        // );
        // dataArray.append("bookingId", res.data.data._id);
        // dataArray.append("paytype", form.paymentStatus);
        // if (
        //   response.razorpay_payment_id ===
        //   response.razorpay_payment_id
        // ) {
        //   dataArray.append("status", "success");
        // } else {
        //   dataArray.append("status", "failure");
        // }

        // console.log("dataArray", dataArray);
        const bodydata = {
          planId: data._id,
          tax: subtax,
          discount: data.discount,
          couponCode: "",
          couponAmount: "",
          subAmount: data.price,
          total: totalvalue1,
          transactionId: response.razorpay_payment_id,
        }

        axios
          .post(baseurl + "subscribe/addsubscribe", bodydata, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            res => {
              if (res.status === 200) {
                setmodal_small2(true)
                GetAllPlans()
                GetGoingplan()
              }
            },
            error => {
              if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message)
              }
            }
          )
      },
      prefill: {
        name: "Nikhil",
        email: "nikhil@gmail.com",
        contact: 65985564556,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#015A65",
      },
    }
    const rzp1 = new Razorpay(options)
    rzp1.open()

    // axios
    //   .post(baseurl + 'subscribe/addsubscribe', bodydata, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   })
    //   .then(
    //     res => {
    //       if (res.status === 200) {
    //         setmodal_small2(true)
    //         GetAllPlans()
    //         GetGoingplan()
    //       }
    //     },
    //     error => {
    //       if (error.response && error.response.status === 400) {
    //         toast.error(error.response.data.message)
    //       }
    //     }
    //   )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Plans" />
          <Row className="justify-content-center">
            <h4>Current Plan</h4>
            {subscriptions1.map((data, key) => (
              <Col key={key} xl="4" md="6">
                <div className=" mb-4 mt-4">
                  <Card className="plan-box mt-3">
                    <CardBody className="p-4">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Row>
                            <h4
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              {data.planName}
                            </h4>
                          </Row>
                          {/* <p className="text-muted">{data.discount} %</p> */}
                        </div>
                      </div>
                      <div className="py-2">
                        <Row>
                          <Col md="6">
                            <h5 className="text-end mt-2">
                              <del>{data.price}</del>
                            </h5>
                          </Col>
                          <Col md="6">
                            <h2>
                              {data.total} /{" "}
                              <span className="font-size-13">
                                {data.duration}
                              </span>
                            </h2>
                          </Col>
                        </Row>

                        {/* <h2>
                          {data.price}/{" "}
                          <span className="font-size-13">{data.duration}</span>
                        </h2> */}
                      </div>
                      <div>
                        <Label>Start Date: {data.startDate}</Label>
                        <br />
                        <Label>Expiry Date: {data.endDate}</Label>
                      </div>

                      <div className="text-center mt-4">
                        <a target="blank" href={imgUrl + data.invoice}>
                          <Button
                            className="w-50"
                            outline
                            size="sm"
                            color="warning"
                            title="View Invoice"
                          >
                            View Invoice
                          </Button>
                        </a>
                      </div>
                      {/* <div className="text-center">
                        <Link className="btn btn-primary btn-sm w-50">Buy Now</Link>
                      </div> */}

                      <div className="plan-features mt-3">
                        {data?.advantages?.map((data, key) => (
                          <p key={"_feature_" + key}>
                            <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                            {data.advantage}
                          </p>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>

          <Row>
            <h4>Choose your plans</h4>
            {subscriptions.map((data, key) => (
              <Col key={key} xl="4" md="6">
                <div className=" mb-4 mt-4">
                  <Card className="plan-box mt-3">
                    <CardBody className="p-4">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Row>
                            <Col md="10">
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                {data.planName}
                              </label>
                            </Col>
                            <Col md="2">
                              <p className="text-muted">{data.discount} %</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="py-2">
                        <Row>
                          <Col md="6">
                            <h5 className="text-end mt-2">
                              <del>{data.price}</del>
                            </h5>
                          </Col>
                          <Col md="6">
                            <h2>
                              {parseFloat(data.price) -
                                parseFloat(data.price) *
                                  (parseFloat(data.discount) / 100)}{" "}
                              /{" "}
                              <span className="font-size-13">
                                {data.duration}
                              </span>
                            </h2>
                          </Col>
                        </Row>
                      </div>
                      <div className="text-center">
                        <button
                          // disabled={subscriptionsex.upgrade == false}
                          onClick={() => {
                            Bookssub(data)
                          }}
                          className="btn btn-primary btn-sm w-50"
                          title="Buy Now"
                        >
                          Buy Now
                        </button>
                      </div>

                      <div className="plan-features mt-5">
                        {data?.advantages?.map((data, key) => (
                          <p key={"_feature_" + key}>
                            <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                            {data.advantage}
                          </p>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>

          <Modal
            isOpen={modal_small2}
            toggle={() => {
              tog_small2()
            }}
          >
            <div className="modal-body">
              <div className="text-center">
                <img src={success} style={{ width: "400px" }} />
                <p className="text-dark p-1">
                  Congratulations! Your subscription has been successfully
                  completed.Thank you for choosing our services.
                </p>
                {/* <p className="text-secondary">
                  Your Invoice{" "}
                  <a
                    type="button"
                    // onClick={() => {
                    //   downloadImage()
                    // }}
                    className="text-primary"
                  >
                    Click hear
                  </a>
                </p> */}

                {/* <Link to="/orders"> */}
                <Button
                  className="mb-3 mt-3"
                  onClick={() => {
                    setmodal_small2(false)
                  }}
                  color="danger"
                  outline
                  style={{ width: "150px" }}
                >
                  {" "}
                  <i className="bx bx-x-circle"> </i> Close
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PagesPricing
