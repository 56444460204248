import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Table, Button, Row, Col, Input } from "reactstrap"
import {
  Card,
  CardBody,
  Container,
  Modal,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"
import avatar1 from "../../assets/images/users/avatar-2.jpg"
import axios from "axios"
import { baseurl, imgUrl } from "Baseurls"
import { ToastContainer, toast } from "react-toastify"
import Flatpickr from "react-flatpickr"
import { format } from "date-fns"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"

function Slotlist() {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    setDatastate([])
  }
  const [modal_small1, setmodal_small1] = useState(false)
  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [datastate, setDatastate] = useState([])

  // let dates = [];

  // const handleDateChange = (NewDate) => {
  //     console.log(NewDate[0]);

  //     const date1 = format(new Date(NewDate.map((data) => (data))), "yyyy-MM-dd");
  //     // const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
  //     dates.push(date1);
  //     // dates.push(date2);
  //     setDatastate(dates);
  // };

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [admininfo, setadmininfo] = useState([])
  const [holidaydata, setholidaydata] = useState([])
  const [viewholidaydata, setviewholidaydata] = useState([])

  const [slots, setslots] = useState([])
  const [slots1, setslots1] = useState([])
  const [slots2, setslots2] = useState([])
  const [slots3, setslots3] = useState([])
  const [slots4, setslots4] = useState([])
  const [slots5, setslots5] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myData = { ...form }
    myData[e.target.name] = e.target.checked
    setform(myData)
    var token = datas
    const bodydata = {
      spotConsultation: e.target.checked,
    }
    axios
      .put(baseurl + "update", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          toast.success(res.data.message)
          GetProfile()
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }
  const handleChange1 = e => {
    let myData = { ...form1 }
    myData[e.target.name] = e.target.checked
    setform1(myData)
  }

  const GetProfile = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctorauth/getdoctorprofile",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setadmininfo(res.data.doctorProfile)
        setform(res.data.doctorProfile)
      })
  }

  const Getholidays = () => {
    var token = datas
    axios
      .post(
        baseurl + "holidays/getalldoctorholidays",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setholidaydata(res.data.holidays)
        setviewholidaydata(res.data.holidaysArr)
      })
  }

  const events = viewholidaydata.map(date => ({
    title: "Holiday",
    date: date,
  }))

  // Time slots
  const [activeTab1, setactiveTab1] = useState("1")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const handleDateChange = selectedDates => {
    const formattedDates = selectedDates.map(date =>
      format(new Date(date), "yyyy-MM-dd")
    )
    setDatastate(formattedDates)
  }

  const redirectbooking = eventClickInfo => {
    const token = datas
    const clickedDate = eventClickInfo.event.start
    const clickdate = format(new Date(clickedDate), "yyyy-MM-dd")
    const bodydata = {
      date: clickdate,
    }
    axios
      .post(baseurl + "holidays/deleteholidays", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          toast.success(res.data.message)
          Getholidays()
          setmodal_small1(false)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const getAllSlots = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctorslots/getalldoctorslots",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setslots(res.data.doctorSlots)
          setslots1(res?.data?.doctorSlots[0])
          setslots2(res?.data?.doctorSlots[0]?.callSession1Slot)
          setslots3(res?.data?.doctorSlots[0]?.callSession2Slot)
          setslots4(res?.data?.doctorSlots[0]?.directSession1Slot)
          setslots5(res?.data?.doctorSlots[0]?.directSession2Slot)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const slotdata = (data, key) => {
    setslots1(data)
    setslots2(data.callSession1Slot)
    setslots3(data.callSession2Slot)
    setslots4(data.directSession1Slot)
    setslots5(data.directSession2Slot)
    toggle1(`${key + 1}`)
  }

  useEffect(() => {
    getAllSlots()
    GetProfile()
    Getholidays()
  }, [])

  const handleRemoveTime2 = (data, key) => {
    const newSlots1 = [...slots2]
    newSlots1.splice(key, 1)
    setslots2(newSlots1)
    var token = datas
    const bodydata = {
      timeSlot: data,
    }

    axios
      .post(
        baseurl + "doctorslots/dropcallsession1slot/" + slots1._id,
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          toast.success(res.data.message)
          getAllSlots()
          setactiveTab1("1")
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }
  const handleRemoveTime3 = (data, key) => {
    const newSlots1 = [...slots3]
    newSlots1.splice(key, 1)
    setslots3(newSlots1)
    var token = datas
    const bodydata = {
      timeSlot: data,
    }

    axios
      .post(
        baseurl + "doctorslots/dropcallsession2slot/" + slots1._id,
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          toast.success(res.data.message)
          getAllSlots()
          setactiveTab1("1")
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }
  const handleRemoveTime4 = (data, key) => {
    const newSlots1 = [...slots4]
    newSlots1.splice(key, 1)
    setslots4(newSlots1)
    var token = datas
    const bodydata = {
      timeSlot: data,
    }

    axios
      .post(
        baseurl + "doctorslots/dropdirectsession1slot/" + slots1._id,
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          toast.success(res.data.message)
          getAllSlots()
          setactiveTab1("1")
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }
  const handleRemoveTime5 = (data, key) => {
    const newSlots1 = [...slots5]
    newSlots1.splice(key, 1)
    setslots5(newSlots1)
    var token = datas
    const bodydata = {
      timeSlot: data,
    }

    axios
      .post(
        baseurl + "doctorslots/dropdirectsession2slot/" + slots1._id,
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          toast.success(res.data.message)
          getAllSlots()
          setactiveTab1("1")
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const redirectdata = () => {
    sessionStorage.setItem("slotid", slots1._id)
    history.push("/addslot")
  }

  const AddHoliday = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      holidays: datastate,
    }
    axios
      .post(baseurl + "holidays/addholidays", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            Getholidays()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }



  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Allude Doctor" breadcrumbItem="Slot list" />
            <Row>
              <Col xl="12">
                <Button
                  onClick={() => {
                    redirectdata()
                  }}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                  title="Edit Slots"
                >
                  <i className="bx bx-grid-small"></i>
                  Edit Slots
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Card>
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="12">
                        <div className="text-primary p-3 mb-5"></div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Col md={12}>
                      <div className="text-center">
                        <div className="avatar-md1 profile-user-wid mb-4 mt-1">
                          <span className="ml-5">
                            <img
                              src={imgUrl + admininfo.avatar}
                              alt=""
                              className=" rounded-circle img-thumbnail"
                              style={{
                                marginTop: "-35px",
                                height: "100px",
                                width: "100px",
                              }}
                            />
                          </span>
                        </div>
                        <h5 className="mt-1 mb-1">#{admininfo.doctorId}</h5>
                      </div>
                    </Col>

                    <ul className="list-unstyled mt-2">
                      <li>
                        <div className="d-flex">
                          <i className="bx bx-user-circle text-primary fs-4"></i>
                          <div className="ms-3">
                            <h6 className="fs-14 mb-2">Name</h6>
                            <p className="text-muted fs-14 mb-0">
                              {admininfo.name}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className="d-flex">
                          <i className="bx bx-phone text-primary fs-4"></i>
                          <div className="ms-3">
                            <h6 className="fs-14 mb-2">Phone</h6>
                            <p className="text-muted fs-14 mb-0">
                              {admininfo.phone}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className="d-flex">
                          <i className="bx bx-mail-send text-primary fs-4"></i>
                          <div className="ms-3">
                            <h6 className="fs-14 mb-2">Email</h6>
                            <p className="text-muted fs-14 mb-0">
                              {admininfo.email}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className="d-flex">
                          <i className="bx bx-error-circle text-primary fs-4"></i>
                          <div className="ms-3">
                            <h6 className="fs-14 mb-2">Gender / Age </h6>
                            <p className="text-muted fs-14 text-break mb-0">
                              {admininfo.gender} / {admininfo.age}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              <Col md={8}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6" />
                      <Col md="3">
                        <Button
                          style={{ float: "right" }}
                          onClick={tog_small1}
                          color="info"
                          outline
                          title="View Holidays"
                        >
                          {" "}
                          <i className="bx bx-show" /> View Holidays
                        </Button>
                      </Col>
                      <Col md="3">
                        <Button
                          style={{ float: "right" }}
                          onClick={tog_small}
                          color="warning"
                          outline
                          title="Apply Holidays"
                        >
                          {" "}
                          <i className="bx bx-extension" /> Apply Holidays
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Nav pills className="navtab-bg nav-justified">
                        {slots.map((data, key) => (
                          <NavItem key={key} className="border m-2">
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab1 === `${key + 1}`,
                              })}
                              onClick={() => {
                                slotdata(data, key)
                              }}
                            >
                              {data.week}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </Row>
                    <div>
                      <div>
                        <TabContent
                          activeTab={activeTab1}
                          className="p-3 text-muted"
                        >
                          <TabPane tabId={activeTab1}>
                            <div>
                              <h5>Online Slots</h5>
                              {slots2?.length == 0 ? (
                                <p className="text-center mt-5 text-muted">
                                  No Slots...
                                </p>
                              ) : (
                                <div>
                                  <div className="mb-2">
                                    <b>
                                      Session(
                                      {slots1.callSession1Time ==
                                      "undefined-undefined"
                                        ? ""
                                        : slots1.callSession1Time}
                                      )
                                    </b>
                                    <br />

                                    {slots2?.map((time, key) => (
                                      <Button
                                        key={key}
                                        size="sm"
                                        className="p-1 m-1"
                                        style={{ width: "70px" }}
                                        outline
                                        color="primary"
                                      >
                                        {time}
                                        <i
                                          onClick={() =>
                                            handleRemoveTime2(time, key)
                                          }
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "5px",
                                          }}
                                          className="bx bx-x-circle text-danger ml-3"
                                        />
                                      </Button>
                                    ))}
                                  </div>
                                  <div className="mb-2">
                                    <b>
                                      Session(
                                      {slots1.callSession2Time ==
                                      "undefined-undefined"
                                        ? ""
                                        : slots1.callSession2Time}
                                      )
                                    </b>
                                    <br />
                                    {slots3?.map((time, key) => (
                                      <Button
                                        key={key}
                                        size="sm"
                                        className="p-1 m-1"
                                        style={{ width: "70px" }}
                                        outline
                                        color="primary"
                                      >
                                        {time}
                                        <i
                                          onClick={() =>
                                            handleRemoveTime3(time, key)
                                          }
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "5px",
                                          }}
                                          className="bx bx-x-circle text-danger ml-3"
                                        />
                                      </Button>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div>
                              <h5>Direct Slots</h5>
                              {slots4?.length == 0 ? (
                                <p className="text-center mt-5 text-muted">
                                  No Slots...
                                </p>
                              ) : (
                                <div>
                                  <div className="mb-2">
                                    <b>
                                      Session(
                                      {slots1.directSession1Time ==
                                      "undefined-undefined"
                                        ? ""
                                        : slots1.directSession1Time}
                                      )
                                    </b>
                                    <br />
                                    {slots4?.map((time, key) => (
                                      <Button
                                        key={key}
                                        size="sm"
                                        className="p-1 m-1"
                                        style={{ width: "70px" }}
                                        outline
                                        color="primary"
                                      >
                                        {time}
                                        <i
                                          onClick={() =>
                                            handleRemoveTime4(time, key)
                                          }
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "5px",
                                          }}
                                          className="bx bx-x-circle text-danger ml-3"
                                        />
                                      </Button>
                                    ))}
                                  </div>

                                  <div className="mb-2">
                                    <b>
                                      Session(
                                      {slots1.directSession2Time ==
                                      "undefined-undefined"
                                        ? ""
                                        : slots1.directSession2Time}
                                      )
                                    </b>
                                    <br />
                                    {slots5?.map((time, key) => (
                                      <Button
                                        key={key}
                                        size="sm"
                                        className="p-1 m-1"
                                        style={{ width: "70px" }}
                                        outline
                                        color="primary"
                                      >
                                        {time}
                                        <i
                                          onClick={() =>
                                            handleRemoveTime5(time, key)
                                          }
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "5px",
                                          }}
                                          className="bx bx-x-circle text-danger ml-3"
                                        />
                                      </Button>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Holidays
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                AddHoliday(e)
              }}
            >
              <div className="mb-4">
                <Label>Date</Label>
                <Flatpickr
                  placeholder="Select date"
                  className="form-control"
                  name="date"
                  onChange={e => {
                    handleDateChange(e)
                  }}
                  options={{
                    mode: "multiple",
                    dateFormat: "d M, Y",
                    minDate: "today",
                  }}
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button
                  disabled={datastate.length == 0}
                  className="m-1"
                  color="success"
                  type="submit"
                >
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Holidays
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              eventClick={redirectbooking}
              // style={{height:"200px"}}
              // weekends={false}
              events={events}
              // eventContent={renderEventContent}
            />

            {/* <Calendar value={holidayDates} style={{ width: "100%" }} /> */}
          </div>
        </Modal>

        <ToastContainer />
      </React.Fragment>
    </div>
  )
}

export default Slotlist
