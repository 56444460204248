import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { baseurl } from "Baseurls"

const Staff = () => {
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [form, setform] = useState([])
  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        baseurl + "receptionist/getAll",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.receptionists)
      })
  }

  const custsearch = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const token = datas
    console.log(token)
    axios
      .post(
        baseurl + "receptionist/getAll?searchQuery=" + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setusers(res.data.receptionists)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const Add = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("email", form.email)
    dataArray.append("countryCode", form.countryCode || "+91")
    dataArray.append("mobileNumber", form.mobileNumber)
    dataArray.append("password", form.password)
    dataArray.append("address", form.address || "")

    axios
      .post(baseurl + "receptionist/addreceptionist", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast.success(res.data.message)
            setform("")
            Get()
            setshow(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Add()
  }

  const Update = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("email", form1.email)
    dataArray.append("mobileNumber", form1.mobileNumber)
    dataArray.append("status", form1.status)
    dataArray.append("address", form1.address)
    dataArray.append("countryCode", form1.countryCode)
    axios
      .put(baseurl + "receptionist/edit/" + form1._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast.success(res.data.message)
            setform1("")
            Get()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = e => {
    e.preventDefault()
    Update()
  }

  const usedata = data => {
    setshow1(true)
    setform1(data)
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(baseurl + "receptionist/delete/" + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Allude" breadcrumbItem="Receptionist" />
          <Row>
            <Col>
              {show == true ? (
                <Card className="p-4">
                  <Form
                    onSubmit={e => {
                      formsubmit(e)
                    }}
                  >
                    <h5>Add New Receptionist</h5>
                    <Row>
                      <Col md="3">
                        <Label>Name</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="name"
                          onChange={e => {
                            handleChange(e)
                          }}
                          pattern="[a-zA-Z0-9\s.,$@%*]+"
                          value={form.name}
                          required
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Col>
                      <Col md="3">
                        <Label>Email Id</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="email"
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.email}
                          required
                          type="email"
                          placeholder="Enter Email"
                        />
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Phone <span className="text-danger">*</span>
                          </Label>
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <select
                                  value={form.countryCode}
                                  name="countryCode"
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                >
                                  <option value="+91">+91 </option>
                                  <option value="+1">+1</option>
                                  <option value="+61">+61 </option>
                                </select>
                              </div>
                            </div>
                            <Input
                              type="number"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Phone"
                              required
                              value={form.mobileNumber}
                              name="mobileNumber"
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <Label>Password</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="password"
                          onChange={e => {
                            handleChange(e)
                          }}
                          type="text"
                          value={form.password}
                          required
                          placeholder="Enter password"
                        />
                      </Col>
                      <Col md="3">
                        <Label> Address</Label>{" "}
                        <textarea
                          name="address"
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.address}
                          type="text"
                          className="form-control"
                          placeholder="Enter Address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        ></textarea>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </Card>
              ) : (
                ""
              )}
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="primary"
                        title="New Receptionist"
                      >
                        New Receptionist <i className="bx bx-user-plus"></i>
                      </Button>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form.search}
                          onChange={custsearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>Sl No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile No</th>
                          <th>Address</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 10 + key + 11}
                            </th>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>
                              {data.countryCode} {data.mobileNumber}
                            </td>
                            <td>{data.address}</td>
                            <td>
                              {data.status == true ? "Active" : "Inactive"}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  usedata(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                                title="Edit"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>
                              <Button
                                size="sm"
                                className="m-1"
                                outline
                                color="danger"
                                onClick={() => {
                                  manageDelete(data)
                                }}
                                title="Delete"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-trash"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal isOpen={show1} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit Receptionist Details</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              formeditsubmit(e)
            }}
          >
            <Row>
              <Col md="6">
                <Label>Name</Label> <span className="text-danger">*</span>
                <Input
                  className="mb-3"
                  value={form1.name}
                  name="name"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  required
                  type="text"
                  pattern="[a-zA-Z0-9\s.,$@%*]+"
                  placeholder="Enter Name"
                />
              </Col>
              <Col md="6">
                <Label>Email Id</Label> <span className="text-danger">*</span>
                <Input
                  value={form1.email}
                  name="email"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  required
                  type="email"
                  placeholder="Enter Email"
                />
              </Col>
              <Col md="6">
              
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Phone <span className="text-danger">*</span>
                  </Label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <select
                          value={form1.countryCode}
                          name="countryCode"
                          onChange={e => {
                            handleChange1(e)
                          }}
                        >
                          <option value="+91">+91 </option>
                          <option value="+1">+1</option>
                          <option value="+61">+61 </option>
                        </select>
                      </div>
                    </div>
                    <Input
                      type="number"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Phone"
                      required
                      value={form1.mobileNumber}
                      name="mobileNumber"
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <Label> Status</Label> <span className="text-danger">*</span>
                <select
                  value={form1.status}
                  name="status"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="true">Active </option>
                  <option value="false">Inactive </option>
                </select>
              </Col>
              <Col md="12">
                <Label> Address</Label>
                <textarea
                  value={form1.address}
                  name="address"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                ></textarea>
              </Col>
            </Row>
            <div className="text-end mt-3">
              <Button type="submit" color="success m-1" outline>
                Submit <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setshow1(!show1)
                }}
                color="danger m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Staff
