import React from "react"
import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Button,
  Input,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"

function DoctorSchedule() {
  const history = useHistory()
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Time Slot " />
          
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Col>
                    <Link to="/AddTimeSlot">
                      <Button color="primary" className="m-2">
                        New Time Slot Making{" "}
                        <i className="bx bx-plus-circle"></i>
                      </Button>
                    </Link>
                  </Col>
                  <Row>
                    <Col md={12}>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          className="form-control"
                          placeholder="Search.."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-2 mt-3">
                        <thead>
                          <tr>
                            <th>SlNo</th>
                            <th>Doctor Name</th>
                            <th>Price </th>
                            <th>Room Number</th>
                            <th>Employee Name / Id</th>
                            <th>Date</th>
                            <th>From Time</th>
                            <th>To Time</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Ramesh</td>
                            <td>500</td>
                            <td>20</td>
                            <td>Kavya / Apollo112</td>
                            <td>22-2-2022</td>
                            <td>09:00 am</td>
                            <td>05:00 pm</td>
                            <td>
                             
                              <Link to="/Adduser">
                                <Button
                                  className="mr-2"
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>{" "}
                              </Link>
                              <Button
                                style={{
                                  padding: "6px",
                                  margin:'3px',
                                }}
                                color="danger"
                                outline
                              >
                                <i className="bx bx-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DoctorSchedule
