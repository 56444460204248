import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"
import { toast } from "react-toastify"
import { withTranslation } from "react-i18next"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import axios from "axios"
import { baseurl } from "Baseurls"

const Header = props => {
  const history = useHistory()

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  // dashboard data
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  var doctorId = data.doctorId

  const [intdata, setitdata] = useState([])

  const GetDashboarddata = () => {
    var token = datas
    axios
      .post(
        baseurl + "getdoctordashboard",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setitdata(res.data)
        sessionStorage.setItem("Expirydate", res?.data?.expriryDate)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles1 = data

  const Getredirectpages = () => {
    var token = datas
    if (Roles1?.type === "Doctor") {
      axios
        .post(
          baseurl + "getdoctormaintenance",
          {  },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(res => {
          if (res?.data?.maintenance?.underMaintenance == true) {
            history.push("/maintenance")
          } else if (res?.data?.doctor?.status == false) {
            history.push("/access")
          } else if (res?.data?.fee == false) {
            history.push("/consultation")
          } else {
            null
          }
        })
    } else {
      axios
        .post(
          "https://api.alludeapp.com/v1/alludeapi/app/doctor/getresDoctorMaintenance",
          { doctorId: doctorId },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(res => {
          if (res?.data?.maintenance?.underMaintenance == true) {
            history.push("/maintenance")
          } else if (res?.data?.doctor?.status == false) {
            history.push("/access")
          } else if (res?.data?.fee == false) {
            history.push("/consultation")
          } else {
            null
          }
        })
    }
  }

  const [form, setform] = useState([])
  const GetProfile = () => {
    var token = datas
    axios
      .post(
        baseurl + "doctorauth/getdoctorprofile",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.doctorProfile)
      })
  }
  const handleChange = e => {
    let myData = { ...form }
    myData[e.target.name] = e.target.checked
    setform(myData)
    var token = datas
    const bodydata = {
      spotConsultation: e.target.checked,
    }
    axios
      .put(baseurl + "update", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          // toast.success(res.data.message)
          GetProfile()
        },
        error => {
          if (error.response && error.response.status === 400) {
            // toast.error(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    GetDashboarddata()
    GetProfile()
  }, [])

  useEffect(() => {
    Getredirectpages()
    const intervalId = setInterval(() => {
      Getredirectpages()
    }, 100000)
    return () => clearInterval(intervalId)
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles1 = data

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          {Roles1?.type === "Doctor" ? (
            <>
              <h5 className="text-white">Spot Consultation: </h5>
              <div className="form-check form-switch form-switch-md">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  name="spotConsultation"
                  title="Spot Consultation"
                  onChange={e => {
                    handleChange(e)
                  }}
                  defaultChecked={form.spotConsultation}
                />
              </div>{" "}
            </>
          ) : (
            ""
          )}

          <div className="d-flex">
            {Roles1?.type === "Doctor" ? (
              <>
                <div className="dropdown d-none d-lg-inline-block ms-1">
                  <Link to="/wallet" className="blink2">
                    {" "}
                    <b>Wallet : {intdata.wallet}</b>
                  </Link>
                  <Link to="/BookingList" className="blink">
                    Scheduled : {intdata.scheduled}
                  </Link>
                  <button
                    type="button"
                    className="btn header-item noti-icon ml-5"
                    data-toggle="fullscreen"
                  >
                    Expiry : {intdata.expriryDate}{" "}
                    <Link to="/Plans">
                      {" "}
                      <button className="btn btn-outline-primary btn-sm">
                        Upgrade
                      </button>
                    </Link>
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
