import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Modal, Card, CardBody, Container, FormFeedback, Input, Label, Form, Button } from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";
import profile from "../../assets/images/latest/undermaintenance.gif";
import logo from "../../assets/images/logo allude color.png";
import { imgUrl, baseurl } from "Baseurls"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"

const ForgetPasswordPage = () => {
    const history = useHistory()
    var gets = localStorage.getItem("authUser")
    var data = JSON.parse(gets)
    var datas = data.token
    
    const Getredirectpages = () => {
        var token = datas
        axios
          .post(baseurl + 'getdoctormaintenance', {},
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(res => {
            if (res?.data?.maintenance?.underMaintenance == false) {
              history.push('/')
            } else if (res?.data?.doctor?.status == false) {
              history.push('/access')
            } else {
              null
            }
          })
      }
      useEffect(() => {
        Getredirectpages();
        const intervalId = setInterval(() => {
          Getredirectpages();
        }, 10000);
        return () => clearInterval(intervalId);
      }, [])
    


    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-2 pt-sm-5">
                <Container>
                    <div>
                    <Link to="/login">
                        <img src={logo} />
                        </Link>
                    </div>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Row>
                                <img className="mt-3" src={profile} />
                                <p className="text-muted mt-4" style={{ fontSize: "16px" }}>
                                We would like to inform you about an upcoming routine maintenance activity scheduled for our services.
                                </p>
                             
                            </Row>
                        </Col>
                    </Row>
                </Container>

              
<ToastContainer />
            </div>
        </React.Fragment>
    );
};

ForgetPasswordPage.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
